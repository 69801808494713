import React from 'react';
import {
  PageBackground,
  Container,
  ContentContainer,
  NavBar,
  LogoImg,
  Logo,
  Nav,
  NavItem,
  SignInButton,
  ScreenProtector,
} from "../Styling/NavBar-styling";
import {
  ErrandContainer,
} from "./ErrandsPage-styling"
import OpenErrand from "./Columns/OpenErrand";
import ErrandsList from "./Columns/ErrandsList";
import AdvancedOptions from "./Columns/AdvancedOptions";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import {
  Errand,
  Message,
  Template,
  HistResponse,
  Prompt,
  Response,
  FAQ,
} from "./Types";
import { ax, api_base_url } from "../Utils";
import CloseErrandPopUp from './CloseErrandPopUp';


var logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png"


const ErrandsPage = () => {
  const [errands, setErrands] = useState<Errand[]>([]);
  const [selectedErrand, setSelectedErrand] = useState<Errand | null>(null);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [histResponses, setHistResponses] = useState<HistResponse[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  //const [prompt, setPrompt] = useState<Prompt | null>(null);
  const [newResponseText, setNewResponseText] = useState("");
  const [errandTitle, setErrandTitle] = useState("");
  const [proposedResponse, setProposedResponse] = useState<string | undefined>(undefined);
  const [chat, setChat] = useState<(Message | Response)[]>([]);
  const [showAddMessageButton, setShowAddMessageButton] = useState(false);
  const [showAddMessageInput, setShowAddMessageInput] = useState(false);
  const [addMessageText, setAddMessageText] = useState("");
  const [lastClickedId, setLastClickedId] = useState<number | null>(null);
  const [draftId, setDraftId] = useState<number | null>(null);
  const [showCloseErrand, setShowCloseErrand] = useState(false);
  const [advancedOptionsText, setAdvancedOptionsText] = useState("");
  const [answerLength, setAnswerLength] = useState(3);
  
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const responseEventSourceRef = useRef<EventSource | null>(null);
  const titleEventSourceRef = useRef<EventSource | null>(null);
  const errandsLengthRef = useRef(errands.length);
  //const selectedErrandRef = useRef(selectedErrand);
  const templatesRef = useRef(templates);
  const histResponsesRef = useRef(histResponses);
  const faqsRef = useRef(faqs);
  const conversationRef = useRef<HTMLDivElement>(null);
  const responseInputRef = useRef<HTMLTextAreaElement>(null);

  

  //create errand consts
  const [createErrandTitle, setCreateErrandTitle] = useState("");
  const [createErrandMessage, setCreateErrandMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    ax.get('/errands/with_last_chat_item')
      .then((response) => setErrands(response.data));
  }, []);

 /* useEffect(() => {
    if (selectedErrand) {
      // get message for errand
      ax.get(`/messages?errand_id=${selectedErrand.id}`)
        .then((response) => setMessage(response.data));

      // get response for errand
      ax.get(`/responses?errand_id=${selectedErrand.id}`)
        .then((response) => setResponse(response.data));
    }
  }, [selectedErrand]);*/


  useEffect(() => {
    const fetchData = async () => {
      // setHistResponses([]);
      // setTemplates([]);
      // setFaqs([]);
      if (responseEventSourceRef.current) {
        responseEventSourceRef.current.close();
        responseEventSourceRef.current = null;
      }
      if (titleEventSourceRef.current) {
        titleEventSourceRef.current.close();
        titleEventSourceRef.current = null;
      }
      if (selectedErrand) {
        setShowAddMessageInput(false);
        //setShowAdvancedOptions(false);
        setLastClickedId(selectedErrand.id);
        setErrandTitle(selectedErrand.title);

        const draftResponse = await ax.get(`/drafts/errand?errand_id=${selectedErrand.id}`);
        if (draftResponse.data) {
          setNewResponseText(draftResponse.data.text);
          setDraftId(draftResponse.data.id);
        }
        else {
          setNewResponseText('');
        }

        try {
          //Get chat for errand
          const chatResponse = await ax.get(`/chat?errand_id=${selectedErrand.id}`);
          const newChat = chatResponse.data;
          setChat(newChat)
  
          //If the chat is not empty
          if (newChat.length >= 1) {
            const lastMessage = newChat[newChat.length - 1];
            //And the last message is of type Message
            if (lastMessage.class_type === 'message') {
              setMessage(lastMessage);
            }
            else {
              setMessage(null);
            }
          }
          else {
            setMessage(null);
          }
        } catch (error) {
          //Handle errors
        }
      }
    };
  
    fetchData();
  }, [selectedErrand]);

  useEffect(() => {
    if (chat.length >= 1) {
      scrollChatToBottom();
      const lastMessage = chat[chat.length - 1];
      //And the last message is of type Message
      if (lastMessage.class_type === 'message') {
        setMessage(lastMessage);
      }
      else {
        setShowAddMessageButton(true);
        setShowAddMessageInput(false);
      }
    }
    else {
      setShowAddMessageButton(false);
      setShowAddMessageInput(true);
    }
  }, [chat])

  useEffect(() => {
    setAdvancedOptionsText('');
    const fetchData = async () => {

      if (message) {
        setShowAddMessageButton(false);
        if(errandTitle === 'New review') {
          await generateTitle();
        }
        await ax.get(`/relevant/all?text=${message.text}`)
          .then((response) => {
            setHistResponses(response.data.responses);
            setTemplates(response.data.templates);
            setFaqs(response.data.faq);
          });

        // console.log('message: ', message.text);
        // await ax.get(`/relevant/responses?message_id=${message.id}`)
        //   .then((response) => setHistResponses(response.data));

        // await ax.get(`/relevant/templates?message_id=${message.id}`)
        //   .then((response) => setTemplates(response.data));

        // await ax.get(`/relevant/faq?message_id=${message.id}`)
        //   .then((response) => setFaqs(response.data));
      }
      else {
        setShowAddMessageButton(true);

        setHistResponses([]);
        setTemplates([]);
        setFaqs([]);
      }
    }
    fetchData();
  }, [message]);

/*  useEffect(() => {
    return () => {
      //Cleanup when selectedErrand changes or component is unmounted
      //console.log("EventSource set:", responseEventSourceRef.current);
      if (responseEventSourceRef.current) {
        responseEventSourceRef.current.close();
        responseEventSourceRef.current = null;
      }
    };
  }, [chat]); */

  useEffect(() => {
    if (showAddMessageButton) {
      setShowAddMessageInput(false);
    }
  }, [showAddMessageButton]);

  useEffect(() =>{
    if (errands.length === errandsLengthRef.current+1) {
      setSelectedErrand(errands[0]);
      console.log('1')
    }
    console.log('2')
    errandsLengthRef.current = errands.length;
  }, [errands]);

  useEffect(() => {
    if (message && newResponseText === '') {
      if (templates !== templatesRef.current && histResponses !== histResponsesRef.current && faqs !== faqsRef.current){
        generateResponse();
        templatesRef.current = templates;
        histResponsesRef.current = histResponses;
        faqsRef.current = faqs;
      }
    }
  }, [templates, histResponses, faqs]);


  useEffect(() => {
    responseInputChangeSize();
  }, [newResponseText])

/*  useEffect(() => {
    if (message) {
      // get similar hist responses for errand
      ax.get(`/responses/relevant?message_id=${message.id}`)
        .then((response) => setHistResponses(response.data));

      // get similar templates for errand
      ax.get(`/templates/relevant?message_id=${message.id}`)
        .then((response) => setTemplates(response.data));
    }
  }, [message]);*/

  const handleHome = async () => {
    navigate('/user');
  };
  const handleSettings = async () => {
    //settings
  };
  const handleLanguages = async () => {
    //Show menu of possible languages to choose from
  };
  const handleSignOut = async () => {
    ax.post('/auth/logout');
    navigate('/');

  };

  const handleCreateErrand = async () => {
    await ax.post('/errands', {
      errand: {
        title: 'New review',
      },
    });
    await ax.get('/errands/with_last_chat_item')
      .then((response) => setErrands(response.data));
  };

/*  const toggleCreateErrand = async () => {
    props.setShowCreateErrand(!props.showCreateErrand);
  };

  const handleTitleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    }
  };
  
  const handleSaveNewErrand = async () => {
    if (createErrandTitle && createErrandMessage) {
      await ax.post('/errands', {
        errand: {
          title: createErrandTitle,
        },
        message: {
          text: createErrandMessage
        }
      });
      setCreateErrandTitle('');
      setCreateErrandMessage('');
      toggleCreateErrand();
      await ax.get('/errands/with_last_chat_item')
        .then((response) => setErrands(response.data));
    }
  };
*/  
  const toggleCloseErrand = () => {
    setShowCloseErrand(!showCloseErrand);
  };

  const handleCloseErrand = async () => {
    toggleCloseErrand();
    if (selectedErrand) {
      await ax.patch(`/errands/close?errand_id=${selectedErrand.id}`);
      await ax.get('/errands/with_last_chat_item')
        .then((response) => setErrands(response.data));
      setSelectedErrand(null);
      setShowAdvancedOptions(false);
      setLastClickedId(null);

    }
  };

  const handleAddMessageClick = async () => {
    setShowAddMessageButton(false);
    setShowAddMessageInput(true);
  };

  const handleAddMessageSave = async () => {
    if (selectedErrand && addMessageText) {
      setShowAddMessageInput(false);
      const requestBody: any = {
        text: addMessageText,
        errand_id: selectedErrand.id,
      }
      await ax.post('/messages', requestBody);
      const chatResponse = await ax.get(`/chat?errand_id=${selectedErrand.id}`);
      const newChat = chatResponse.data;
      setChat(newChat)
      setAddMessageText('');
      //setMessage(newChat[newChat.length-1])

    }
  };

  const handleAddMessageCancel = async () => {
    setShowAddMessageInput(false);
    setShowAddMessageButton(true);
  };
  
  const toggleAdvancedOptions = async () => {
    setShowAdvancedOptions(!showAdvancedOptions)
  };

  const handleTemplateCheck = (index: number, checked: boolean) => {
    const newTemplates = [...templates];
    newTemplates[index].checked = checked;
    setTemplates(newTemplates);
  };

  const handleHistResponsesCheck = (index: number, checked: boolean) => {
    const newHistResponses = [...histResponses];
    newHistResponses[index].checked = checked;
    setHistResponses(newHistResponses);
  };

  const handleFaqCheck = (index: number, checked: boolean) => {
    const newFaqs = [...faqs];
    newFaqs[index].checked = checked;
    setFaqs(newFaqs);
  };

  const handleLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswerLength(parseInt(e.target.value));
    console.log(answerLength)
  };

  const handleSendResponse = async () => {
    if (newResponseText === '') {
      return
    }
    if (selectedErrand && message){
      const requestBody: any = {
        text: newResponseText,
        errand_id: selectedErrand.id,
        message_id: message.id,
      }
      await ax.post('/responses', requestBody);
      const chatResponse = await ax.get(`/chat?errand_id=${selectedErrand.id}`);
      const newChat = chatResponse.data;
      setChat(newChat)
      setNewResponseText('');
      setMessage(null);
      setShowAdvancedOptions(false);

      if (draftId) {
        const requestBody: any = {
          text: '',
          errand_id: selectedErrand.id,
          id: draftId,
        };
        await ax.patch('/drafts', requestBody);
      }
    }
  };

  const generateTitle = async () => {
    console.log("i gentitle")
    setErrandTitle('');
    var saveTitle = '';
    const eventSource = new EventSource(
      `${api_base_url}/errands/generate_title/${selectedErrand?.id}`, 
      {withCredentials: true}
    );
    titleEventSourceRef.current = eventSource;
    
    eventSource.onmessage = function(event) {
      const data = JSON.parse(event.data);


      if (data) {
        // console.log("Chunk:", data.text);
        setErrandTitle(prev => prev + data.text);
        saveTitle += data.text; 
      }
      else {
        eventSource.close();
      }
    };

    eventSource.onerror = async (event) => {
        console.error("EventSource failed:", event);
        eventSource.close();
        await ax.patch(`/errands/title?errand_id=${selectedErrand?.id}&title=${saveTitle}`);
        ax.get('/errands/with_last_chat_item')
          .then((response) => setErrands(response.data));

    };
    console.log('title: ', errandTitle);
  };

  const generateResponse = async () => {
    setNewResponseText('Generating response...');
    if (!message) {//ISF kalla på /proposed_response baserad errand_id istället
      setNewResponseText('');
      setHistResponses([]);
      setTemplates([]);
      setFaqs([]);
      return;
    }

    const responseResponse = await ax.get(`/relevant/responses?message_id=${message.id}`);
    const newHistResponses = responseResponse.data;
    const templateResponse = await ax.get(`/relevant/templates?message_id=${message.id}`);
    const newTemplates = templateResponse.data;
    const faqResponse = await ax.get(`/relevant/faq?message_id=${message.id}`);
    const newFaqs = faqResponse.data;

    const requestBody: any = {
      message_id: message.id,
      template_ids: newTemplates.filter((t: { checked: any; }) => t.checked).map((t: { id: any; }) => t.id),
      response_ids: newHistResponses.filter((t: { checked: any; }) => t.checked).map((t: { id: any; }) => t.id),
      faq_answer_ids: newFaqs.filter((t: { checked: any; }) => t.checked).map((t: { answer_id: any; }) => t.answer_id),
    };
    console.log("messid: ", message.id);
    console.log("genhist: ", newHistResponses);
    console.log("gentemp: ", newTemplates);
    console.log("genfaq: ", newFaqs);
    // first create the prompt, then send the prompt_id to generate. This because EventSource can only use .get so we cant send a requestBody

    ax.post('/prompts', requestBody)
      .then(response => {
          // const promptData: Prompt = response.data.prompt as Prompt;
          // console.log(response.data);

          const promptData: Prompt = response.data as Prompt;
          //setPrompt(promptData);
          //console.log("created prompt", promptData);

          // proposed response event source for streaming from OpenAI
          const eventSource = new EventSource(
            `${api_base_url}/proposed_responses/generate?prompt_id=${promptData.id}`, 
            {withCredentials: true}
          );
          responseEventSourceRef.current = eventSource;

          
          setNewResponseText(''); //clear generating response message
          
          eventSource.onmessage = function(event) {
            const data = JSON.parse(event.data);

            if (data) {
              // console.log("Chunk:", data.text);
              setNewResponseText(prev => prev + data.text);
            }
            else {
              eventSource.close();
            }
          };

          eventSource.onerror = (event) => {
              // console.error("EventSource failed:", event);
              eventSource.close();
          };
      });
  };

  const updateDraft = async () => {
    if (selectedErrand) {
      if (draftId) {
        const requestBody: any = {
          text: newResponseText,
          errand_id: selectedErrand.id,
          id: draftId,
        };
        await ax.patch('/drafts', requestBody);
      }
      else {
        const requestBody: any = {
          text: newResponseText,
          errand_id: selectedErrand.id,
        };
        await ax.patch('/drafts', requestBody);
      }
    }
  };

  const isEmptyErrand = async () => {
    if (selectedErrand) {
      if (chat.length === 0) {
        await ax.delete(`/errands/${selectedErrand.id}`);//Väntar på fix
        ax.get('/errands/with_last_chat_item')
          .then((response) => setErrands(response.data));
      }
    }
  };

  const scrollChatToBottom = () => {
    if (conversationRef.current && chat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
    setNewResponseText(e.target.value);
    //e.target.style.height = 'auto';
    //e.target.style.height = (e.target.scrollHeight - 21 > 150 ? 150 : e.target.scrollHeight - 31) + 'px';
  };

  const responseInputChangeSize = () => {
    if (responseInputRef.current) {
      responseInputRef.current.style.height = 'auto';
      responseInputRef.current.style.height = (responseInputRef.current.scrollHeight > 250 ? 250 : responseInputRef.current.scrollHeight - 11) + 'px';
      responseInputRef.current.scrollTop = responseInputRef.current.scrollHeight;
    }
  };


  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ConvAIor</Logo>
            <Nav>
              <NavItem onClick={handleHome}>Hem</NavItem>
              <NavItem onClick={handleLanguages}>Språk</NavItem>
              <NavItem onClick={handleSettings}>Inställningar</NavItem>
            </Nav>
            <SignInButton onClick={handleSignOut}>Logga ut</SignInButton>
          </NavBar>
          <ContentContainer>
            <ErrandsList
              errands={errands}
              setSelectedErrand={setSelectedErrand}
              handleCreateErrand={handleCreateErrand}
              lastClickedId={lastClickedId}
              updateDraft={updateDraft}
              isEmptyErrand ={isEmptyErrand}
            />
            <ErrandContainer>
              <OpenErrand
                selectedErrand={selectedErrand}
                chat={chat}
                proposedResponse={proposedResponse}
                setProposedResponse={setProposedResponse}
                toggleAdvancedOptions={toggleAdvancedOptions}
                newResponseText={newResponseText}
                setNewResponseText={setNewResponseText}
                handleSendResponse={handleSendResponse}
                handleCreateErrand={handleCreateErrand}
                showAddMessageButton={showAddMessageButton}
                showAddMessageInput={showAddMessageInput}
                handleAddMessageClick={handleAddMessageClick}
                handleAddMessageSave={handleAddMessageSave}
                handleAddMessageCancel={handleAddMessageCancel}
                setAddMessageText={setAddMessageText}
                addMessageText={addMessageText}
                toggleCloseErrand={toggleCloseErrand}
                message={message}
                errandTitle={errandTitle}
                conversationRef={conversationRef}
                handleTextareaChange={handleTextareaChange}
                responseInputRef={responseInputRef}
              />
              {showAdvancedOptions && (
              <AdvancedOptions
                templates={templates}
                histResponses={histResponses}
                toggleAdvancedOptions={toggleAdvancedOptions}
                handleTemplateCheck={handleTemplateCheck}
                handleHistResponsesCheck={handleHistResponsesCheck}
                generateResponse={generateResponse}
                faqs={faqs}
                handleFaqCheck={handleFaqCheck}
                advancedOptionsText={advancedOptionsText}
                setAdvancedOptionsText={setAdvancedOptionsText}
                answerLength={answerLength}
                handleLengthChange={handleLengthChange}
              />
              )}
            </ErrandContainer>

            {showCloseErrand && selectedErrand && (
            <CloseErrandPopUp
              toggleCloseErrand={toggleCloseErrand}
              handleCloseErrand={handleCloseErrand}
              selectedErrand={selectedErrand}
            />
            )}
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};
export default ErrandsPage;

/*{props.showCreateErrand && (
          <CreateErrandPopUp
            toggleCreateErrand={toggleCreateErrand}
            createErrandTitle={createErrandTitle}
            setCreateErrandTitle={setCreateErrandTitle}
            createErrandMessage={createErrandMessage}
            setCreateErrandMessage={setCreateErrandMessage}
            handleSaveNewErrand={handleSaveNewErrand}
            handleTitleKeyPress={handleTitleKeyPress}
            messageInputRef={messageInputRef}
          />
          )} */