import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import {
  MainContent,
  NavigationSection,
  NavigationHeader,
  NavigationItem,
  NavigationListContainer,
  CopilotSection,
  CopilotContainer,
  SelectedNavigationItem,
} from "../UserPage/UserPage-styling";
import {
  PageBackground,
  Container,
  ContentContainer,
  NavBar,
  LogoImg,
  Logo,
  Nav,
  NavItem,
  SignInButton,
  ScreenProtector,
} from "../Styling/NavBar-styling";
import { api_base_url, ax } from "../Utils";
import { useNavigate } from 'react-router-dom';
import { ErrorText, ParagraphInput, ParagraphSection, SaveTemplateButton, SaveTemplateContainer, TemplateFormContainer, TemplateFormSection, TemplateInfoSection, TemplateTextSection } from './TemplatesPage-styling';
import { FilterBox, FilterContainer, FilterText, Header } from '../CataloguePage/CataloguePage-styling';
import { GenerateResponseContainer } from '../ErrandsPage/ErrandsPage-styling';
import SaveTemplatePopUp from './SaveTemplatePopUp';
import HamburgerMenu from '../Components/HamburgerMenu';
import IconNavigation from '../Components/IconNavigation';


const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";
const sendMessageLink = "https://i.postimg.cc/XY0PqNg8/send-icon-2048x1863-u8j8xnb6.png";


const TemplatesPage = () => {
  const [firstText, setFirstText] = useState('');
  const [secondText, setSecondText] = useState('');
  const [thirdText, setThirdText] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const [selectedIndustry, setSelectedIndustry] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [showErrorText, setShowErrorText] = useState(false);
  const [showSaveTemplatePopUp, setShowSaveTemplatePopUp] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCompany && selectedIndustry && selectedCategory
      && firstText && secondText && thirdText) {
        setShowErrorText(false);
      }
  }, [selectedCompany, selectedCategory, selectedIndustry, firstText, secondText, thirdText]);

  const handleHome = async () => {
    navigate('/user');
  };
  const handleLanguages = async () => {
    //Show menu of possible languages to choose from
  };
  const handleSettings = async () => {
    //Show menu of settings
  };
  const handleSignOut = async () => {
    await ax.post('/auth/logout');
    window.location.href = 'https://ragge.ai/';
  };
  const handleFeedback = async () => {
    navigate('/feedback');
  };
  const handleCatalogue = async () => {
    navigate('/catalogue');
  };
  const handleStats = async () => {
    navigate('/stats')
  };
  const handleCopilot = async () => {
    navigate('/user');
  };
  const handleMarketing = async () => {
    navigate('/marketing');
  };

  const handleCompanyChange = (event: { target: { value: any; }; }) => {
    var element = document.getElementById("company");
    if (element instanceof HTMLElement && element.style) {
      element.style.outline = "none";
    }
    const value = event.target.value;
    setSelectedCompany(value);
  };

  const handleIndustryChange = (event: { target: { value: any; }; }) => {
    var element = document.getElementById("industry");
    if (element instanceof HTMLElement && element.style) {
      element.style.outline = "none";
    }
    const value = event.target.value;
    setSelectedIndustry(value);
  };

  const handleCategoryChange = (event: { target: { value: any; }; }) => {
    var element = document.getElementById("category");
    if (element instanceof HTMLElement && element.style) {
      element.style.outline = "none";
    }
    const value = event.target.value;
    setSelectedCategory(value);
  };

  const handleFirstTextChange = (e: any) => {
    var element = document.getElementById("text_1");
    if (element instanceof HTMLElement && element.style) {
      element.style.outline = "none";
    }
    setFirstText(e);
  }

  const handleSecondTextChange = (e: any) => {
    var element = document.getElementById("text_2");
    if (element instanceof HTMLElement && element.style) {
      element.style.outline = "none";
    }
    setSecondText(e);
  }

  const handleThirdTextChange = (e: any) => {
    var element = document.getElementById("text_3");
    if (element instanceof HTMLElement && element.style) {
      element.style.outline = "none";
    }
    setThirdText(e);
  }

  const toggleSaveTemplatePopUp = () => {
    if (selectedCompany !== 0 && selectedIndustry !== 0 && selectedCategory !== 0
      && firstText && secondText && thirdText) {
        setShowSaveTemplatePopUp(!showSaveTemplatePopUp);
      }
    else {
      setShowErrorText(true);
      if (firstText === '') {
        var element = document.getElementById("text_1");
        if (element instanceof HTMLElement && element.style) {
          element.style.outline = "2px solid red";
        }
      }
      if (secondText === '') {
        var element = document.getElementById("text_2");
        if (element instanceof HTMLElement && element.style) {
          element.style.outline = "2px solid red";
        }
      }
      if (thirdText === '') {
        var element = document.getElementById("text_3");
        if (element instanceof HTMLElement && element.style) {
          element.style.outline = "2px solid red";
        }
      }
      if (!selectedCompany) {
        var element = document.getElementById("company");
        if (element instanceof HTMLElement && element.style) {
          element.style.outline = "2px solid red";
        }
      }
      if (!selectedIndustry) {
        var element = document.getElementById("industry");
        if (element instanceof HTMLElement && element.style) {
          element.style.outline = "2px solid red";
        }
      }
      if (!selectedCategory) {
        var element = document.getElementById("category");
        if (element instanceof HTMLElement && element.style) {
          element.style.outline = "2px solid red";
        }
      }
    }
  };

  const handleSaveTemplate = () => {
    if (selectedCompany !== 0 && selectedIndustry !== 0 && selectedCategory !== 0
      && firstText && secondText && thirdText) {
      const requestBody: any = {
        "text_1": firstText,
        "text_2": secondText,
        "text_3": thirdText,
        "industry_id": selectedIndustry,
        "category_id": selectedCategory,
        "created_for_company_id": selectedCompany,
      };
      ax.post('/templates', requestBody);
    }
  };


  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <IconNavigation />
          <ContentContainer>
            <MainContent>
              
              <TemplateFormContainer>
                <TemplateFormSection>
                  <TemplateInfoSection>
                    <Header style={{'marginBottom': '35px'} as React.CSSProperties}>Skapa Mall</Header>
                    <FilterContainer>
                      <FilterText>Företag:</FilterText>
                      <FilterBox id='company' onChange={handleCompanyChange}>
                        <option value={0} selected disabled hidden>Välj</option>
                        <option value={1}>ICA</option>
                        <option value={2}>Demo</option>
                      </FilterBox>
                    </FilterContainer>
                    <FilterContainer>
                      <FilterText>Industri:</FilterText>
                      <FilterBox id='industry' onChange={handleIndustryChange}>
                        <option value={0} selected disabled hidden>Välj</option>
                        <option value={1}>Livsmedel</option>
                        <option value={2}>Hotell</option>
                      </FilterBox>
                    </FilterContainer>
                    <FilterContainer>
                      <FilterText>Kategori:</FilterText>
                      <FilterBox id='category' onChange={handleCategoryChange}>
                        <option value={0} selected disabled hidden>Välj</option>
                        <option value={1}>Pris</option>
                        <option value={2}>Hälsa</option>
                        <option value={3}>Service</option>
                        <option value={4}>Politik</option>
                        <option value={5}>Kvalitet</option>
                        <option value={6}>Diskriminering</option>
                      </FilterBox>
                    </FilterContainer>
                    {showErrorText &&
                      <ErrorText>
                        Error: Kunde inte skapa mallen eftersom alla fält inte är fyllda.<br/>
                        Se till att fylla alla fält och försök sedan igen!
                      </ErrorText>
                    }
                  </TemplateInfoSection>
                  <TemplateTextSection>
                    <ParagraphSection>
                      <FilterText>Översats:</FilterText>
                      <ParagraphInput placeholder='Skriv här...'
                        id='text_1'
                        value={firstText}
                        onChange={(x: any) => handleFirstTextChange(x.target.value)}
                      />
                    </ParagraphSection>
                    <ParagraphSection>
                      <FilterText>Undersats:</FilterText>
                      <ParagraphInput placeholder='Skriv här...'
                        id='text_2'
                        value={secondText}
                        onChange={(x: any) => handleSecondTextChange(x.target.value)}
                      />
                    </ParagraphSection>
                    <ParagraphSection>
                      <FilterText>Slutsats:</FilterText>
                      <ParagraphInput placeholder='Skriv här...'
                        id='text_3'
                        value={thirdText}
                        onChange={(x: any) => handleThirdTextChange(x.target.value)}
                      />
                    </ParagraphSection>
                    <SaveTemplateContainer>
                      <SaveTemplateButton onClick={toggleSaveTemplatePopUp}>Spara</SaveTemplateButton>
                    </SaveTemplateContainer>
                  </TemplateTextSection>
                </TemplateFormSection>
              </TemplateFormContainer>
            </MainContent>
            {showSaveTemplatePopUp &&
              <SaveTemplatePopUp
                toggleSaveTemplatePopUp={toggleSaveTemplatePopUp}
                handleSaveTemplate={handleSaveTemplate}
              />
            }
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};

export default TemplatesPage;

//extra tagg?
// <FilterContainer>
// <FilterText>Typ:</FilterText>
// <FilterBox onChange={handleTypeChange}>
//   <option value={0} selected disabled hidden>Välj</option>
//   <option value={1}>Kundsvar</option>
//   <option value={2}>Marknadsföring</option>
// </FilterBox>
// </FilterContainer>