import styled from 'styled-components';

export const TemplateFormContainer = styled.div`
  width: 80%;
  
  min-height: 240px;
  border-radius: 6px;
  align-items: flex-start;
  flex-direction: row;
  overflow-x: auto;
  display: flex;
  margin: 55px 5px 0 5px;
  box-shadow: inset 0px 0px 5px 0px #000, 0 0 5px #000;

`;

export const TemplateFormSection = styled.div`
  height: calc(100% - 5px);
  width: 100%;
  min-width: 240px;
  min-height: 240px;
  background-color: rgba(204, 204, 204, 0.2);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  overflow: auto;
  padding: 5px 5px 0px 5px;
`;

export const TemplateInfoSection = styled.div`
  width: 38%;
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 16px;
  margin: 50px 10px 10px 10px;
`;

export const TemplateTextSection = styled.div`
  width: 62%;
  height: 615px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ParagraphSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 20px;
`;

export const ParagraphInput = styled.textarea`
  height: 100px;
  width: calc(100% - 50px);
  padding: 5px;
  margin: 8px 10px 15px 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  color: #fff;
  resize: none;
  font-size: 16px;
  font-family: "Tahoma", "Arial", "Arabic Transparent";
  white-space: pre-line;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.5);

  &:focus {
    outline: none;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.6), 0 1px 6px rgba(0, 0, 0, 0.8);
  }
`;

export const SaveTemplateContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: end;
  align-items: flex-start;
`;


export const SaveTemplateButton = styled.button`
  background-color: #fff;
  color: #0f86a1;
  font-size: 24px;
  padding: 8px 20px 8px 20px;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.5);
  margin-right: 38px;
  margin-bottom: 15px;

  &:hover {
    background-color: #d8d8d8;
    box-shadow: 0 1px 8px #000;
  }
`;


// export const LoginBox = styled.div`
//   border: 2px solid #ccc;
//   padding: 20px;
//   border-radius: 8px;
//   width: 80vw;
//   height: 80vh;
//   max-width: 400px;
//   max-height: 435px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   background-color: #FFFFFF;
//   position: absolute;
//   z-index: 1;
// `;

// export const Title = styled.h1`
//   text-align: center;
//   font-size: 48px;
// `;

// export const Label = styled.label`
//   display: block;
//   width: 100%;
//   margin-bottom: 10px;
//   font-size: 24px;
// `;

// export const ComboBoxList = styled.ul`
//   position: absolute;
//   width: 100%;
//   max-height: 150px;
//   overflow-y: auto;
//   border: 1px solid #ccc;
//   border-top: none;
//   border-radius: 0 0 4px 4px;
//   list-style: none;
//   padding: 0;
//   margin: 0;
//   display: none;
//   background-color: white;
//   z-index: 3;
// `;

// export const ComboBoxContainer = styled.div`
//   position: relative;
//   display: inline-block;

//   &:focus-within ${ComboBoxList} {
//     display: block;
//   }
// `;

// export const ComboBoxInput = styled.input`
//   width: 200px;
//   padding: 8px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// `;

// interface ComboBoxItemProps {
//   selected: boolean;
// }

// export const ComboBoxItem = styled.li<ComboBoxItemProps>`
//   padding: 8px;
//   cursor: pointer;
//   background-color: ${(props) => (props.selected ? '#f2f2f2' : 'transparent')};

//   &:hover {
//     background-color: #f2f2f2;
//   }
// `;

// export const ComboBoxArrow = styled.span`
//   position: absolute;
//   top: 50%;
//   right: 8px;
//   transform: translateY(-50%);
// `;

// export const Button = styled.button`
//   background-color: #0f86a1;
//   color: #fff;
//   border: none;
//   margin-bottom: 5px;
//   padding: 10px;
//   border-radius: 6px;
//   cursor: pointer;
//   font-size: 24px;
//   font-weight: 500;
//   margin-top: 15px;
//   width: 100%;
//   box-sizing: border-box;

//   &:hover {
//     background-color: #0a6073;
//     box-shadow: 0 0 4px #0f86a1;
//   }
// `;