import React from "react";
import { AdminSettingContainer, AdminSettingSelectBox, AdminSettingText } from "../AdminPage-styling";


const AddDocument = () => {
  return (
    <>
      <AdminSettingContainer>
        <AdminSettingText>Dokument typ</AdminSettingText>
        <AdminSettingSelectBox></AdminSettingSelectBox>
      </AdminSettingContainer>

    </>
  );
};

export default AddDocument;