import React from 'react';
import { Errand } from "../Types";
import { formatDate } from "../../Utils";
import {
  ErrandsListSection,
  ErrandsHeader,
  CreateErrandWrapper,
  CreateErrandCard,
  ErrandsListContainer,
  ErrandCardStyling,
  ErrandCardTitle,
  ErrandCardText,
  ErrandCardDate,
} from "../ErrandsPage-styling"

type ErrandCardProps = {
  errand: Errand;
  setSelectedErrand: (x: Errand) => any;
  lastClickedId: number | null;
  updateDraft: () => void;
  isEmptyErrand: () => void;
};

const ErrandCard = (props: ErrandCardProps) => {
  return (
    <ErrandCardStyling onClick={() => {
      props.isEmptyErrand();
      props.updateDraft();
      props.setSelectedErrand(props.errand);
    }}
      selected={props.lastClickedId === props.errand.id}
    >
      <ErrandCardTitle>{props.errand.title}</ErrandCardTitle>
      <ErrandCardText>{props.errand.last_chat_item_text}</ErrandCardText>
      <ErrandCardDate>
        Skapat: {formatDate(props.errand.created_at)}
      </ErrandCardDate>
    </ErrandCardStyling>
  );
};

type Props = {
  errands: Errand[];
  setSelectedErrand: (x: Errand) => any;
  handleCreateErrand: () => void;
  lastClickedId: number | null;
  updateDraft: () => void;
  isEmptyErrand: () => void;
};

const ErrandsList = (props: Props) => {
  return (
    <ErrandsListSection>
      <ErrandsHeader>Recensioner</ErrandsHeader>
      <CreateErrandWrapper>
        <CreateErrandCard onClick={props.handleCreateErrand}>
          Ny Recension
        </CreateErrandCard>
      </CreateErrandWrapper>
      <ErrandsListContainer>
        {props.errands.map((errand, index) => (
          <ErrandCard
            key={index}
            errand={errand}
            setSelectedErrand={props.setSelectedErrand}
            lastClickedId={props.lastClickedId}
            updateDraft={props.updateDraft}
            isEmptyErrand={props.isEmptyErrand}
          />
        ))}
      </ErrandsListContainer>
    </ErrandsListSection>
  );
};
export default ErrandsList;

