import { Container } from "@mantine/core";
import { MainContent } from "../LoginPage/LoginPage-styling";
import { PageBackground, ScreenProtector, NavBar, LogoImg, Logo, Nav, NavItem, SignInButton, ContentContainer } from "../Styling/NavBar-styling";
import { RefreshCopilotButton, NavigationSection, NavigationHeader, NavigationListContainer, SelectedNavigationItem, NavigationItem } from "../UserPage/UserPage-styling";
import { ax } from "../Utils";
import { useNavigate } from "react-router-dom";
import { LandingHeader } from "../LandingPage/LandingPage-styling";
import '../CSS/pricing.css';
import HamburgerMenu from "../Components/HamburgerMenu";
import { useEffect } from "react";
import ChatbotContainer from "../ExternalChatbot/CodeForClients/ChatbotContainer";
import ChatbotToggleContainer from "../ExternalChatbot/CodeForClients/ChatbotToggleContainer";

const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";

declare global {
  interface Window {
    initializeChatbotWidget: (config: {
      src: string;
      clientId: string;
      theme: string;
      width: string;
      height: string;
      allow: string;
      sandbox: string;
    }) => void;
  }
}

const NotFoundPage = () => {

  const navigate = useNavigate();

  // const script = document.createElement('script');
  // script.src = 'http://localhost:3000/scripts/chatbotInit.js';
  // script.async = true;
  // script.onload = () => {
  //   if ((window as any).initializeChatbotWidget) {
  //     window.initializeChatbotWidget({
  //       src: 'http://localhost:3000/chatbot', // URL to the chatbot's web application
  //       clientId: 'uniqueClient123', // Unique client identifier
  //       theme: '#0f86a1', // Custom theme, e.g., 'light' or 'dark'
  //       width: '300px', // Width of the chatbot iframe
  //       height: '500px', // Height of the chatbot iframe
  //       allow: 'microphone; camera', // Permissions for the iframe
  //       sandbox: 'allow-same-origin allow-scripts' // Security sandboxing options
  //     });
  //   }
  // };
  // document.body.appendChild(script);

  // useEffect(() => {
  //   // Function to initialize the chatbot iframe
  //   const initializeChatbot = () => {
  //     const container = document.getElementById('chatbot-container');
  //     if (container && !container.querySelector('iframe')) {
  //       // Dynamically load the chatbotInit.js script
  //       const script = document.createElement('script');
  //       script.src = 'http://localhost:3000/scripts/ChatbotInit.js'; // Adjust the path as needed
  //       script.async = true;
  //       script.onload = () => {
  //         // Initialize the chatbot after the script is loaded
  //         if (window.initializeChatbotWidget) {
  //           window.initializeChatbotWidget({
  //             src: 'http://localhost:3000/chatbot', // URL to the chatbot's web application
  //             clientId: 'uniqueClient123', // Unique client identifier
  //             theme: 'light', // Custom theme, e.g., 'light' or 'dark'
  //             width: '300px', // Width of the chatbot iframe
  //             height: '500px', // Height of the chatbot iframe
  //             allow: 'microphone; camera', // Permissions for the iframe
  //             sandbox: 'allow-same-origin allow-scripts' // Security sandboxing options
  //           });
  //         }
  //       };

  //       document.body.appendChild(script);
  //     }
  //   };

  //   initializeChatbot();

  //   // Cleanup function to remove the script if the component unmounts
  //   return () => {
  //     const script = document.querySelector(`script[src='http://localhost:3000/scripts/chatbotInit.js']`);
  //     if (script) {
  //       document.body.removeChild(script);
  //     }
  //   };
  // }, []);


  
  const handleHome = async () => {
    navigate('/user');
  };
  const handleLanguages = async () => {
    //Show menu of possible languages to choose from
  };
  const handleSettings = async () => {
    //Show menu of settings
  };
  const handleSignOut = async () => {
    await ax.post('/auth/logout');
    window.location.href = 'https://ragge.ai/';
  };
  const handleFeedback = async () => {
    navigate('/feedback');
  };
  const handleCatalogue = async () => {
    navigate('/catalogue');
  };
  const handleStats = async () => {
    navigate('/stats')
  };
  const handleCreateTemplate = () => {
    navigate('/admin/templates')
  };
  const handleMarketing = () => {
    navigate('/marketing')
  };
  const handleCopilot = async () => {
    navigate('/user');
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo onClick={handleHome} >ragge.ai</Logo>
            <HamburgerMenu />
            <SignInButton onClick={handleSignOut}>Logga ut</SignInButton>
          </NavBar>
          <ContentContainer>
            <MainContent>
              <NavigationSection style={{justifySelf: 'start'}}>
                <NavigationHeader>Navigation</NavigationHeader>
                <NavigationListContainer>
                  <NavigationItem onClick={handleCopilot}>Copilot</NavigationItem>
                  <NavigationItem onClick={handleFeedback}>Feedback</NavigationItem>
                  <NavigationItem onClick={handleCatalogue}>Kunskapsbanken</NavigationItem>
                  <NavigationItem onClick={handleMarketing}>Marknadsföring</NavigationItem>
                  <NavigationItem onClick={handleStats}>Statistik</NavigationItem>
                  <NavigationItem onClick={handleCreateTemplate}>Skapa mallar</NavigationItem>
                </NavigationListContainer>
              </NavigationSection>
              <LandingHeader>
                Page not found!
              </LandingHeader>
            </MainContent>
          </ContentContainer>
        </Container>
        {/* <ChatbotToggleContainer /> */}
        <ChatbotContainer />



      </ScreenProtector>
    </PageBackground>
  );
};

export default NotFoundPage;