import { z } from "zod";

export type Errand = {
  title: string;
  id: number;
  created_at: string;
  last_chat_item_text: string;
};

export type Template = {
  id: number;
  text_1: string;
  text_2: string;
  text_3: string;
  created_at: string;
  checked: boolean;
};

export type HistResponse = {
  id: number;
  text: string;
  checked: boolean;
  errand_id: number;
};

export type FAQ = {
  answer_id: number;
  answer_text: string;
  question_id: number;
  question_text: string;
  title: string;
  checked: boolean;
};

export type Response = {
  id: number;
  text: string;
  class_type: string;
  created_at: string;
  created_by: string;
};

export type Prompt = {
  id: number;
  prompt: string;
  system_prompt: string;
  created_at: string;
};

export type Draft = {
  text: string;
  errand_id: number;
};

export type User = {
  first_name: string;
  last_name: string;
  id: string;
  company_id: number;
  company_name: string;
  is_superuser: boolean;
  copilot_language_name: string;
  interface_language_name: string;
};

export type Company = {
  name: string;
  id: number;
  industry_id: number;
  default_copilot_language_id: number;
  default_interface_language_id: number;
  country_name: string;
  business_description: string;
  pricing_plan_id: number;
};

export type KPIs = {
  nbr_open_errands: number;
  nbr_total_errands: number;
  nbr_closed_errands_last_30_days: number;
  nbr_responses_last_30_days: number;
};

export type Marketing = {
  title: string;
  text: string;
  body: string;
  text_type: string;
  id: number;
  created_at: string;
  last_updated_at: string;
};

export type FeedbackType = "email" | "review" | null;


export type Feedback = {
  id: number;
  title: string;
  source_type: FeedbackType;
  latest_message: Message;
  summary: string;
  rating: number;
  last_updated_at: string;
};

export type Message = {
  id: number;
  feedback_id: number;
  role: string;
  text: string;
  raw_body: string;
  body: string;
  quote_body: string;
  receiver_contact_info: [{
    name: string;
    email: string;
  }];
  sender_contact_info: {
    name: string;
    email: string;
  };
  sent_at: string;
  last_updated_at: string;
};

export type PricingPlan = {
  id: number;
  name: string;
  fixed_price_per_month: "";
  nbr_max_users: string;
  nbr_copilot_drafts: string;
};

export type Language = {
  name: string;
  code: string;
  id: number;
};

export type Industry = {
  name: string;
  id: number;
};

export type Inbox = {
  email: string;
  id: number;
  inbox_name: string;
};

export const signUpSchema = z
  .object({
    pricingPlanId: z.number().min(1).max(4),
    companyName: z.string().min(1, "Företagsnamn krävs"),
    industryId: z.number().int().min(1),
    defaultCopilotLanguageId: z.number().int().min(1),
    defaultInterfaceLanguageId: z.number().int().min(1),
    country: z.string().min(1, "Land krävs"),

    firstName: z.string().min(1, "Förnamn krävs"),
    lastName: z.string().min(1, "Efternamn krävs"),
    email: z.string().email(),
    phone: z.string().min(9, "Ogiltigt telefonnummer"),
    password: z.string()
      .min(8, "Lösenordet måste vara minst 8 tecken långt")
      .superRefine((value, ctx) => {
        if (!/[A-Z]/.test(value)) {
          ctx.addIssue({
            code: 'custom',
            message: "Lösenordet måste innehålla minst en stor bokstav",
          });
        }
        if (!/[a-z]/.test(value)) {
          ctx.addIssue({
            code: 'custom',
            message: "Lösenordet måste innehålla minst en liten bokstav",
          });
        }
        if (!/\d/.test(value)) {
          ctx.addIssue({
            code: 'custom',
            message: "Lösenordet måste innehålla minst en siffra",
          });
        }
        if (!/\W/.test(value)) {
          ctx.addIssue({
            code: 'custom',
            message: "Lösenordet måste innehålla minst ett specialtecken (t.ex. !@#$%^&*)",
          });
        }
      }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Upprepat lösenord måste matcha lösenordet",
    path: ["confirmPassword"],
  });

export type TSignUpSchema = z.infer<typeof signUpSchema>;

export const forgotPasswordSchema = z
  .object({
    email: z.string().email("Ogiltig e-postadress"),
  });

export type TForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>;

export const resetPasswordSchema = z
  .object({
    password: z.string()
    .min(8, "Lösenordet måste vara minst 8 tecken långt")
    .superRefine((value, ctx) => {
      if (!/[A-Z]/.test(value)) {
        ctx.addIssue({
          code: 'custom',
          message: "Lösenordet måste innehålla minst en stor bokstav",
        });
      }
      if (!/[a-z]/.test(value)) {
        ctx.addIssue({
          code: 'custom',
          message: "Lösenordet måste innehålla minst en liten bokstav",
        });
      }
      if (!/\d/.test(value)) {
        ctx.addIssue({
          code: 'custom',
          message: "Lösenordet måste innehålla minst en siffra",
        });
      }
      if (!/\W/.test(value)) {
        ctx.addIssue({
          code: 'custom',
          message: "Lösenordet måste innehålla minst ett specialtecken (!@#$%^&*)",
        });
      }
    }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Upprepat lösenord måste matcha lösenordet",
    path: ["confirmPassword"],
  });

export type TResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

export const addUserSchema = z
  .object({
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    email: z.string().email(),
    isSuperuser: z.union([z.literal("true"), z.literal("false")]).transform((val) => val === "true"),
  });

export type TAddUserSchema = z.infer<typeof addUserSchema>;

export const websiteSchema = z
  .object({
    website: z.string().url("Ogiltig webbadress"),
    description: z.string().min(1, "Beskrivning krävs"),
  });

export type TWebsiteSchema = z.infer<typeof websiteSchema>;

export const addInboxSchema = z
  .object({
    email: z.string().email("Ogiltig e-postadress"),
    inboxName: z.string().min(1, "Namn krävs"),
  });

export type TAddInboxSchema = z.infer<typeof addInboxSchema>;
