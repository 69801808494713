import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import {
  MainContent,
  NavigationSection,
  NavigationHeader,
  NavigationItem,
  NavigationListContainer,
  CopilotSection,
  ConversationSection,
  CopilotContainer,
  CopilotChatItem,
  ChatProfileImgContainer,
  CopilotProfileImg,
  CopilotChatItemText,
  CopilotInputSection,
  CopilotInput,
  SendIcon,
  CopilotImgAnimation,
  BlinkingSpan,
  RefreshCopilotButton,
  SelectedNavigationItem,
} from "./UserPage-styling";
import {
  PageBackground,
  Container,
  ContentContainer,
  NavBar,
  LogoImg,
  Logo,
  Nav,
  NavItem,
  SignInButton,
  ScreenProtector,
} from "../Styling/NavBar-styling";
import { api_base_url, ax } from "../Utils";
import { useNavigate } from 'react-router-dom';
import { User } from '../Types';
import RefreshCopilotPopUp from './RefreshCopilotPopUp';
import MarkdownRenderer from '../Components/MarkdowRenderer';
import HamburgerMenu from '../Components/HamburgerMenu';
import IconNavigation from '../Components/IconNavigation';


const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";
const sendMessageLink = "https://i.postimg.cc/XY0PqNg8/send-icon-2048x1863-u8j8xnb6.png";
const companyImageLink = 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/ICA_logo.svg/1200px-ICA_logo.svg.png';

// type CopilotProps = {
//   copilotChat: string[];
//   companyImageLink: string;
// };

// const CopilotConversation = (props: CopilotProps) => {
//   return (
//     <div>
//       {props.copilotChat}
//       <CopilotChatItem $isCopilot={true} >
//         <ChatProfileImgContainer>
//           <CopilotProfileImg src={props.companyImageLink} />
//         </ChatProfileImgContainer>
//       </CopilotChatItem>
//     </div>
//   )
// };

export type UserProps = {
  currentUser: User | null;
  setCurrentUser: (u: User) => any;
};

const UserPage = (props: UserProps) => {

  const [copilotChat, setCopilotChat] = useState<string[]>(['']);
  const [inputText, setInputText] = useState('');
  //const [sentInputText, setSentInputText] = useState('');
  const [chatId, setChatId] = useState<number | null>(null);
  const conversationRef = useRef<HTMLDivElement>(null);
  const [answerLoading, setAnswerLoading] = useState<boolean[]>([]);
  const [showRefreshPopUp, setShowRefreshPopUp] = useState(false);
  const [refreshTriggered, setRefreshTriggered] = useState(false);
  
  const copilotEventSourceRef = useRef<EventSource | null>(null);
  const hasStreamedWelcomeMessage = useRef(false);
  const isFetchingUser = useRef(false);
  const [copilotLogoLink, setCopilotLogoLink] = useState('');


  const navigate = useNavigate();

  useEffect(() => {
    ax.get('/copilot_config/for_module?module_name=internal_chatbot')
      .then(response => {
        setCopilotLogoLink(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching copilot logo!', error);
      });
  }, []);

  useEffect(() => {
    if (!props.currentUser && !isFetchingUser.current) {
      isFetchingUser.current = true;
      ax.get('/users/company/whoami')
        .then((response) => props.setCurrentUser(response.data));
    }
    //get company image and set companyImageLink
    if (copilotChat.length === 1 && copilotChat[0] === '' && !hasStreamedWelcomeMessage.current) {
      hasStreamedWelcomeMessage.current = true;
      streamWelcomeMessage();
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
    if (copilotChat[0] === "" && refreshTriggered) {
      setRefreshTriggered(false);
      streamWelcomeMessage();
    }
  }, [copilotChat]);

  useEffect(() => {
    if (inputText === '') {
      const textarea = document.getElementById('copilotInputId');
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = (textarea.scrollHeight - 30 > 100 ? 100 : textarea.scrollHeight - 30) + 'px';
      }
    }
  }, [inputText]);

  const handleHome = async () => {
    navigate('/user');
  };
  const handleLanguages = async () => {
    //Show menu of possible languages to choose from
  };
  const handleSettings = async () => {
    //Show menu of settings
  };
  const handleSignOut = async () => {
    await ax.post('/auth/logout');
    window.location.href = 'https://ragge.ai/';
  };
  const handleFeedback = async () => {
    navigate('/feedback');
  };
  const handleCatalogue = async () => {
    navigate('/catalogue');
  };
  const handleStats = async () => {
    navigate('/stats')
  };
  const handleCreateTemplate = () => {
    navigate('/admin/templates')
  };
  const handleMarketing = () => {
    navigate('/marketing')
  };

  const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 30 > 100 ? 100 : e.target.scrollHeight - 30) + 'px';
  };

  const streamWelcomeMessage = async() => {
    if (copilotChat.length === 1) {
      setAnswerLoading([true]);

      if (copilotEventSourceRef.current) {
        copilotEventSourceRef.current.close();
      }

      copilotEventSourceRef.current = new EventSource(
        `${api_base_url}/copilot/get_welcome_message`, 
        {withCredentials: true}
      );

      copilotEventSourceRef.current.onmessage = function(event) {
        const data = JSON.parse(event.data);

        if (data.chat_id) {
          setChatId(data.chat_id);
        }
        else if (data.text) {
          setCopilotChat((prevChat) => {
            const lastChatItem = prevChat[0];
            const updatedLastChatItem = lastChatItem ? lastChatItem + data.text : data.text;
            return [...prevChat.slice(0, -1), updatedLastChatItem];
          });
        }
        /*else {
          eventSource.close();
        }*/
      };

      copilotEventSourceRef.current.onerror = (event) => {
        // console.error("EventSource failed:", event);
        copilotEventSourceRef.current?.close();
        setAnswerLoading([false]);
      };
    }
  };

  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    setCopilotChat([...copilotChat, inputText, '']);
    //setSentInputText(inputText);
    //setInputText('');
    generateAnswer();
  };

  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const generateAnswer = async () => {
    
    if (copilotEventSourceRef.current) {
      copilotEventSourceRef.current.close();
    }

    const requestBody: any = {
      text: inputText,
      module: 'general',
      chat_id: chatId,
    };

    setAnswerLoading([...answerLoading, true]);
    
    const getAnswerId = await ax.post('/copilot/request_params', requestBody);

    copilotEventSourceRef.current = new EventSource(
      `${api_base_url}/copilot/get_answer?query_id=${getAnswerId.data}`, 
      {withCredentials: true}
    );

  
    setInputText('');

    copilotEventSourceRef.current.onmessage = function(event) {
      const data = JSON.parse(event.data);

      if (data.copilot_text) {
        setCopilotChat((prevChat) => {
          const lastChatItem = prevChat[prevChat.length - 1];
          const updatedLastChatItem = lastChatItem ? lastChatItem + data.copilot_text : data.copilot_text;
          return [...prevChat.slice(0, -1), updatedLastChatItem];
        });
      }
      else if (data.content_text) {
        setCopilotChat((prevChat) => {
          const lastChatItem = prevChat[prevChat.length - 1];
          const updatedLastChatItem = lastChatItem ? lastChatItem + data.content_text : data.content_text;
          return [...prevChat.slice(0, -1), updatedLastChatItem];
        });
      }

    };

    copilotEventSourceRef.current.onerror = (event) => {
        // console.error("EventSource failed:", event);
        copilotEventSourceRef.current?.close();
        setAnswerLoading((prevAnswerLoading) => {
          //Create a new array with all elements except the last one
          const newArray = prevAnswerLoading.slice(0, -1);
          //Add false as the new last element
          newArray.push(false);
          return newArray;
        });

    };
  };

  const toggleShowRefreshPopUp = () => {
    setShowRefreshPopUp(!showRefreshPopUp);
  };

  const handleRefreshCopilot = () => {
    setRefreshTriggered(true);
    setCopilotChat(['']);
    setShowRefreshPopUp(false);
    setInputText('');
    setChatId(null);
  };

  const scrollToBottom = () => {
    if (conversationRef.current && copilotChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo onClick={handleHome} >ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <IconNavigation />
          <ContentContainer>
            <MainContent>
              <RefreshCopilotButton onClick={toggleShowRefreshPopUp}>&#10227;</RefreshCopilotButton>
              {/* <NavigationSection>

                <NavigationHeader>Navigation</NavigationHeader>

                <NavigationListContainer>
                  <SelectedNavigationItem>Copilot</SelectedNavigationItem>
                  <NavigationItem onClick={handleFeedback}>Feedback</NavigationItem>
                  <NavigationItem onClick={handleCatalogue}>Kunskapsbanken</NavigationItem>
                  <NavigationItem onClick={handleMarketing}>Marknadsföring</NavigationItem>
                  <NavigationItem onClick={handleStats}>Statistik</NavigationItem>
                  <NavigationItem onClick={handleCreateTemplate}>Skapa mallar</NavigationItem>
                </NavigationListContainer>
              </NavigationSection> */}
              <CopilotContainer>
                <CopilotSection>
                  <ConversationSection ref={conversationRef}>
                    {copilotChat.map((item, index) => {
                      if (index % 2 === 0) { // Check if index is even
                        return (
                          <CopilotChatItem key={index} $isCopilot={true} >
                            {answerLoading[index/2] &&
                              <CopilotImgAnimation></CopilotImgAnimation>
                            }
                              <ChatProfileImgContainer>
                                {copilotLogoLink ? (
                                  <CopilotProfileImg src={copilotLogoLink} alt="AI"/>
                                ) : (
                                  "AI"
                                )}
                              </ChatProfileImgContainer>
                              {answerLoading[index/2] &&
                                <MarkdownRenderer markdownText={item} isStreaming={true} fontSize={18} />
                              }
                              {!answerLoading[index/2] &&
                                <MarkdownRenderer markdownText={item} isStreaming={false} fontSize={18} />
                              }
                          </CopilotChatItem>
                        )
                      } else {
                        return (
                          <CopilotChatItem key={index} $isCopilot={false} >
                            <ChatProfileImgContainer>
                              {props.currentUser?.first_name.charAt(0)}
                            </ChatProfileImgContainer>
                            <CopilotChatItemText>{item}</CopilotChatItemText>
                          </CopilotChatItem>
                        )
                      }
                    })}

                  </ConversationSection>
                  <CopilotInputSection>
                    <SendIcon src={sendMessageLink}
                      onClick={handleSend}
                    />
                    <CopilotInput
                      id='copilotInputId'
                      value={inputText}
                      onChange={handleTextareaChange}
                      placeholder="Skriv din fråga här..."
                      rows={1}
                      onKeyDown={handleEnterSend}
                    />
                  </CopilotInputSection>

                </CopilotSection>
              </CopilotContainer>
            </MainContent>
            {showRefreshPopUp &&
              <RefreshCopilotPopUp
                toggleShowRefreshPopUp={toggleShowRefreshPopUp}
                handleRefreshCopilot={handleRefreshCopilot}
              />
            }
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};

export default UserPage;

/**                              <Loader>
                                <Ball />
                                <Ball />
                                <Ball />
                              </Loader> */