import React from 'react';
import { formatDate } from "../../Utils";
import {
  ErrandsListSection,
  ErrandsHeader,
  CreateErrandWrapper,
  CreateErrandCard,
  ErrandsListContainer,
  ErrandCardStyling,
  ErrandCardTitle,
  ErrandCardText,
  ErrandCardDate,
} from "../../ErrandsPage/ErrandsPage-styling"
import { Feedback } from '../../Types';

type FeedbackCardProps = {
  feedback: Feedback;
  setSelectedFeedback: (x: Feedback) => any;
  lastClickedId: number | null;
  formatDateTime: (date: string) => string;
};

const MarketingCard = (props: FeedbackCardProps) => {
  
  return (
    <ErrandCardStyling onClick={() => {
      props.setSelectedFeedback(props.feedback);
    }}
      selected={props.lastClickedId === props.feedback.id}
    >
      <ErrandCardTitle>{props.feedback.title}</ErrandCardTitle>
      <ErrandCardText>{props.feedback.summary}</ErrandCardText>
      <ErrandCardDate>
        {props.formatDateTime(props.feedback.last_updated_at)}
      </ErrandCardDate>
    </ErrandCardStyling>
  );
};

type Props = {
  feedbacks: Feedback[];
  setSelectedFeedback: (x: Feedback | null) => any;
  lastClickedId: number | null;
  formatDateTime: (date: string) => string;
};

const FeedbackList = (props: Props) => {
  return (
    <ErrandsListSection>
      <ErrandsHeader>Feedback</ErrandsHeader>
      <ErrandsListContainer>
        {props.feedbacks.map((feedback, index) => (
          <MarketingCard
            key={index}
            feedback={feedback}
            setSelectedFeedback={props.setSelectedFeedback}
            lastClickedId={props.lastClickedId}
            formatDateTime={props.formatDateTime}
          />
        ))}
      </ErrandsListContainer>
    </ErrandsListSection>
  );
};
export default FeedbackList;

