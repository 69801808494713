import styled from 'styled-components';

export const ExternalChatbotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  width: 100%;
  height: 100vh;
  background-color: #fff;
`;

export const ExternalChatbotHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: #0f86a1;
  padding: 7px 10px;
  box-shadow: 0px 0px 3px 1px #999;
`;

export const ExternalChatbotHeaderLogoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ExternalChatbotHeaderLogo = styled.img`
  max-width: 50px;
  max-height: 35px;
`;

export const ExternalChatbotHeaderTitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  margin-left: 10px;
`;

export const ExternalChatbotHeaderButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ExternalChatbotMinimizeButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding-bottom: 2px;
  margin-top: -10px;
`;

export const ExternalChatbotMessagesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  flex-grow: 1;
  overflow-y: auto;
  font-size: 0.9rem;
`;

export const ExternalChatbotMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: safe end;
  width: 100%;
  margin-bottom: 10px;
`;

export const ExternalChatbotMessagerAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const ExternalChatbotMessage = styled.div`
  background-color: #C3E0E7;
  border-radius: 10px 10px 10px 0;
  padding: 0.5rem;
  max-width: 70%;
`;

export const ExternalChatbotCustomerMessage = styled(ExternalChatbotMessage)`
  background-color: #0f86a1;
  color: #fff;
  border-radius: 10px 10px 0 10px;
  margin-left: auto;
  margin-right: 5px;
`;

export const ExternalChatbotFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: safe end;
  background-color: #fff;
  padding: 8px 10px;
  box-shadow: 0px -5px 15px 0px #f0f0f0;
`;

export const ExternalChatbotInput = styled.textarea`
  height: auto;
  max-height: 200px;
  padding: 10px;
  border: 1px solid #0f86a1;
  border-radius: 5px;
  resize: none;
  font-size: 1rem;
  border: none;

  
  &:focus {
    outline: none;
  }
`;

export const ExternalChatbotSendButton = styled.button`
  background-color: #0f86a1;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1rem;
  height: 30px;
  width: 30px;
  padding-right: 7px;
  padding-top: 5px;
  margin-bottom: 5px;
`;