// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-container {
  /* General styles for the markdown container */
  color: #fff;
  font-size: 18px;
  margin: 0px 15px 5px 5px;
  line-height: 1.25;
}
`, "",{"version":3,"sources":["webpack://./src/Components/MarkdownRenderer-styling.css"],"names":[],"mappings":"AAAA;EACE,8CAA8C;EAC9C,WAAW;EACX,eAAe;EACf,wBAAwB;EACxB,iBAAiB;AACnB","sourcesContent":[".markdown-container {\n  /* General styles for the markdown container */\n  color: #fff;\n  font-size: 18px;\n  margin: 0px 15px 5px 5px;\n  line-height: 1.25;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
