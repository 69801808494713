import styled from "styled-components";

export const WelcomeSection = styled.div`
  width: 100%;
  margin-top: 25px;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  display: flex;
`;

export const WelcomeHeader = styled.h1`
  font-size: 60px;
  color: #fff;
  font-weight: 400;
  width: 100%;
  text-align: center;
`;

export const DashboardSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: safe center;
`;

export const DashboardHeader = styled.a`
  font-size: 40px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
`;

export const DashboardRowHeader = styled.a`
  font-size: 26px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
`;

export const DashboardRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: safe center;
  padding: 15px 10px 0px 10px;
  margin-bottom: 10px;
  overflow: auto;
`;

export const DashboardStatBox = styled.div`
  height: 150px;
  width: 210px;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  align-content: safe center;
  padding: 10px 5px 15px 5px;
  justify-content: space-between;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

