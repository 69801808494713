import React from 'react';
import {
  ErrandsListSection,
  ErrandsHeader,
  CreateErrandWrapper,
  CreateErrandCard,
  ErrandsListContainer,
  ErrandCardStyling,
  ErrandCardTitle,
  ErrandCardText,
  ErrandCardDate,
} from "../../ErrandsPage/ErrandsPage-styling"
import { Marketing } from '../../Types';

type MarketingCardProps = {
  marketing: Marketing;
  setSelectedMarketing: (x: Marketing) => any;
  lastClickedId: number | null;
  //isEmptyMarketing: () => void;
  formatDateTime: (date: string) => string;
};

const MarketingCard = (props: MarketingCardProps) => {
  return (
    <ErrandCardStyling onClick={() => {
      props.setSelectedMarketing(props.marketing);
    }}
      selected={props.lastClickedId === props.marketing.id}
    >
      <ErrandCardTitle>{props.marketing.title}</ErrandCardTitle>
      <ErrandCardText>{props.marketing.text}</ErrandCardText>
      <ErrandCardDate>
        {props.formatDateTime(props.marketing.last_updated_at)}
      </ErrandCardDate>
    </ErrandCardStyling>
  );
};

type Props = {
  marketings: Marketing[];
  setSelectedMarketing: (x: Marketing | null) => any;
  handleNewMarketing: () => void;
  lastClickedId: number | null;
  //updateDraft: () => void;
  formatDateTime: (date: string) => string;
};

const CampaignList = (props: Props) => {
  return (
    <ErrandsListSection>
      <ErrandsHeader>Marknadsföring</ErrandsHeader>
      <CreateErrandWrapper>
        <CreateErrandCard onClick={props.handleNewMarketing}>
          Ny Kampanj
        </CreateErrandCard>
      </CreateErrandWrapper>
      <ErrandsListContainer>
        {props.marketings.map((marketing, index) => (
          <MarketingCard
            key={index}
            marketing={marketing}
            setSelectedMarketing={props.setSelectedMarketing}
            lastClickedId={props.lastClickedId}
            formatDateTime={props.formatDateTime}
          />
        ))}
      </ErrandsListContainer>
    </ErrandsListSection>
  );
};
export default CampaignList;

