import styled from 'styled-components';

export const PageBackground = styled.div`
  
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to top left, #ebc7b5, #0f86a1);
  overflow-x: auto;
  flex-direction: column;
`;//Sätt background baserat på mode, lägg till darkmode som är #212121 eller #333333 till #212121 i gradient

export const ScreenProtector = styled.div`
  width: 100%;
  min-height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  max-width: 1440px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 2;
`;

export const OverflowContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 2;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 10px);;
  justify-content: center;
  align-items: safe start;
  overflow: auto;
  
  @media (max-width: calc(1440px + 6rem)) {
    margin-left: 1rem;
    width: calc(100% - 1rem);
  }
  @media (max-width: calc(1440px + 4rem)) {
    margin-left: 2rem;
    width: calc(100% - 2rem);
  }
  @media (max-width: calc(1440px + 2rem)) {
    margin-left: 3rem;
    width: calc(100% - 3rem);
  }

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const NavBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  z-index: 2;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 101;
`;

export const LogoImg = styled.img`
  height: 130%;
  min-height: 60px;
  margin-right: 30px;
  margin-left: 20px;
  cursor: pointer;

  width: 0;
  margin: 0;
`;

export const Logo = styled.h1`
  margin: 0;
  margin-right: auto;
  font-size: 42px;
  font-weight: 500;
  margin-left: 20px;
  cursor: pointer;
  z-index: 101;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

export const NavItem = styled.button`
  margin-right: 50px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 30px;

  &:last-child {
    margin-right: 0px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const SignInButton = styled.button`
  background-color: #fff;
  color: #0f86a1;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 30px;
  font-weight: 400;
  margin-left: 50px;
  margin-right: 30px;

  &:hover {
    background-color: #d8d8d8;
    box-shadow: 0 0 10px #0f86a1;
  }
`;
