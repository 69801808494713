import axios from "axios";
// import { useNavigate } from 'react-router-dom';

export function formatDate(dateTimeString: string): string {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

// AXIOS HTTP CLIENT BELOW
export var api_base_url: string

// read API url from env
if (!process.env.REACT_APP_API_BASE_URL) {
  api_base_url= "http://127.0.0.1:8000"
} else {  
  api_base_url = process.env.REACT_APP_API_BASE_URL  // set through Azure
}


// init axios http client with base url and to send credentials cookie
export const ax = axios.create({
  withCredentials: true,
  baseURL: api_base_url
})

// set axios to redirect to login if it recieves a 401 from backend
ax.interceptors.response.use((response) => {
  return response;
}, (error) => { // Anything except 2XX goes to here
  const status = error.response?.status || 500;
  // const navigate = useNavigate();
  if (status === 401) {
      // navigate('/login');
      window.location.replace(window.location.protocol + "//" + window.location.host + "/login")
  } else {
      return Promise.reject(error); // Delegate error to calling side
  }
});