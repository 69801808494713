import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  PageBackground,
  Container,
  ContentContainer,
  NavBar,
  LogoImg,
  Logo,
  Nav,
  NavItem,
  SignInButton,
  ScreenProtector,
} from "../Styling/NavBar-styling";
import { useNavigate } from 'react-router-dom';
import { ax, api_base_url } from "../Utils";
import TextEditor from './Sections/TextEditor';
import CampaignList from './Sections/CampaignList';
import MarketingCopilot from './Sections/MarketingCopilot';
import { Marketing, User } from '../Types';
import { Id, toast } from 'react-toastify';
import { Editor } from '@tiptap/react';
import { debounce } from 'lodash';
import IconNavigation from '../Components/IconNavigation';
import HamburgerMenu from '../Components/HamburgerMenu';


const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";


type Props = {
  currentUser: User | null;
  setCurrentUser: (u: User) => any;
};

const MarketingPage = (props: Props) => {

  const [copilotChat, setCopilotChat] = useState<string[]>(['']);
  const [inputText, setInputText] = useState('');
  const [answerLoading, setAnswerLoading] = useState<boolean[]>([]);
  const [copilotChatId, setCopilotChatId] = useState<number | null>(null);
  const [content, setContent] = useState('');
  //const [wordCount, setWordCount] = useState(0);
  const [promptContent, setPromptContent] = useState('');
  const [body, setBody] = useState('');
  const conversationRef = useRef<HTMLDivElement>(null);
  const copilotEventSourceRef = useRef<EventSource | null>(null);
  const toastId = useRef<Id | null>(null);
  const editorRef = useRef<Editor | null>(null);


  //CampaignList consts
  const [marketings, setMarketings] = useState<Marketing[]>([]);
  const [selectedMarketing, setSelectedMarketing] = useState<Marketing | null>(null);
  const [lastClickedId, setLastClickedId] = useState<number | null>(null);

  const marketingsLengthRef = useRef(marketings.length);
  const selectedMarketingRef = useRef(selectedMarketing);
  const isFetchingUser = useRef(false);
  const isFetchingList = useRef(false);


  const navigate = useNavigate();


  useEffect(() => {
    if (!props.currentUser && !isFetchingUser.current) {
      isFetchingUser.current = true;
      ax.get('/users/company/whoami')
        .then((response) => props.setCurrentUser(response.data));
    }
    if (marketings.length === 0 && !isFetchingList.current){
      isFetchingList.current = true;
      ax.get('marketing/all')
        .then((response) => {
          setMarketings(response.data)
          marketingsLengthRef.current = response.data.length
        }
      );
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [copilotChat]);

  useEffect(() => {
    if (inputText === '') {
      const textarea = document.getElementById('marketingCopilotInputId');
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = (textarea.scrollHeight - 30 > 100 ? 100 : textarea.scrollHeight - 30) + 'px';
      }
    }
  }, [inputText]);


  //CampaignList useEffects:
  // useEffect(() => {
  //   if (marketings.length === marketingsLengthRef.current+1) {
  //     setSelectedMarketing(marketings[0]);
  //   }
  //   marketingsLengthRef.current = marketings.length;
  // }, [marketings]);

  useEffect(() => {
    if (selectedMarketing && (selectedMarketing.id === selectedMarketingRef.current?.id || marketings.length !== marketingsLengthRef.current)) {
      marketingsLengthRef.current = marketings.length;
      setLastClickedId(selectedMarketing.id);
    }
    else {
      setCopilotChatId(null);
      if (selectedMarketing) {
        setLastClickedId(selectedMarketing.id);
        updatePage(selectedMarketing.id);
      }
      else {
        setLastClickedId(null);
        blankPage();
      }
    }
    selectedMarketingRef.current = selectedMarketing;
  }, [selectedMarketing]);

  useEffect(() => {
    setPromptContent(content);
  }, [content]);

  // useEffect(() => {
  //   countWords();
  // }, [promptContent]);

  // const countWords = () => {
  //   if (promptContent.trim() === '') {
  //     setWordCount(0);
  //   }
  //   else {
  //     const wordArray = promptContent.trim().split(/\s+/);
  //     setWordCount(wordArray.length);
  //   }
  // };

  const updatePage = async (id: number) => {
    await ax.get(`/marketing?marketing_id=${id}`)
      .then((response) => setContent(response.data.body));
    setCopilotChat(['']);
    streamWelcomeMessage();
  };

  const blankPage = () => {
    setContent('');
    setCopilotChat(['']);
    streamWelcomeMessage();
  };

  const handleHome = async () => {
    navigate('/user');
  };
  const handleSettings = async () => {
    //settings
  };
  const handleLanguages = async () => {
    //Show menu of possible languages to choose from
  };
  const handleSignOut = async () => {
    await ax.post('/auth/logout');
    window.location.href = 'https://ragge.ai/';
  };

  const handleNewMarketing = () => {
    setSelectedMarketing(null);
  };


  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    setCopilotChat([...copilotChat, inputText, '']);
    //setSentInputText(inputText);
    //setInputText('');
    generateAnswer();
  };

  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 30 > 100 ? 100 : e.target.scrollHeight - 30) + 'px';
  };
  
  const scrollToBottom = () => {
    if (conversationRef.current && copilotChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const streamWelcomeMessage = async() => {

    setAnswerLoading([true]);
    if (copilotEventSourceRef.current) {
      copilotEventSourceRef.current.close();
    }

    if (selectedMarketing) {
      copilotEventSourceRef.current = new EventSource(
        `${api_base_url}/copilot/get_welcome_message?module=marketing&module_ref_id=${selectedMarketing.id}`, 
        {withCredentials: true}
      );

      copilotEventSourceRef.current.onmessage = function(event) {
        const data = JSON.parse(event.data);

        if (data.chat_id) {
          setCopilotChatId(data.chat_id);
        }
        else if (data.text) {
          setCopilotChat((prevChat) => {
            const lastChatItem = prevChat[0];
            const updatedLastChatItem = lastChatItem ? lastChatItem + data.text : data.text;
            return [...prevChat.slice(0, -1), updatedLastChatItem];
          });
        }
        /*else {
          eventSource.close();
        }*/
      };

      copilotEventSourceRef.current.onerror = (event) => {
        // console.error("EventSource failed:", event);
        copilotEventSourceRef.current?.close();
        setAnswerLoading([false]);
      };
    }
    else {
      copilotEventSourceRef.current = new EventSource(
        `${api_base_url}/copilot/get_welcome_message?module=marketing`, 
        {withCredentials: true}
      );

      copilotEventSourceRef.current.onmessage = function(event) {
        const data = JSON.parse(event.data);

        if (data.chat_id) {
          setCopilotChatId(data.chat_id);
        }
        else if (data.text) {
          setCopilotChat((prevChat) => {
            const lastChatItem = prevChat[0];
            const updatedLastChatItem = lastChatItem ? lastChatItem + data.text : data.text;
            return [...prevChat.slice(0, -1), updatedLastChatItem];
          });
        }
        /*else {
          eventSource.close();
        }*/
      };

      copilotEventSourceRef.current.onerror = (event) => {
        // console.error("EventSource failed:", event);
        copilotEventSourceRef.current?.close();
        setAnswerLoading([false]);
      };
    }
  };

  const generateAnswer = async () => {

    if (copilotEventSourceRef.current) {
      copilotEventSourceRef.current.close();
    }
    console.log('mark id: ', selectedMarketing?.id, 'copchatid: ', copilotChatId, 'inputtext: ', inputText, 'promptcontent: ', promptContent);
    const requestBody: any = {
      text: inputText,
      module: 'marketing',
      current_content: promptContent,
      chat_id: copilotChatId,
      module_ref_id: selectedMarketing?.id,
    };

    setAnswerLoading([...answerLoading, true]);

    // var isCampText = false;
    // isCampText = false;

    const getAnswerId = await ax.post('/copilot/request_params', requestBody);

    copilotEventSourceRef.current = new EventSource(
      `${api_base_url}/copilot/get_answer?query_id=${getAnswerId.data}`, 
      {withCredentials: true}
    );

    setInputText('');
    setContent('');

    copilotEventSourceRef.current.onmessage = function(event) {
      const data = JSON.parse(event.data);


      if (data.copilot_text) {
        setCopilotChat((prevChat) => {
          const lastChatItem = prevChat[prevChat.length - 1];
          const updatedLastChatItem = lastChatItem ? lastChatItem + data.copilot_text : data.copilot_text;
          return [...prevChat.slice(0, -1), updatedLastChatItem];
        });
      }
      else if (data.content_text) {
        //isCampText || setContent('');
        setContent((prevContent) => {
          const updatedContent = prevContent + data.content_text;
          //const updatedContent = isCampText ? prevContent + data.content_text : data.content_text;
          //isCampText = true;
          return updatedContent;
        })
      }

    };

    copilotEventSourceRef.current.onerror = (event) => {
      // console.error("EventSource failed:", event);
      copilotEventSourceRef.current?.close();
      setAnswerLoading((prevAnswerLoading) => {
        //Create a new array with all elements except the last one
        const newArray = prevAnswerLoading.slice(0, -1);
        //Add false as the new last element
        newArray.push(false);
        return newArray;
      });
      if (editorRef.current) {
        handleChange(editorRef.current.getHTML(), editorRef.current.getText());
      }
    };
  };
  
  // Debounced function that shows the notification
  const handleChange = useCallback(debounce((html: string, text: string) => {
      
    if (text && text !== selectedMarketing?.text) {

      
      toastId.current = toast.info('Saving draft...');

      if (selectedMarketing) {
        const requestBody: any = {
          text: text,
          body: html,
          id: selectedMarketing.id,
        }

        ax.patch(`/marketing`, requestBody)
          .then((response) => {
            toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
            //setSelectedMarketing(response.data)
          });
      }

      else {
        const requestBody1: any = {
          text: text,
          max_nbr_words: 3,
        }

        ax.post('/generate/title', requestBody1)
          .then((response) => {

          const requestBody: any = {
            title: response.data,
            text: text,
            body: html,
          }

          ax.patch(`/marketing`, requestBody)
            .then(async (response) => {
              toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
              await ax.get('marketing/all').then((response2) => setMarketings(response2.data));
              setSelectedMarketing(response.data)
            });
          });
        }
        
    }

  }, 5000), [selectedMarketing]); // 5 seconds

  const onContentChange = (updatedContentBody: string, updatedContentText: string) => {
    setPromptContent(updatedContentText.replace(/<br\s*\/?>/g, '\n'));
    setBody(updatedContentBody);
  };

  const saveMarketing = async () => {
    if (!promptContent || promptContent === selectedMarketing?.text) {
      return;
    }

    toastId.current = toast.info('Saving draft...');

    if (selectedMarketing) {
      const requestBody: any = {
        text: promptContent,
        body: body,
        id: lastClickedId,
      }

      await ax.patch(`/marketing`, requestBody)
        .then((response) => {
          toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
        });
    }

    else {
      const requestBody1: any = {
        text: promptContent,
        max_nbr_words: 3,
      }

      const marketingTitle = await ax.post('/generate/title', requestBody1);

      const requestBody: any = {
        title: marketingTitle.data,
        text: promptContent,
        body: body,
      }

      await ax.patch(`/marketing`, requestBody)
        .then(async (response) => {
          toast.update(toastId?.current ?? '', {type: 'success', render: 'Draft saved', autoClose: 2000 });
          await ax.get('marketing/all').then((response2) => setMarketings(response2.data));
          setSelectedMarketing(response.data)
        });
    }

  };

  function formatDateTime(dateTimeString: string): string {
    const dateTime = new Date(dateTimeString);
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
    const oneYear = 365 * 24 * 60 * 60 * 1000; // milliseconds in one year
    const difference = now.getTime() - dateTime.getTime();

    // Format options for displaying the date and time
    const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    const dateOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    const yearOptions: Intl.DateTimeFormatOptions = { year: 'numeric' };

    if (difference < oneDay) {
        // Less than a day ago, show time
        return dateTime.toLocaleTimeString('sv-SE', timeOptions);
    } else if (difference < oneYear) {
        // More than a day but less than a year ago, show date
        return dateTime.toLocaleDateString('sv-SE', dateOptions);
    } else {
        // More than a year ago, show year
        return dateTime.toLocaleDateString('sv-SE', yearOptions);
    }
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <IconNavigation />
          <ContentContainer>
            <CampaignList
              marketings={marketings}
              setSelectedMarketing={setSelectedMarketing}
              lastClickedId={lastClickedId}
              handleNewMarketing={handleNewMarketing}
              formatDateTime={formatDateTime}
            />
            <TextEditor
              content={content}
              onContentChange={onContentChange}
              saveMarketing={saveMarketing}
              handleChange={handleChange}
              selectedMarketing={selectedMarketing}
              selectedMarketingRef={selectedMarketingRef}
              cancelHandleChange={handleChange.cancel}
              editorRef={editorRef}
            />
            <MarketingCopilot
              currentUser={props.currentUser}
              inputText={inputText}
              handleTextareaChange={handleTextareaChange}
              handleSend={handleSend}
              handleEnterSend={handleEnterSend}
              conversationRef={conversationRef}
              copilotChat={copilotChat}
              answerLoading={answerLoading}
            />
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};
export default MarketingPage;

/*{props.showCreateErrand && (
          <CreateErrandPopUp
            toggleCreateErrand={toggleCreateErrand}
            createErrandTitle={createErrandTitle}
            setCreateErrandTitle={setCreateErrandTitle}
            createErrandMessage={createErrandMessage}
            setCreateErrandMessage={setCreateErrandMessage}
            handleSaveNewErrand={handleSaveNewErrand}
            handleTitleKeyPress={handleTitleKeyPress}
            messageInputRef={messageInputRef}
          />
          )} */