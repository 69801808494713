
import { TWebsiteSchema, websiteSchema } from "../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AuthorizationError, AuthorizationForm, AuthorizationInput, AuthorizationLabel, AuthorizationText, AuthorizationTitle, LinkSpan } from "../../AuthorizationPages/Authorization-styling";
import { ax } from "../../Utils";
import { AdminButton, AdminSettingContainer, AdminSettingForm, AdminSettingInput, AdminSettingText, AdminSettingTextarea, SmallTextSpan } from "../AdminPage-styling";


export default function AddWebsite() {

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    clearErrors,
  } = useForm<TWebsiteSchema>({
    resolver: zodResolver(websiteSchema),
  });

  const onSubmit = async (data: TWebsiteSchema) => {
    console.log("submitting form", data);
    const requestBody = {
      website: data.website,
      text: data.description,
    };

    try {
      await ax.post('/web', requestBody);
    }
    catch (error) {
      alert("Förfrågan misslyckades. Error: " + error);
      return;
    }

    toast.success("Webbplatsen har lagts till!");
    reset();

    // reset();
  };


  return (

    <AdminSettingForm onSubmit={handleSubmit(onSubmit)}>
      <AdminSettingContainer>
        <AdminSettingText>Lägg till en webbplats för ragge att läsa <SmallTextSpan>(URL)</SmallTextSpan></AdminSettingText>
        <AdminSettingInput
          {...register("website")}
          type="website"
          placeholder="https://www.raggeexempel.se"
          id="website"
        />
        {errors.website && (
          <AuthorizationError>{`${errors.website.message}`}</AuthorizationError>
        )}
      </AdminSettingContainer>

      <AdminSettingContainer>
        <AdminSettingText>Skriv en kort beskrivning av webbplatsen</AdminSettingText>
        <AdminSettingTextarea
          {...register("description")}
          placeholder="Skriv här..."
          id="description"
          rows={3}
        />
        {errors.description && (
          <AuthorizationError>{`${errors.description.message}`}</AuthorizationError>
        )}
      </AdminSettingContainer>

      <AdminButton
        disabled={isSubmitting}
        type="submit"
      >
        Lägg till
      </AdminButton>
    </AdminSettingForm>
  );
}