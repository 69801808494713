import React, { useState } from "react";
import styled from "styled-components";
import { Errand } from "../Types";
import { formatDate } from "../../Utils";

// Container for the entire form
const LeftPanelContainer = styled.div`
  display: flex;
  width: 316px;
  flex-direction: column;
  align-items: center;
  border-right: solid 1px #000000;
`;
const TitleContainer = styled.div`
  font-size: 23px;
  padding-bottom: 16px;
  padding-top: 12px;
  font-family: Arial, sans-serif;
`;
const ErrandCardStyling = styled.div`
  cursor: pointer;
  width: 300px;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid;
  border-width: 1px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 8px;
  margin-right: 8px;
  &:hover {
    background-color: #d3d3f7;
  }
`;

const ErrandTitleStyling = styled.div`
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 2px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 18px;
`;

const ErrandCreatedAtStyling = styled.div`
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 4px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 12px;
`;

type ErrandCardProps = {
  errand: Errand;
  callback: (x: Errand) => void;
};
const ErrandCard = (errandProps: ErrandCardProps) => {
  return (
    <ErrandCardStyling onClick={() => errandProps.callback(errandProps.errand)}>
      <ErrandTitleStyling>Title: {errandProps.errand.title}</ErrandTitleStyling>
      <ErrandCreatedAtStyling>
        Created at: {formatDate(errandProps.errand.created_at)}
      </ErrandCreatedAtStyling>
    </ErrandCardStyling>
  );
};

type Props = {
  errands: Errand[];
  callback: (x: Errand) => void;
};

export const AllErrandsSection = (props: Props) => {
  return (
    <LeftPanelContainer>
      <TitleContainer>Errands</TitleContainer>
      {props.errands.map((errand, index) => (
        <ErrandCard
          errand={errand}
          callback={props.callback}
          key={"all_errands_left_panel" + index.toString()}
        ></ErrandCard>
      ))}
    </LeftPanelContainer>
  );
};
