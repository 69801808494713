import { useState, useEffect } from "react";
import { AllErrandsSection } from "./AllErrandsSection/AllErrandsSection";
import {
  Errand,
  Message,
  Template,
  HistResponse,
  Prompt,
  Response,
} from "./Types";
import { ax } from "../Utils";
import styled from "styled-components";
import { MessagesSection } from "./MessagesSection/MessagesSection";

const Container = styled.div`
  display: flex;
  height: 100vh; /* Adjust the height as needed */
`;

export const OpenErrandsPage = () => {
  const [errands, setErrands] = useState<Errand[]>([]);
  const [selectedErrand, setSelectedErrand] = useState<Errand | null>(null);
  const [proposedResponse, setProposedResponse] = useState<string | undefined>(
    undefined
  );
  const [message, setMessage] = useState<Message | null>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [histResponses, setHistResponses] = useState<HistResponse[]>([]);
  const [prompt, setPrompt] = useState<Prompt | null>(null);
  const [response, setResponse] = useState<Response | null>(null);
  const [newResponseText, setNewResponseText] = useState("");

  useEffect(() => {
    ax.get('/errands')
    .then((response) => setErrands(response.data));
  }, []);

  useEffect(() => {
    if (selectedErrand) {
      // get message for errand
      ax.get(`/messages?errand_id=${selectedErrand.id}`)
        .then((response) => setMessage(response.data));

      // get response for errand
      ax.get(`/responses?errand_id=${selectedErrand.id}`)
        .then((response) => setResponse(response.data));
    }
  }, [selectedErrand]);

  useEffect(() => {
    if (message) {
      // get similar hist responses for errand
      ax.get(`/responses/relevant/?message_id=${message.id}`)
        .then((response) => setHistResponses(response.data));

      // get similar templates for errand
      ax.get(`/templates/relevant/?message_id=${message.id}`)
        .then((response) => setTemplates(response.data));
    }
  }, [message]);

  const handleTemplateCheck = (index: number, checked: boolean) => {
    const newTemplates = [...templates];
    newTemplates[index].checked = checked;
    setTemplates(newTemplates);
  };

  const handleHistResponsesCheck = (index: number, checked: boolean) => {
    const newHistResponses = [...histResponses];
    newHistResponses[index].checked = checked;
    setHistResponses(newHistResponses);
  };

  const generateResponse = () => {
    if (!message) {
      // console.log('No message selected. Aborting request...');
      return;
    }

    const requestBody: any = {
      template_ids: templates.filter((t) => t.checked).map((t) => t.id),
      rel_response_ids: histResponses.filter((t) => t.checked).map((t) => t.id),
      message_id: message.id,
    };

    ax.post('/proposed_responses/generate', requestBody)
      .then(
        (response) => (
          setNewResponseText(response.data.proposed_response_text), 
          setPrompt(response.data.prompt)
        )
      );
  };

  return (
    <Container>
      <AllErrandsSection
        errands={errands}
        callback={setSelectedErrand}
      ></AllErrandsSection>
      <MessagesSection
        message={message}
        response={response}
        proposedResponse={proposedResponse}
        setProposedResponse={setProposedResponse}
      ></MessagesSection>

      <div style={{ width: "60%", overflow: "auto" }}>
        <h3>Relevant templates</h3>
        {templates.map((template, index) => (
          <div key={index}>
            <input
              type="checkbox"
              checked={template.checked}
              onChange={(e) => handleTemplateCheck(index, e.target.checked)}
            />
            <b>Template {template.id}:</b>
            <br />
            <span>{template.text_1}</span>
            <br />
            <span>{template.text_2}</span>
            <br />
            <span>{template.text_3}</span>
            <hr />
          </div>
        ))}
        <h3>Similar responses</h3>
        {histResponses.map((HistResponse, index) => (
          <div key={index}>
            <input
              type="checkbox"
              checked={HistResponse.checked}
              onChange={(e) =>
                handleHistResponsesCheck(index, e.target.checked)
              }
            />
            <b>Response {HistResponse.id}:</b>
            <br />
            <span>{HistResponse.text}</span>
            <hr />
          </div>
        ))}
        <button onClick={generateResponse}>Generate!</button>
        <h3>New proposed response:</h3>
        <textarea
          value={newResponseText}
          onChange={(e) => setNewResponseText(e.target.value)}
        />
        <h3>Prompt:</h3>
        {prompt && (
          <div>
            <h5>Prompt: {prompt.id}</h5>
            <b>System prompt</b>
            <br />
            <span>{prompt.system_prompt}</span> 
            <br />
            <b>User prompt</b>
            <br />
            <span>{prompt.prompt}</span>
          </div>
        )}
      </div>
    </Container>
  );
};
