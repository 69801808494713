import React from 'react';
import{
  PopupOverlay,
  CloseErrandWindow,
  CloseButton,
  PopUpTitleContainer,
  CloseErrandInfoSection,
  CloseErrandInfo,
  CloseErrandButtonSection,
  DeleteErrandButton,
  CancelButton,
} from "./ErrandsPage-styling"
import { Errand } from './Types';


type Props = {
  toggleCloseErrand: () => void;
  handleCloseErrand: () => any;
  selectedErrand: Errand;
}

const CreateErrandPopUp = (props: Props) => {
  return (
    <PopupOverlay>
      <CloseErrandWindow>
        <PopUpTitleContainer>Avsluta ärende: {props.selectedErrand.title}</PopUpTitleContainer>
        <CloseButton
          onClick={props.toggleCloseErrand}
          style={{'--after-color': '#a0a0a0',} as React.CSSProperties}
        />
        <CloseErrandInfoSection>
          <CloseErrandInfo>
            Avsluta bara recensioner som du har svarat klart på. Genom att avsluta
            ärendet arkiverar du det i katalogen och tar bort det från dina aktiva
            recensioner.<br />
            <br />
            Du kan fortfarande se avslutade recensioner i kunskapsbanken.
            
          </CloseErrandInfo>
        </CloseErrandInfoSection>
        <CloseErrandButtonSection>
          <DeleteErrandButton onClick={props.handleCloseErrand}>
            Avsluta
          </DeleteErrandButton>
          <CancelButton onClick={props.toggleCloseErrand}>
            Avbryt
          </CancelButton>
        </CloseErrandButtonSection>
      </CloseErrandWindow>
    </PopupOverlay>
  );
};

export default CreateErrandPopUp;
