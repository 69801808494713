
import { Industry, Language, PricingPlan, TSignUpSchema, signUpSchema } from "../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MuiPhoneNumber from 'mui-phone-number';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { ax } from "../Utils";
import { toast } from "react-toastify";
import '../CSS/pricing.css';
import { motion } from 'framer-motion';
import { AuthorizationError, AuthorizationForm, AuthorizationInput, AuthorizationLabel, FormButtonContainer, FormObligatory, FormPricingCard, FormPricingCardPrice, FormPricingCardText, FormPricingCardTitle, FormProgressList, FormProgressListItem, FormProgressNav, FormProgressStep, FormProgressStepName, FormProgressStepText, FormSelectBox, FormStepTitle, FormSubmitButton, NavigationButton, PricingCardContainer, SignUpContainer } from "../AuthorizationPages/Authorization-styling";
import { Container, ContentContainer, Logo, LogoImg, Nav, NavBar, NavItem, PageBackground, ScreenProtector } from "../Styling/NavBar-styling";
import { MainContent } from "../LoginPage/LoginPage-styling";
import { useNavigate } from "react-router-dom";
import countryList from 'react-select-country-list'


const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";

const steps = [
  { 
    id: 'Steg 1', 
    name: 'Prisalternativ',
    fields: ['pricingPlanId']
  },
  {
    id: 'Steg 2',
    name: 'Företagsuppgifter',
    fields: ['companyName', 'industryId', 'country', 'defaultCopilotLanguageId', 'defaultInterfaceLanguageId']
  },
  {
    id: 'Steg 3',
    name: 'Användaruppgifter',
    fields: ['firstName', 'lastName', 'email', 'phone', 'password', 'confirmPassword']
  },
  { id: 'Steg 4', name: 'Bekräfta uppgifter', fields: [] }
]

export default function SignUpForm() {
  const [previousStep, setPreviousStep] = useState(0)
  const [currentStep, setCurrentStep] = useState(0)
  const delta = currentStep - previousStep
  const options = useMemo(() => countryList().getData(), [])

  const [pricingPlans, setPricingPlans] = useState<PricingPlan[]>([]);
  const isFetchingPricingPlans = useRef(false);
  const [copilotLanguages, setCopilotLanguages] = useState<Language[]>([]);
  const isFetchingCopilotLanguages = useRef(false);
  const [interfaceLanguages, setInterfaceLanguages] = useState<Language[]>([]);
  const isFetchingInterfaceLanguages = useRef(false);
  const [industries, setIndustries] = useState<Industry[]>([]);
  const isFetchingIndustries = useRef(false);

  useEffect(() => {
    if (pricingPlans.length === 0 && !isFetchingPricingPlans.current) {
      isFetchingPricingPlans.current = true;
      ax.get('/pricing_plan/all')
        .then(response => {
          setPricingPlans(response.data);
        })
        .catch(error => {
          console.error('Failed to fetch pricing plans:', error);
        });
    }
    if (copilotLanguages.length === 0 && !isFetchingCopilotLanguages.current) {
      isFetchingCopilotLanguages.current = true;
      ax.get('/language/copilot/all')
        .then(response => {
          setCopilotLanguages(response.data);
        })
        .catch(error => {
          console.error('Failed to fetch copilot languages:', error);
        });
    }
    if (interfaceLanguages.length === 0 && !isFetchingInterfaceLanguages.current) {
      isFetchingInterfaceLanguages.current = true;
      ax.get('/language/interface/all')
        .then(response => {
          setInterfaceLanguages(response.data);
        })
        .catch(error => {
          console.error('Failed to fetch interface languages:', error);
        });
    }
    if (industries.length === 0 && !isFetchingIndustries.current) {
      isFetchingIndustries.current = true;
      ax.get('/industries/all')
        .then(response => {
          setIndustries(response.data);
        })
        .catch(error => {
          console.error('Failed to fetch industries:', error);
        });
    }
  }, []);

  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    clearErrors,
    trigger,
    getValues,
  } = useForm<TSignUpSchema>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      defaultCopilotLanguageId: 1,
      defaultInterfaceLanguageId: 1,
    },
    mode: 'onBlur',
  });

  const onSubmit = async (data: TSignUpSchema) => {
    let currentSubmissionStep = '';

    try {
      currentSubmissionStep = 'company';

      const requestBody = {
        name: data.companyName,
        industry_id: data.industryId,
        pricing_plan_id: data.pricingPlanId,
        default_copilot_language_id: data.defaultCopilotLanguageId,
        default_interface_language_id: data.defaultInterfaceLanguageId,
        country_name: data.country,
      };

      const companyResponse = await ax.post('/companies', requestBody);

      currentSubmissionStep = 'user';

      const requestBody2 = {
        company_id: companyResponse.data.id,
        email: data.email,
        password: data.password,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone.replace(/\s/g, ''),
        is_superuser: true,
      };

      await ax.post('/auth/register', requestBody2);

      currentSubmissionStep = 'verification';

      const requestBody3 = {
        email: data.email,
      };

      await ax.post('/auth/request-verify-token', requestBody3);
    }
    catch (error) {
      switch (currentSubmissionStep) {
        case 'company':
          alert("Registrering av företaget misslyckades! Error: " + error);
          break;
        case 'user':
          alert("Registrering av användaren misslyckades! Error: " + error);
          break;
        case 'verification':
          alert("Verifikationsförfrågan misslyckades. Error: " + error);
          break;
        default:
          alert("Ett problem uppstod! Error: " + error);
          break;
      }
    }

    toast.success("Registreringen lyckades! Ett verifieringsmail har skickats till din e-postadress.");
    reset();
  };

  type FieldName = keyof TSignUpSchema;

  const next = async () => {
    console.log(currentStep);
    const fields = steps[currentStep].fields;
    console.log(fields);
    console.log("before trigger", errors);

    if (fields) {
      // Only trigger validation for fields defined in the current step
      console.log('Current errors', errors);
      const result = await trigger(fields as Array<keyof TSignUpSchema>, { shouldFocus: true });
      console.log('Validation result', result);
      if (result) {
        // If all fields in the current step are valid, proceed to the next step
        setPreviousStep(currentStep);
        setCurrentStep(currentStep + 1);
      }
    } else {
      // If no fields are defined for validation in this step (e.g., a confirmation step), just move forward
      setPreviousStep(currentStep);
      setCurrentStep(currentStep + 1);
    }
    console.log("after trigger", errors);
  };


  const prev = () => {
    if (currentStep > 0) {
      setPreviousStep(currentStep)
      setCurrentStep(step => step - 1)
    }
  };

  const handleHome = async () => {
    navigate('/');
  };
  const handleBack = async () => {
    navigate('/login');
  };

  const renderReview = () => {
    const allValues = getValues(); // Retrieve all form values
    return (
      <div style={{alignSelf: 'center'}}>
        <h2>Ifyllda uppgifter</h2>
        <div>
          <strong>Prisplan:</strong> {pricingPlans.find(plan => plan.id === allValues.pricingPlanId)?.name}
        </div>
        <div>
          <strong>Företasnamn:</strong> {allValues.companyName}
        </div>
        <div>
          <strong>Industri:</strong> {industries.find(industry => industry.id === allValues.industryId)?.name}
        </div>
        <div>
          <strong>Land:</strong> {allValues.country}
        </div>
        <div>
          <strong>Språk (AI-assistenten):</strong> {copilotLanguages.find(language => language.id === allValues.defaultCopilotLanguageId)?.name}
        </div>
        <div>
          <strong>Språk (användargränssnitt):</strong> {interfaceLanguages.find(language => language.id === allValues.defaultInterfaceLanguageId)?.name}
        </div>
        <div>
          <strong>Förnamn:</strong> {allValues.firstName}
        </div>
        <div>
          <strong>Efternamn:</strong> {allValues.lastName}
        </div>
        <div>
          <strong>E-postadress:</strong> {allValues.email}
        </div>
        <div>
          <strong>Telefon:</strong> {allValues.phone}
        </div>
      </div>
    );
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>
            <Nav>
              <NavItem onClick={handleBack}>Tillbaka</NavItem>
            </Nav>
          </NavBar>
          <ContentContainer>
            <MainContent>
              <SignUpContainer>
                <FormProgressNav>
                  <FormProgressList role="list">
                    {steps.map((step, index) => (
                      <FormProgressListItem key={step.name}>
                        <FormProgressStep 
                          $isactive={currentStep === index}
                          $iscompleted={currentStep > index}
                          aria-current={currentStep === index ? 'step' : undefined}
                        >
                          <FormProgressStepText
                            $isactive={currentStep === index || currentStep > index} 
                            $iscompleted={currentStep > index}
                          >
                            {step.id}
                          </FormProgressStepText>
                          <FormProgressStepName>
                            {step.name}
                          </FormProgressStepName>
                        </FormProgressStep>

                      </FormProgressListItem>
                    ))}
                  </FormProgressList>
                </FormProgressNav>
                <AuthorizationForm onSubmit={handleSubmit(onSubmit)}>
                  {currentStep === 0 && (
                    <motion.div
                      initial={{ x: delta >= 0 ? '2rem' : '-2rem', opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                      <FormStepTitle>Prisalternativ</FormStepTitle>
                      <PricingCardContainer>
                        <Controller
                          control={control}
                          name="pricingPlanId"
                          render={({ field }) => (
                            <>
                            {pricingPlans.map((plan) => (
                              <PricingCard
                                key={plan.id}
                                id={plan.id}
                                name={plan.name}
                                price={plan.fixed_price_per_month}
                                maxUsers={plan.nbr_max_users}
                                copilotDrafts={plan.nbr_copilot_drafts}
                                selected={field.value === plan.id}
                                onClick={() => field.onChange(plan.id)}
                              ></PricingCard>
                            ))}
                            </>
                          )} 
                        />
                      </PricingCardContainer>
                      

                      {errors.pricingPlanId && (
                        <AuthorizationError>{`${errors.pricingPlanId.message}`}</AuthorizationError>
                      )}
                    </motion.div>
                  )}

                  {currentStep === 1 && (
                    <motion.div
                      style={{ display: 'flex', flexDirection: 'column' }}
                      initial={{ x: delta >= 0 ? '2rem' : '-2rem', opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                      <FormStepTitle>Företagsuppgifter</FormStepTitle>
                      <AuthorizationLabel>Företagsnamn<FormObligatory>*</FormObligatory></AuthorizationLabel>
                      <AuthorizationInput
                        {...register("companyName")}
                        type="text"
                        placeholder="ex. Företag AB"
                      />
                      {errors.companyName && (
                        <AuthorizationError>{`${errors.companyName.message}`}</AuthorizationError>
                      )}

                      <AuthorizationLabel>Industri<FormObligatory>*</FormObligatory></AuthorizationLabel>
                      <Controller
                        name="industryId"
                        control={control}
                        rules={{
                          required: 'Industri krävs',
                        }}
                        render={({ field }) => (
                          <FormSelectBox {...field} defaultValue={0} onChange={(e: { target: { value: string; }; }) => field.onChange(parseInt(e.target.value, 10))}>
                            <option value={0} disabled>Välj industri</option>
                            {industries.map((industry) => (
                              <option key={industry.id} value={industry.id}>{industry.name}</option>
                            ))}
                          </FormSelectBox>
                        )} />
                      {errors.industryId && (
                        <AuthorizationError>{`${errors.industryId.message}`}</AuthorizationError>
                      )}

                      <AuthorizationLabel htmlFor="copilotLanguageSelect">Välj språk för AI-assistenten<FormObligatory>*</FormObligatory></AuthorizationLabel>
                      <Controller
                        name="defaultCopilotLanguageId"
                        control={control}
                        rules={{
                          required: 'Språk för AI-assistenten krävs',
                        }}
                        render={({ field }) => (
                          <FormSelectBox {...field} id="copilotLanguageSelect" onChange={(e: { target: { value: string; }; }) => field.onChange(parseInt(e.target.value, 10))}>
                            {copilotLanguages.map((language) => (
                              <option key={language.id} value={language.id}>{language.name}</option>
                            ))}
                          </FormSelectBox>
                        )} />
                      {errors.defaultCopilotLanguageId && (
                        <AuthorizationError>{`${errors.defaultCopilotLanguageId.message}`}</AuthorizationError>
                      )}

                      <AuthorizationLabel htmlFor="interfaceLanguageSelect">Välj språk för hemsidan<FormObligatory>*</FormObligatory></AuthorizationLabel>
                      <Controller
                        name="defaultInterfaceLanguageId"
                        control={control}
                        rules={{
                          required: 'Språk för hemsidan krävs',
                        }}
                        render={({ field }) => (
                          <FormSelectBox {...field} id="interfaceLanguageSelect" onChange={(e: { target: { value: string; }; }) => field.onChange(parseInt(e.target.value, 10))}>
                            {interfaceLanguages.map((language) => (
                              <option key={language.id} value={language.id}>{language.name}</option>
                            ))}
                          </FormSelectBox>
                        )} />
                      {errors.defaultInterfaceLanguageId && (
                        <AuthorizationError>{`${errors.defaultInterfaceLanguageId.message}`}</AuthorizationError>
                      )}

                      <AuthorizationLabel>Land<FormObligatory>*</FormObligatory></AuthorizationLabel>
                      <Controller
                        name="country"
                        control={control}
                        rules={{
                          required: 'Land krävs',
                        }}
                        render={({ field }) => (
                          <FormSelectBox {...field} defaultValue="" onChange={(e: { target: { value: string; }; }) => field.onChange(e.target.value)}>
                            <option value="" disabled>Välj land</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.label}>{option.label}</option>
                            ))}
                          </FormSelectBox>
                        )} />

                      
                      {errors.country && (
                        <AuthorizationError>{`${errors.country.message}`}</AuthorizationError>
                      )}
                    </motion.div>
                  )}

                  {currentStep === 2 && (
                    <motion.div
                      style={{ display: 'flex', flexDirection: 'column' }}
                      initial={{ x: delta >= 0 ? '2rem' : '-2rem', opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                      <FormStepTitle>Användaruppgifter</FormStepTitle>
                      <AuthorizationLabel>Förnamn<FormObligatory>*</FormObligatory></AuthorizationLabel>
                      <AuthorizationInput
                        {...register("firstName")}
                        type="text"
                        placeholder="Förnamn"
                      />
                      {errors.firstName && (
                        <AuthorizationError>{`${errors.firstName.message}`}</AuthorizationError>
                      )}

                      <AuthorizationLabel>Efternamn<FormObligatory>*</FormObligatory></AuthorizationLabel>
                      <AuthorizationInput
                        {...register("lastName")}
                        type="text"
                        placeholder="Efternamn"
                      />
                      {errors.lastName && (
                        <AuthorizationError>{`${errors.lastName.message}`}</AuthorizationError>
                      )}

                      <AuthorizationLabel>E-postadress<FormObligatory>*</FormObligatory></AuthorizationLabel>
                      <AuthorizationInput
                        {...register("email")}
                        type="email"
                        placeholder="E-postadress"
                      />
                      {errors.email && (
                        <AuthorizationError>{`${errors.email.message}`}</AuthorizationError>
                      )}

                      <AuthorizationLabel>Telefonnummer<FormObligatory>*</FormObligatory></AuthorizationLabel>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <MuiPhoneNumber
                            style={{ border: error ? '1px solid red' : '1px solid #333', borderRadius: '5px', padding: '0.5rem 0.7rem 0 0.7rem', fontSize: '1.1rem'}}
                            defaultCountry={'se'}
                            value={value}
                            onChange={(newValue) => {
                              if (typeof newValue === 'string') {
                                onChange(newValue); // Pass the new value to Controller's onChange
                              }
                            } }
                            className={`px-4 py-2 rounded ${error ? 'border-red-500' : ''}`} />
                        )} />
                      {errors.phone && <AuthorizationError>{errors.phone.message}</AuthorizationError>}

                      <AuthorizationLabel htmlFor="password">Nytt lösenord</AuthorizationLabel>
                      <AuthorizationInput
                        {...register("password")}
                        type="password"
                        placeholder="Lösenord"
                      />
                      {errors.password && (
                        <AuthorizationError>{`${errors.password.message}`}</AuthorizationError>
                      )}

                      <AuthorizationLabel htmlFor="confirmPassword">Upprepa lösenord</AuthorizationLabel>
                      <AuthorizationInput
                        {...register("confirmPassword")}
                        type="password"
                        placeholder="Lösenord (igen)"
                      />
                      {errors.confirmPassword && (
                        <AuthorizationError>{`${errors.confirmPassword.message}`}</AuthorizationError>
                      )}
                    </motion.div>
                  )}
                  {currentStep === 3 && (
                   <motion.div
                     style={{ display: 'flex', flexDirection: 'column' }}
                     initial={{ x: delta >= 0 ? '2rem' : '-2rem', opacity: 0 }}
                     animate={{ x: 0, opacity: 1 }}
                     transition={{ duration: 0.3, ease: 'easeInOut' }}
                   >
                     <FormStepTitle>Bekräfta uppgifter</FormStepTitle>
                     {renderReview()}
                    
                   </motion.div>
                 )}
                  <FormButtonContainer>
                    <NavigationButton
                      type="button"
                      onClick={prev}
                      disabled={currentStep === 0}
                    >
                      Föregående
                    </NavigationButton>
                    {currentStep !== 3 && (
                      <NavigationButton
                        type="button"
                        onClick={next}
                        disabled={currentStep === steps.length - 1}
                      >
                        Nästa steg
                      </NavigationButton>
                    )}
                    {currentStep === 3 && (
                      <FormSubmitButton
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Slutför köp
                      </FormSubmitButton>
                    )}
                  </FormButtonContainer>
                </AuthorizationForm>

              </SignUpContainer>
            </MainContent>
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
    
  );
}

type PricingCardProps = {
  id: number;
  name: string;
  price: string;
  maxUsers: string;
  copilotDrafts: string;
  selected: boolean;
  onClick: () => void;
};

const PricingCard = (props: PricingCardProps) => {
  return (
    <FormPricingCard onClick={props.onClick} $isSelected={props.selected}>
      {props.id === 1 && (
        <>
          <FormPricingCardTitle>Gratis testperiod</FormPricingCardTitle>
          <FormPricingCardText>Testa på i 2 veckor helt gratis! Ingen betalningsmetod krävs.</FormPricingCardText>
          <FormPricingCardPrice>Gratis!</FormPricingCardPrice>
          <FormPricingCardText>Upp till {props.maxUsers} användare</FormPricingCardText>
          <FormPricingCardText>{props.copilotDrafts} copilotskrivna utkast /vecka</FormPricingCardText>
        </>
      )}
      {props.id === 2 && (
        <>
          <FormPricingCardTitle>{props.name}</FormPricingCardTitle>
          <FormPricingCardText>Levla upp dina kundrelationer med Premium!</FormPricingCardText>
          <FormPricingCardPrice>{props.price} kr/månad</FormPricingCardPrice>
          <FormPricingCardText>Upp till {props.maxUsers} användare</FormPricingCardText>
          <FormPricingCardText>Obegränsade copilotmeddelanden 0,4 kr/1000 token</FormPricingCardText>
        </>
      )}
      {props.id === 3 && (
        <>
          <FormPricingCardTitle>{props.name}</FormPricingCardTitle>
          <FormPricingCardText>Testa på i 2 veckor helt gratis! Ingen betalningsmetod krävs.</FormPricingCardText>
          <FormPricingCardPrice>{props.price} kr/månad</FormPricingCardPrice>
          <FormPricingCardText>Max antal användare: {props.maxUsers}</FormPricingCardText>
          <FormPricingCardText>Max antal Copilot-utkast: {props.copilotDrafts}</FormPricingCardText>
        </>
      )}
      {props.id === 4 && (
        <>
          <FormPricingCardTitle>{props.name}</FormPricingCardTitle>
          <FormPricingCardText>Testa på i 2 veckor helt gratis! Ingen betalningsmetod krävs.</FormPricingCardText>
          <FormPricingCardPrice>Kontakta oss!</FormPricingCardPrice>
          <FormPricingCardText>Max antal användare: {props.maxUsers}</FormPricingCardText>
          <FormPricingCardText>Max antal Copilot-utkast: {props.copilotDrafts}</FormPricingCardText>
        </>
      )}
    </FormPricingCard>

  );
}


/**
 * <Controller
                        control={control}
                        name="pricingPlanId"
                        rules={{ required: "A pricing plan must be selected." }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <label htmlFor="free">
                              Gratis provperiod
                            </label>
                            <input
                              type="radio"
                              value={1}
                              id="free"
                              name="pricingPlan"
                              checked={value === 1}
                              onChange={(e) => onChange(parseInt(e.target.value, 10))} />

                            <label htmlFor="pro">
                              Pro
                            </label>
                            <input
                              type="radio"
                              value={2}
                              id="pro"
                              name="pricingPlan"
                              checked={value === 2}
                              onChange={(e) => onChange(parseInt(e.target.value, 10))} />

                            <label htmlFor="premium">
                              Företag
                            </label>
                            <input
                              type="radio"
                              value={3}
                              id="premium"
                              name="pricingPlan"
                              checked={value === 3}
                              onChange={(e) => onChange(parseInt(e.target.value, 10))} />

                            <label htmlFor="enterprise">
                              Enterprise
                            </label>
                            <input
                              type="radio"
                              value={4}
                              id="enterprise"
                              name="pricingPlan"
                              checked={value === 4}
                              onChange={(e) => onChange(parseInt(e.target.value, 10))} />
                          </>
                        )} />
 */