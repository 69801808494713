// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


/* Plain card */
.card {
 width: 25%;
 min-height: 350px;
 background: white;
 border-radius: 10px;
 transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.shadow {
 box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
             0 0  0 2px rgb(190, 190, 190),
             0.3em 0.3em 1em rgba(0,0,0,0.3);
}`, "",{"version":3,"sources":["webpack://./src/CSS/pricing.css"],"names":[],"mappings":";;;AAGA,eAAe;AACf;CACC,UAAU;CACV,iBAAiB;CACjB,iBAAiB;CACjB,mBAAmB;CACnB,sEAAsE;AACvE;;AAEA;CACC;;4CAE2C;AAC5C","sourcesContent":["\n\n\n/* Plain card */\n.card {\n width: 25%;\n min-height: 350px;\n background: white;\n border-radius: 10px;\n transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n}\n\n.shadow {\n box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),\n             0 0  0 2px rgb(190, 190, 190),\n             0.3em 0.3em 1em rgba(0,0,0,0.3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
