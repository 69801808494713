
import { TAddInboxSchema, addInboxSchema } from "../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AuthorizationError } from "../../AuthorizationPages/Authorization-styling";
import { ax } from "../../Utils";
import { AdminButton, AdminSettingContainer, AdminSettingForm, AdminSettingInput, AdminSettingText } from "../AdminPage-styling";
import Swal from "sweetalert2";


export default function AddInbox() {

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TAddInboxSchema>({
    resolver: zodResolver(addInboxSchema),
  });

  const onSubmit = async (data: TAddInboxSchema) => {
    const requestBody = {
      email: data.email,
      inbox_name: data.inboxName,
    };

    try {
      await ax.post('/feedback/email/new_inbox/auth', requestBody);
    }
    catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Något gick fel!',
        text: 'Error: ' + error,
      });
    }

    Swal.fire({
      icon: 'success',
      title: 'Inkorgen har lagts till!',
      text: 'Ett bekräftelsemejl har skickats till inkorgen!',
    });
    reset();
  };


  return (

    <AdminSettingForm onSubmit={handleSubmit(onSubmit)}>
      <AdminSettingContainer>
        <AdminSettingText>E&#8209;postadress</AdminSettingText>
        <AdminSettingInput
          {...register("email")}
          type="email"
          placeholder="exempel@domän.se"
          id="email"
        />
        {errors.email && (
          <AuthorizationError>{`${errors.email.message}`}</AuthorizationError>
        )}
      </AdminSettingContainer>

      <AdminSettingContainer>
        <AdminSettingText>Namnge inkorg</AdminSettingText>
        <AdminSettingInput
          {...register("inboxName")}
          type="text"
          placeholder="Namn"
          id="inboxName"
        />
        {errors.inboxName && (
          <AuthorizationError>{`${errors.inboxName.message}`}</AuthorizationError>
        )}
      </AdminSettingContainer>

      <AdminButton
        disabled={isSubmitting}
        type="submit"
      >
        Lägg till
      </AdminButton>
    </AdminSettingForm>
  );
}