import React, { useState } from 'react';
import axios from 'axios';

function NewEmailInbox() {
    const [email, setEmail] = useState('');
    const [inboxName, setInboxName] = useState('');

    const authenticate = async () => {
        const response = await axios.post('/auth', { email, inboxName });
        window.location.href = response.data.url;
    };

    return (
        <div>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
            <input type="text" value={inboxName} onChange={(e) => setInboxName(e.target.value)} placeholder="Name your inbox" />
            <button onClick={authenticate}>Authenticate with Nylas</button>
        </div>
    );
}

export default NewEmailInbox;
