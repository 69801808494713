import React from 'react';
import{
  PopupOverlay,
  CloseErrandWindow,
  CloseButton,
  PopUpTitleContainer,
  CloseErrandInfoSection,
  CloseErrandInfo,
  CloseErrandButtonSection,
  DeleteErrandButton,
  CancelButton,
} from "../ErrandsPage/ErrandsPage-styling"


type Props = {
  toggleSaveTemplatePopUp: () => void;
  handleSaveTemplate: () => any;
}

const SaveTemplatePopUp = (props: Props) => {
  return (
    <PopupOverlay>
      <CloseErrandWindow>
        <PopUpTitleContainer>Spara ny mall.</PopUpTitleContainer>
        <CloseButton 
          onClick={props.toggleSaveTemplatePopUp}
          style={{'--after-color': '#a0a0a0',} as React.CSSProperties}
        />
        <CloseErrandInfoSection>
          <CloseErrandInfo>
            Spara mallen i kunskapsbanken för att förbättra ICA copilots kunskap!<br/><br/>
            Se till att mallen är korrekt och att den är utformad på ett sätt som du vill
            att framtida svar från ICA copilot ska vara.
          </CloseErrandInfo>
        </CloseErrandInfoSection>
        <CloseErrandButtonSection>
          <DeleteErrandButton
            style={{'backgroundColor': '#0f86a1',} as React.CSSProperties}
            onClick={props.handleSaveTemplate}>
            Spara
          </DeleteErrandButton>
          <CancelButton onClick={props.toggleSaveTemplatePopUp}>
            Avbryt
          </CancelButton>
        </CloseErrandButtonSection>
      </CloseErrandWindow>
    </PopupOverlay>
  );
};

export default SaveTemplatePopUp;
