import styled from 'styled-components';

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 1000px;
`;

export const LoginBox = styled.div`
  border: 2px solid #ccc;
  padding: 20px;
  border-radius: 6px;
  width: 80%;
  height: 400px;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 48px;
  margin: 15px;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 24px;
`;

export const Input = styled.input<{ $error: boolean }>`
  display: block;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border-width: 1px;
  border-radius: 8px;
  font-size: 20px;
  border-width: ${(props) => (props.$error ? '2px' : '1px')};
  border-style: solid;
  border-color: ${(props) => (props.$error ? 'red' : '#0f86a1')};
  border-radius: 8px;
  transition: border-color 0.3s;
  box-shadow: ${(props) => (props.$error ? '0 0 4px red' : '0 0 4px #0f86a1')};
  outline: none;
  box-sizing: border-box;

  &:hover {
    background-color: rgba(15, 134, 161, 0.3);
  }
  &:focus {
    box-shadow: 0 0 6px #0f86a1;
    outline: 1px solid #0f86a1;
  }
`;

export const Button = styled.button`
  background-color: #0f86a1;
  color: #fff;
  border: none;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  margin-top: 15px;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #0a6073;
    box-shadow: 0 0 4px #0f86a1;
  }
`;

export const ForgotPasswordWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ForgotPasswordText = styled.a`
  color: #0f86a1;
  cursor: pointer;
  font-size: 20px;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;