import { FaTrashCan, FaWrench } from "react-icons/fa6";
import { Inbox } from "../../Types";
import { AdminListContainer, AdminListItem, DeleteButton, ListConfigureButton } from "../AdminPage-styling";

type CompanyInboxListProps = {
  companyInboxes: Inbox[];
  handleDeleteInboxPopup: (inboxId: number, email: string, inboxName: string) => void;
  handleConfigureInbox: (inbox: Inbox) => void;
};

const CompanyInboxList = (props: CompanyInboxListProps) => {




  return (
    <AdminListContainer>
      {props.companyInboxes.length === 0 ? (
        <AdminListItem>Hittade inga inkorgar</AdminListItem>
      ) : (
        props.companyInboxes.map((inbox) => (
          <AdminListItem key={inbox.id}>
            <DeleteButton onClick={() => props.handleDeleteInboxPopup(inbox.id, inbox.email, inbox.inbox_name)}>
              <FaTrashCan />
            </DeleteButton>
            <ListConfigureButton onClick={() => props.handleConfigureInbox(inbox)}>
              <FaWrench />
            </ListConfigureButton>
            {inbox.email} {inbox.inbox_name}
          </AdminListItem>
      ))
    )}
    </AdminListContainer>
  );
};

export default CompanyInboxList;