import React from "react";
import { Link } from "react-router-dom";
import { FaBullhorn, FaRegComments, FaRobot, FaRegChartBar, FaBook, FaSearch, FaEnvelopeOpenText, FaUserTie } from "react-icons/fa";
import "./IconNavigation-styling.css";

const IconNavigation = () => {
  return (
    <div className="vertical-navbar">
      <Link to="/user" className="nav-item">
        <FaRobot className="nav-icon"/>
        <span className="nav-text">Chatbot</span>
      </Link>
      <Link to="/feedback" className="nav-item">
        <FaRegComments className="nav-icon"/>
        <span className="nav-text">Feedback</span>
      </Link>
      <Link to="/catalogue" className="nav-item">
        <FaSearch className="nav-icon"/>
        <span className="nav-text">Kunskapsbanken</span>
      </Link>
      <Link to="/marketing" className="nav-item">
        <FaBullhorn className="nav-icon"/>
        <span className="nav-text">Marknadsföring</span>
      </Link>
      <Link to="/stats" className="nav-item">
        <FaRegChartBar className="nav-icon"/>
        <span className="nav-text">Statistik</span>
      </Link>
      <Link to="/admin" className="nav-item">
        <FaUserTie className="nav-icon"/>
        <span className="nav-text">Admin</span>
      </Link>
    </div>
  );
}

export default IconNavigation;