import React, { useEffect, useRef } from 'react';
import { useState } from "react";
import {
  PageBackground,
  Container,
  ContentContainer,
  NavBar,
  LogoImg,
  Logo,
  Nav,
  NavItem,
  SignInButton,
  ScreenProtector,
} from "../Styling/NavBar-styling";
import { ax, formatDate } from "../Utils";
import { useNavigate } from 'react-router-dom';
import { Template, User, FAQ, Feedback, Message, Marketing } from '../Types';
import {
  DisplaySection,
  FilterSection,
  ResultsSection,
  SearchBar,
  SearchBarContainer,
  SearchIcon,
  Header,
  FilterContainer,
  FilterText,
  FilterBox,
  ResultsListContainer,
  ResultItemStyling,
  ResultItemText,
  ResultItemTitle,
  FavouriteStar,
  ResultTitleSection,
  FilterTime,
  DisplayChatText,
  ChatItemText,
} from './CataloguePage-styling';
import {
  AddMessageButton,
  AddMessageContainer,
  Checkbox,
  ErrandCardDate,
  ErrandCardTitle,
  MessageBubble,
  MessageContainer,
  MessageTitle,
  MessageTitleContainer,
  MessagesArea,
} from '../ErrandsPage/ErrandsPage-styling';
import IconNavigation from '../Components/IconNavigation';
import HamburgerMenu from '../Components/HamburgerMenu';



const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";
const searchGlassLink = "https://i.postimg.cc/q78Sb9zk/wepik-export-20230921105747-Oz8-E.png";
const favouriteStarLink = "https://i.postimg.cc/jSwwmKvd/wepik-export-20230929162319z-Qb2.png"
const yellowFavouriteStarLink = "https://i.postimg.cc/ncJ2kC49/wepik-export-20231005150920fc-Rt.png";

export type Search = {
  text: string;
};

export type SearchResult = {
  template: Template,
  feedback: Feedback,
  faq: FAQ,
  marketing: Marketing,
  similarity: number
}

type ConversationProps = {
  chat: Message[];
};

const Conversation = (props: ConversationProps) => {
  return (
    <div>
      {props.chat.map((item, index) => {
        if (item.role === 'customer') {
          return(
            <MessageContainer key={index}>
              <MessageBubble
                style={{'backgroundColor': 'rgba(0, 0, 0, 0.3)', 'marginRight': '15%', 'maxWidth': '85%'} as React.CSSProperties}
                $isCustomer={true} >
                <DisplayChatText>{item.text}</DisplayChatText>
                <ErrandCardDate>
                  {formatDate(item.last_updated_at)}
                </ErrandCardDate>
              </MessageBubble>
            </MessageContainer>
          );
        }
        else if (item.text){
          console.log("item", item);
          return(
            <MessageContainer key={index}>
              <MessageBubble
                style={{'backgroundColor': 'rgba(15, 134, 161, 0.7)', 'marginLeft': '15%', 'maxWidth': '85%'} as React.CSSProperties}
                $isCustomer={false}>
                <DisplayChatText>{item.text}</DisplayChatText>
                <ErrandCardDate>
                  {formatDate(item.last_updated_at)}
                </ErrandCardDate>
              </MessageBubble>
            </MessageContainer>
          );
        }
      })}
    </div>
  );
}


type Props = {
  currentUser: User | null;
  setCurrentUser: (u: User) => any;
};

const CataloguePage = (props: Props) => {
  const [searchText, setSearchText] = useState("");
  const [favouritesOnly, setFavouritesOnly] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
  const [lastClickedItem, setLastClickedItem] = useState<SearchResult | null>(null);
  const [chat, setChat] = useState<Message[]>([]);
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [selectedFaq, setSelectedFaq] = useState<FAQ | null>(null);
  const [selectedMarketing, setSelectedMarketing] = useState<Marketing | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedType, setSelectedType] = useState('all');
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [noResults, setNoResults] = useState(false);
  const [userSelectEnabled, setUserSelectEnabled] = useState(false);
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');

  const isFetchingList = useRef(false);
  const isFetchingUser = useRef(false);

  const navigate = useNavigate();


  useEffect(() => {
    if (!props.currentUser && !isFetchingUser.current) {
      isFetchingUser.current = true;
      ax.get('/users/company/whoami')
        .then((response) => {
          props.setCurrentUser(response.data);
          ax.get(`/users/company/all?company_id=${response.data.company_id}`)
            .then((response) => setUsers(response.data));
        });
    }
    else if (!isFetchingList.current && props.currentUser) {
      isFetchingList.current = true;
      ax.get(`/users/company/all?company_id=${props.currentUser.company_id}`)
        .then((response) => setUsers(response.data));
    }
    const currentDate = new Date();
    const toYear = currentDate.getFullYear();
    const fromYear = currentDate.getFullYear() - 5;
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const nowDate = `${toYear}-${month}-${day}`;
    const fiveYearDate = `${fromYear}-${month}-${day}`;
    setToTime(nowDate);
    setFromTime(fiveYearDate);
  }, []);

  // useEffect (() => {
  //   if (searchResult.length === 0) {
  //     setNoResults(true);
  //   }
  //   else {
  //     setNoResults(false)
  //   }
  // }, [searchResult]);

  const handleHome = async () => {
    navigate('/user');
  };
  const handleLanguages = async () => {
    //Show menu of possible languages to choose from
  };
  const handleSettings = async () => {
    //Show menu of settings


  };
  const handleSignOut = async () => {
    await ax.post('/auth/logout');
    window.location.href = 'https://ragge.ai/';
  };

  const toggleFavouritesOnly = () => {
    setFavouritesOnly(!favouritesOnly);
  };

  const getSearchResult = async () => {
    setSearchResult([]);
    console.log('user', selectedUser, 'type', selectedType, 'text', searchText, 'from', fromTime, 'to', toTime/*, 'favourites', favouritesOnly*/);
    if (selectedUser) {
      const requestBody: any = {
        "text": searchText,
        "types": [selectedType],
        "user_ids": [selectedUser],
        //"only_favorites": favouritesOnly,
        "from_date": `${fromTime}T23:59`,
        "to_date": `${toTime}T23:59`
      };
      await ax.post('/search', requestBody)
        .then(response => {
            setSearchResult(response.data)
            console.log(response.data);
            if (response.data.length === 0) {
              setNoResults(true);
            }
            else {
              setNoResults(false)
            }
        });
    }
    else if (selectedType === 'feedback') {
      const requestBody: any = {
        "text": searchText,
        "types": [selectedType],
        // "only_favorites": favouritesOnly,
        "from_date": `${fromTime}T23:59`,
        "to_date": `${toTime}T23:59`
      };
      await ax.post('/search', requestBody)
        .then(response => {
            setSearchResult(response.data)
            console.log(response.data);
            if (response.data.length === 0) {
              setNoResults(true);
            }
            else {
              setNoResults(false)
            }
        });
    }
    else {
      const requestBody: any = {
        "text": searchText,
        "types": [selectedType],
        // "only_favorites": favouritesOnly,

      };
      await ax.post('/search', requestBody)
        .then(response => {
            setSearchResult(response.data)
            console.log(response.data);
            if (response.data.length === 0) {
              setNoResults(true);
            }
            else {
              setNoResults(false)
            }
        });
    }

  };

  const handleEnterSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getSearchResult();
    }
  };

  const handleTypeChange = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    setSelectedType(value);
    setUserSelectEnabled(value === 'feedback');
    if (value !== 'feedback') {
      setSelectedUser(null);
    }
  };

  const handleUserChange = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value === '0') {
      setSelectedUser(null);
    }
    else {
      setSelectedUser(value);
    }
  };

  const handleFromTimeChange = (event: { target: { value: any; }; }) => {
    const timeValue = event.target.value;
    setFromTime(timeValue);
  };

  const handleToTimeChange = (event: { target: { value: any; }; }) => {
    const timeValue = event.target.value;
    setToTime(timeValue);
  };

  const setSelectedItem = async (item: SearchResult) => {
    setLastClickedItem(item);
    setSelectedFeedback(null);
    setSelectedTemplate(null);
    setSelectedFaq(null);
    setSelectedMarketing(null);
    setChat([]);
    if (item.feedback) {
      setSelectedFeedback(item.feedback);
      const chatResponse = await ax.get(`/feedback/chat?feedback_id=${item.feedback.id}`);
      const newChat = chatResponse.data;
      setChat(newChat);
    }
    else if (item.template) {
      setSelectedTemplate(item.template);
    }
    else if (item.faq) {
      setSelectedFaq(item.faq);
    }
    else if (item.marketing) {
      setSelectedMarketing(item.marketing);
    }
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <ContentContainer>
            <IconNavigation />
            <FilterSection>
              <Header>Kunskapsbanken</Header>
              <SearchBarContainer>
                <SearchIcon src={searchGlassLink} onClick={getSearchResult} />
                <SearchBar
                  placeholder='Sök...'
                  value={searchText}
                  onChange={(x: any) => setSearchText(x.target.value)}
                  onKeyDown={handleEnterSearch}
                />
              </SearchBarContainer>
              <FilterContainer>
                <FilterText>Typ:</FilterText>
                <FilterBox onChange={handleTypeChange}>
                  <option value='all'>Alla</option>
                  <option value='template'>Mallar</option>
                  <option value='feedback'>Feedback</option>
                  <option value='faq'>FAQ</option>
                  <option value='marketing'>Marknadsföring</option>
                </FilterBox>
              </FilterContainer>
              <FilterContainer>
                {userSelectEnabled &&
                  <><FilterText>Användare:</FilterText>
                    <FilterBox onChange={handleUserChange}>
                      <option key={0} value='0'>Alla</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>{user.first_name}</option>
                      ))}
                    </FilterBox>
                  </>
                }
              </FilterContainer>
              <FilterContainer>
                {userSelectEnabled &&
                  <><FilterText>Från:</FilterText>
                    <FilterTime
                      type='date'
                      id='fromTime'
                      value={fromTime}
                      onChange={handleFromTimeChange}
                    />
                  </>
                }
              </FilterContainer>
              <FilterContainer>
                {userSelectEnabled &&
                  <><FilterText>Till:</FilterText>
                    <FilterTime 
                      type='date'
                      id='toTime'
                      value={toTime}
                      onChange={handleToTimeChange}
                    />
                  </>
                }
              </FilterContainer>
              {/* <FilterContainer style={{'justifyContent': 'flex-start'} as React.CSSProperties}>
                <FilterText>Visa bara favoriter?</FilterText>
                <Checkbox
                  style={{'minHeight': '25px', 'minWidth': '25px', '--check-size': '20px'} as React.CSSProperties}
                  checked={favouritesOnly}
                  onChange={(e: { target: { checked: boolean; }; }) =>
                    toggleFavouritesOnly()}
                />
              </FilterContainer> */}
              <AddMessageContainer>
                <AddMessageButton onClick={getSearchResult}>Sök</AddMessageButton>
              </AddMessageContainer>
            </FilterSection>
            <ResultsSection>
              <Header>Sökresultat</Header>
              <ResultsListContainer>
                {noResults &&
                  <FilterText>
                    Din sökning gav inga resultat.
                    Du kan justera sökningen till vänster och försöka igen.
                  </FilterText>
                }
                {searchResult.map((item, index) => {
                  if (item.feedback) {
                    return(
                      <ResultItemStyling key={index} onClick={() => {
                        setSelectedItem(item);
                      }}
                        selected={lastClickedItem === item}
                      >
                        <ResultTitleSection>
                          <ResultItemTitle>{item.feedback.title}</ResultItemTitle>
                          {/* <FavouriteStar src={favouriteStarLink}/> */}
                        </ResultTitleSection>
                        <ResultItemText>{item.feedback.summary}</ResultItemText>
                      </ResultItemStyling>
                    )
                  }
                  else if (item.template) {
                    return(
                      <ResultItemStyling key={index} onClick={() => {
                        setSelectedItem(item);
                      }}
                        selected={lastClickedItem === item}
                      >
                        <ResultTitleSection>
                          <ResultItemTitle>Mall {item.template.id}</ResultItemTitle>
                          {/* <FavouriteStar src={favouriteStarLink}/> */}
                        </ResultTitleSection>
                        <ResultItemText>{item.template.text_1} {item.template.text_2} {item.template.text_3}</ResultItemText>
                      </ResultItemStyling>
                    )
                  }
                  else if (item.faq) {
                    return(
                      <ResultItemStyling key={index} onClick={() => {
                        setSelectedItem(item);
                      }}
                        selected={lastClickedItem === item}
                      >
                        <ResultTitleSection>
                          <ResultItemTitle>FAQ {item.faq.answer_id} - {item.faq.title}</ResultItemTitle>
                          {/* <FavouriteStar src={favouriteStarLink}/> */}
                        </ResultTitleSection>
                        <ResultItemText>{item.faq.question_text}</ResultItemText>
                      </ResultItemStyling>
                    )
                  }
                  else if (item.marketing) {
                    return(
                      <ResultItemStyling key={index} onClick={() => {
                        setSelectedItem(item);
                      }}
                        selected={lastClickedItem === item}
                      >
                        <ResultTitleSection>
                          <ResultItemTitle>{item.marketing.title}</ResultItemTitle>
                          {/* <FavouriteStar src={favouriteStarLink}/> */}
                        </ResultTitleSection>
                        <ResultItemText>{item.marketing.text}</ResultItemText>
                      </ResultItemStyling>
                    )
                  }
                  else {
                    console.log("Icke: ", item);
                  }
                })}
              </ResultsListContainer>
            </ResultsSection>
            <DisplaySection>
              {lastClickedItem && 
                <div>
                  {selectedFeedback &&
                    <div>
                      <MessageTitleContainer>
                        <MessageTitle>{selectedFeedback.title}</MessageTitle>
                      </MessageTitleContainer>
                      <MessagesArea>
                        <Conversation chat={chat} />
                      </MessagesArea>
                    </div>
                  }
                  {selectedTemplate &&
                    <div>
                      <MessageTitleContainer>
                        <MessageTitle>Mall {selectedTemplate.id}</MessageTitle>
                      </MessageTitleContainer>
                      <MessageContainer>
                        <MessageBubble
                          style={{'backgroundColor': 'rgba(15, 134, 161, 0.7)', 'marginLeft': '10px', 'maxWidth': '100%', 'paddingBottom': '8px'} as React.CSSProperties}
                          $isCustomer={false}>
                            <ChatItemText>{selectedTemplate.text_1}<br/><br/>{selectedTemplate.text_2}<br/><br/>{selectedTemplate.text_3}</ChatItemText>
                        </MessageBubble>
                      </MessageContainer>
                    </div>
                  }
                  {selectedFaq &&
                    <div>
                      <MessageTitleContainer>
                        <MessageTitle>FAQ {selectedFaq.answer_id} - {selectedFaq.title}</MessageTitle>
                      </MessageTitleContainer>
                      <MessagesArea>
                        <MessageContainer>
                          <MessageBubble
                            style={{'backgroundColor': 'rgba(0, 0, 0, 0.3)', 'marginRight': '15%', 'maxWidth': '85%', 'paddingBottom': '6px'} as React.CSSProperties}
                            $isCustomer={true}>
                              <ErrandCardTitle style={{'fontSize': '18px'} as React.CSSProperties}>Fråga</ErrandCardTitle>
                              <ChatItemText>{selectedFaq.question_text}</ChatItemText>
                          </MessageBubble>
                        </MessageContainer>
                        <MessageContainer>
                          <MessageBubble
                            style={{'backgroundColor': 'rgba(15, 134, 161, 0.7)', 'marginLeft': '15%', 'maxWidth': '85%', 'paddingBottom': '6px'} as React.CSSProperties}
                            $isCustomer={false} >
                              <ErrandCardTitle style={{'fontSize': '18px'} as React.CSSProperties}>Svar</ErrandCardTitle>
                              <ChatItemText>{selectedFaq.answer_text}</ChatItemText>
                          </MessageBubble>
                        </MessageContainer>
                      </MessagesArea>
                    </div>
                  }
                  {selectedMarketing &&
                    <div>
                      <MessageTitleContainer>
                        <MessageTitle>{selectedMarketing.title}</MessageTitle>
                      </MessageTitleContainer>
                      <MessageContainer>
                        <MessageBubble
                          style={{'backgroundColor': 'rgba(15, 134, 161, 0.7)', 'marginLeft': '10px', 'maxWidth': '100%', 'paddingBottom': '8px'} as React.CSSProperties}
                          $isCustomer={false}>
                            <ChatItemText>{selectedMarketing.text}</ChatItemText>
                        </MessageBubble>
                      </MessageContainer>
                    </div>
                  }
                </div>
              }
            </DisplaySection>
            
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};

export default CataloguePage;