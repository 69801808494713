import { useState, useEffect } from "react";
import { ax } from "../Utils";
import styled from "styled-components";
import { Template, HistResponse } from "../OpenErrandsPage/Types"
import { useForm } from "react-hook-form";
import {
  FormContainer,
  TitleContainer,
  InputTitle,
  Button,
} from "../CreateErrandPage/CreateErrandPage-styling";

export type Search = {
  text: string;
};

export type SearchResult = {
  template: Template,
  response: HistResponse,
  similarity: number
}


const Container = styled.div`
  display: flex;
  height: 100vh; /* Adjust the height as needed */
`;

export const SearchPage = () => {
  const [search_text, setSearchText] = useState("");
  const [search_result, setSearchResult] = useState<SearchResult[]>([]);
  const { handleSubmit } = useForm<Search>({
    mode: "onChange",
  });

  const getSearchResult = () => {
    ax.post('/search', {
      "text": search_text
    })
      .then(
        (response) => (
          setSearchResult(response.data)
        )
      );
  };

  return (
    <Container>
    <FormContainer>
      <TitleContainer> Search for templates and errands</TitleContainer>
      <InputTitle
        placeholder="Ex: Varför är ostbågar så dyra?"
        value={search_text}
        onChange={(x: any) => setSearchText(x.target.value)}
      />
      <form onSubmit={handleSubmit(getSearchResult)}>
        <Button type="submit">Search</Button>
      </form>
    </FormContainer>

      <div style={{ width: "60%", overflow: "auto" }}>
        <h3>Relevant matches:</h3>
        {search_result.map((item, index) => {
          if (item.response) {
            return <div>
              <b> Response {item.response.id}: {Number((item.similarity).toFixed(2))} </b>
              <br />
              <span> {item.response.text} </span>
              <hr />
            </div>;
          } else if (item.template) {
            return <div>
              <b>Template {item.template.id}: {Number((item.similarity).toFixed(2))} </b>
              <br />
              <span>{item.template.text_1}</span>
              <br />
              <span>{item.template.text_2}</span>
              <br />
              <span>{item.template.text_3}</span>
              <hr />
            </div>;
        }
        return null; // handle cases where neither classA nor classB is present if needed
      })}
      </div>
    </Container>
  );
};
