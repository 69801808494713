import React, { useEffect, useRef, useState } from 'react';
import { ExternalChatbotContainer, ExternalChatbotCustomerMessage, ExternalChatbotFooter, ExternalChatbotHeader, ExternalChatbotHeaderButtonSection, ExternalChatbotHeaderLogo, ExternalChatbotHeaderLogoSection, ExternalChatbotHeaderTitle, ExternalChatbotInput, ExternalChatbotMessage, ExternalChatbotMessageContainer, ExternalChatbotMessagerAvatar, ExternalChatbotMessagesSection, ExternalChatbotMinimizeButton, ExternalChatbotSendButton } from './ExternalChatbot-styling';
import { FaPaperPlane, FaWindowMinimize } from 'react-icons/fa6';
import { useChat } from './Hooks/useChat';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ax } from '../Utils';
import { isBrightColor } from '../Functions/isBrightColor';


const fetchMessages = async () => {
  //const { data } = await ax.get('/api/messages');
  const data = [""];
  return data;
};

const postMessage = async (message: string) => {
  //await ax.post('/api/messages', { message });
  return message;
};

const ExternalChatbot = () => {

  //const { messages, error, isLoading, addMessage } = useChat("ChatId");
  const [inputText, setInputText] = useState<string>('');
  const [parentUrl, setParentUrl] = useState<string>('');
  
  const conversationRef = useRef<HTMLDivElement>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const theme = urlParams.get('theme') || '#0f86a1';

  // Determine if the theme is bright or dark
  const isBright = isBrightColor(theme);
  const textColor = isBright ? '#000000' : '#FFFFFF';

  //set first copilotChat message to the company greeting

  const queryClient = useQueryClient();



  const chat = useQuery<string[]>({
    queryKey: ['chat', /*chatId*/],
    queryFn: fetchMessages,
    initialData: ["Hello! How can I help you today?"],
  });

  const addMessage = useMutation({
    mutationFn: postMessage,
    onSuccess: (message) => {
      console.log('message', message);
      queryClient.setQueryData(['chat', /*chatId*/], [...chat.data, message]);
      queryClient.invalidateQueries(
        {
          queryKey: ['messages'],
          exact: true,
        }
      );
      setInputText('');
      //här generate answer som inte behöver ta in input texten för den är redan postad.
      //generateAnswer får sen i sin tur göra invalidateQueries så att chatten uppdateras, 
      //under den tiden visar vi loading animation (skit i stream)
      //alltså visa old data + en extra bubbla med loading animation tills den nya datan kommer
    },
  });


  useEffect(() => {
    // Listen for the message from the parent window
    const handleMessage = (event: { data: { type: string; url: React.SetStateAction<string>; }; }) => {
      if (event.data.type === 'setParentUrl') {
        setParentUrl(event.data.url);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chat.data]);


  
  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    addMessage.mutate(inputText);
    //generateAnswer();
  };
  
  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 40 > 200 ? 200 : e.target.scrollHeight - 40) + 'px';
  };
  
  const scrollToBottom = () => {
    if (conversationRef.current && chat.data.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleMinimize = () => {
    window.parent.postMessage('toggleChatbot', '*');
  };

  return (
    <ExternalChatbotContainer>
      <ExternalChatbotHeader style={{ backgroundColor: theme, color: textColor }}>
        <ExternalChatbotHeaderLogoSection>
          <ExternalChatbotHeaderLogo src="https://via.placeholder.com/50x35" />
          <ExternalChatbotHeaderTitle>Ragbot</ExternalChatbotHeaderTitle>
        </ExternalChatbotHeaderLogoSection>
        <ExternalChatbotHeaderButtonSection>
          <ExternalChatbotMinimizeButton style={{ color: textColor }} onClick={handleMinimize}>
            <FaWindowMinimize />
          </ExternalChatbotMinimizeButton>
        </ExternalChatbotHeaderButtonSection>
      </ExternalChatbotHeader>
      <ExternalChatbotMessagesSection ref={conversationRef} >
        {chat.isLoading ? <p>Loading...</p>
        : chat.isError ? <p>Error: {chat.error.message}</p>
        : chat.data ? chat.data.map((item, index)=> {
            if (index % 2 === 0) { // Check if index is even
              return (
                <ExternalChatbotMessageContainer key={index}>
                  <ExternalChatbotMessagerAvatar src="https://via.placeholder.com/30x30" />
                  <ExternalChatbotMessage >
                    {item}
                  </ExternalChatbotMessage>
                </ExternalChatbotMessageContainer>
              )
            } else {
              return(
                <ExternalChatbotMessageContainer key={index}>
                  <ExternalChatbotCustomerMessage>
                    {item}
                  </ExternalChatbotCustomerMessage>
                </ExternalChatbotMessageContainer>
              )
            }
          }) : null
      }


      </ExternalChatbotMessagesSection>
      <ExternalChatbotFooter>
        <ExternalChatbotInput
          placeholder="Skriv ett meddelande..."
          rows={1}
          onKeyDown={handleEnterSend}
          onChange={handleTextareaChange}
          value={inputText}
          />
        <ExternalChatbotSendButton onClick={handleSend} style={{ backgroundColor: theme, color: textColor }}>
          <FaPaperPlane />
        </ExternalChatbotSendButton>
      </ExternalChatbotFooter>
    </ExternalChatbotContainer>
  );
}

export default ExternalChatbot;