import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FormContainer,
  TitleContainer,
  InputTitle,
  InputMessage,
  Button,
} from "./CreateErrandPage-styling";
import { ax } from "../Utils";

interface CreateErrandInterface {
  title: string;
  message: string;
}

export const CreateErrand = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const createErrand = async (data: CreateErrandInterface) => {
    const { data: response } = await ax.post(
      `/errands`, {
        errand: {
          title: data.title, 
        },
        message: {
          text: data.message
        }
      }
    );
    return response.data;
  };

  const queryClient = useQueryClient();
  const { handleSubmit } = useForm<CreateErrandInterface>({
    mode: "onChange",
  });
  const { mutate, isLoading } = useMutation(createErrand, {
    onSuccess: (data) => {
      setTitle("");
      setMessage("");
      showToastSucessMessage();
    },
    onError: () => {
      showToastFailMessage();
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const onSubmit = () => {
    mutate({ title: title, message: message });
  };

  const showToastSucessMessage = () => {
    toast.success("Successfully created an errand", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const showToastFailMessage = () => {
    toast.error("Failed to create an errand, try again!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <FormContainer>
      <TitleContainer> Create your errand</TitleContainer>
      <InputTitle
        placeholder="Errand title"
        value={title}
        onChange={(x: any) => setTitle(x.target.value)}
      />
      <InputMessage
        placeholder="Enter the message from the customer"
        value={message}
        onChange={(x: any) => setMessage(x.target.value)}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button type="submit">Create</Button>
      </form>
      <ToastContainer />
    </FormContainer>
  );
};
