import styled, { keyframes } from "styled-components";

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const NavigationSection = styled.div`
  width: 20%;
  min-width: 230px;
  margin-top: 55px;
  overflow: auto;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  max-height: calc(100% - 50px);
  min-height: 240px;
`;

/*export const NavigationHeaderContainer = styled.div`
  width: 100%;
  height: 70px;
`;*/

export const NavigationHeader = styled.h1`
  font-size: 36px;
  color: #d8d8d8;
  margin-left: 20px;
`;
//  font-family: 'Poppins', sans-serif;?

export const NavigationListContainer = styled.div`
  width: calc(100% - 5px);
  padding-right: 5px;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
`;

export const NavigationItem = styled.button`
  margin-bottom: 10px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 30px;
  padding: 20px;
  border-radius: 6px;
  width: 100%;
  text-align: start;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  }
`;//    text-decoration: underline; possibly on hover

export const SelectedNavigationItem = styled.button`
  margin-bottom: 10px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 30px;
  padding: 20px;
  border-radius: 6px;
  width: 100%;
  text-align: start;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
`;


export const RefreshCopilotButton = styled.button`
  padding: 0px 30px 0px 30px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 32px;
  border-radius: 6px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  border: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 3px #000;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.6), 0 0 3px #000;
  }
`;

export const CopilotContainer = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  min-height: 240px;
  border-radius: 6px;
  align-items: flex-start;
  flex-direction: row;
  overflow-x: auto;
  display: flex;
  box-shadow: inset 0px 0px 5px 0px #000, 0 0 5px #000;
  margin: 55px 5px 0 5px;
`;

export const CopilotSection = styled.div`
  height: 100%;
  width: 100%;
  min-width: 240px;
  min-height: 320px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  padding: 0;

  
`;

export const ConversationSection = styled.div`
  min-height: 160px;
  max-height: calc(100% - 80px);
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  display: flex;
  flex: 1;
`;//Eventuellt calc på height

export const CopilotChatItem = styled.div<{$isCopilot: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  background-color: ${(props) => (props.$isCopilot ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)")};
  padding-bottom: 5px;
  position: relative;

  &:last-child{
    height: 100%;
  }
`;//Färg beroende på om det är från oss eller användare

export const ChatProfileImgContainer = styled.div`
  margin: 10px;
  width: 36px;
  height: 36px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 15px;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  text-align: center;
  line-height: 35px;
  color: #0f86a1;
  font-size: 24px;
  font-weight: 500;
  overflow: clip;
  display: flex;
  z-index: 1;
`;

export const CopilotProfileImg = styled.img`
  max-width: 36px;
  max-height: 36px;
`;

export const CopilotChatItemText = styled.p`
  font-size: 18px;
  color: #fff;
  margin: 15px 15px 5px 5px;
  line-height: 1.25;
  white-space: pre-line;
`;

export const CopilotInputSection = styled.div`
  min-height: 50px;
  width: calc(100% - 50px);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px 25px 15px 25px;
  display: flex;
  position: relative;
`;

export const CopilotInput = styled.textarea`
  height: auto;
  max-height: 100px;
  width: calc(100% - 90px);
  padding: 15px 65px 15px 15px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  color: #fff;
  resize: none;
  font-size: 18px;
  font-family: "Tahoma", "Arial", "Arabic Transparent";
  margin-bottom: 15px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  line-height: 1.25;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 7px #000;
  }
  &::placeholder {
    color: #999;
    opacity: 1;
  }
`;

export const SendIcon = styled.img`
  position: absolute;
  bottom: 43px;
  right: 45px;
  height: 30px;
  cursor: pointer;
  
  &:hover {
    bottom: 41px;
    right: 43px;
    height: 34px;
  }
`;


const rotate_3922 = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const CopilotImgAnimation = styled.div`
  position: absolute;
  top: 28px;
  left: 27px;
  border-radius: 50%;
  height: 46px;
  width: 46px;
  animation: ${rotate_3922} 1.2s linear infinite;
  background-color: #9b59b6;
  background-image: linear-gradient(red, white, #0f86a1);

  span {
    position: absolute;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    background-color: #9b59b6;
    background-image: linear-gradient(#9b59b6, #84cdfa, #5ad1cd);

    &:nth-of-type(1) {
      filter: blur(5px);
    }

    &:nth-of-type(2) {
      filter: blur(10px);
    }

    &:nth-of-type(3) {
      filter: blur(25px);
    }

    &:nth-of-type(4) {
      filter: blur(50px);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    border: solid 5px #ffffff;
    border-radius: 50%;
  }
`;

/*const bounce = keyframes`
  50% {
    transform: translateY(-10px);
  }
`;

export const Loader = styled.div`
  width: 50px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 25px;
`;

export const Ball = styled.li`
  list-style: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;

  &:nth-child(1) {
    animation: ${bounce} 1s ease-in-out infinite;
  }

  &:nth-child(2) {
    animation: ${bounce} 1s ease-in-out 0.2s infinite;
  }

  &:nth-child(3) {
    animation: ${bounce} 1s ease-in-out 0.4s infinite;
  }
`;*/

const blink = keyframes`
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
`;

export const BlinkingSpan = styled.span`
  animation: ${blink} 1.5s infinite;
  display: inline-block;
  font-size: 16px;
  vertical-align: 10%;
`;