function getRGBComponents(color: string) {
  let r: number | undefined, g: number | undefined, b: number | undefined;

  if (color.charAt(0) === '#') {
    if (color.length === 4) {
      r = parseInt(color.charAt(1) + color.charAt(1), 16);
      g = parseInt(color.charAt(2) + color.charAt(2), 16);
      b = parseInt(color.charAt(3) + color.charAt(3), 16);
    } else {
      r = parseInt(color.substr(1, 2), 16);
      g = parseInt(color.substr(3, 2), 16);
      b = parseInt(color.substr(5, 2), 16);
    }
  } else if (color.indexOf('rgb') === 0) {
    const match = color.match(/rgba?\((\d+), (\d+), (\d+)(?:, \d+)?\)/);
    if (match) {
      r = parseInt(match[1], 10);
      g = parseInt(match[2], 10);
      b = parseInt(match[3], 10);
    } else {
      // Handle the case when 'match' is null
      r = 0;
      g = 0;
      b = 0;
    }
  }

  return { r: r!, g: g!, b: b! };
}

export function isBrightColor(color: string) {
  const { r, g, b } = getRGBComponents(color);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 155; // Arbitrary threshold, you can adjust it if necessary
}