import React from 'react';
import {
  AdvancedOptionsSection,
  TemplatesSection,
  TemplatesTitle,
  CloseButton,
  RelevantTemplateStyling,
  RelevantTemplateText,
  RelevantTemplateTitle,
  Checkbox,
  OptionTitleSection,
  AddMessageButton,
  GenerateResponseContainer,
  MessageTitle,
  AdvancedTextInput,
  LengthSlider,
  LabelsContainer,
} from "../ErrandsPage-styling"
import { FAQ, HistResponse, Template } from '../Types';

type TemplateProps = {
  template: Template;
  index: number;
  handleTemplateCheck: (index: number, checked: boolean) => void;
};

const RelevantTemplate = (props: TemplateProps) => {
  return (
    <RelevantTemplateStyling>
      <OptionTitleSection>
        <Checkbox 
          checked={props.template.checked}
          onChange={(e: { target: { checked: boolean; }; }) =>
            props.handleTemplateCheck(props.index, e.target.checked)}
        />
        <RelevantTemplateTitle>Mall {props.template.id}</RelevantTemplateTitle>
      </OptionTitleSection>
      <RelevantTemplateText>{
        props.template.text_1 + ' ' + 
        props.template.text_2 + ' ' + 
        props.template.text_3}
      </RelevantTemplateText>
    </RelevantTemplateStyling>
  );
};

type ResponseProps = {
  histResponse: HistResponse;
  index: number;
  handleHistResponsesCheck: (index: number, checked: boolean) => void;
};

const SimilarResponse = (props: ResponseProps) => {
  return (
    <RelevantTemplateStyling>
      <OptionTitleSection>
        <Checkbox
          checked={props.histResponse.checked}
          onChange={(e: { target: { checked: boolean; }; }) =>
            props.handleHistResponsesCheck(props.index, e.target.checked)}
        />
        <RelevantTemplateTitle>Svar {props.histResponse.id}</RelevantTemplateTitle>
      </OptionTitleSection>
      <RelevantTemplateText>{props.histResponse.text}</RelevantTemplateText>
    </RelevantTemplateStyling>
  );
};

type FaqProps = {
  faq: FAQ;
  index: number;
  handleFaqCheck: (index: number, checked: boolean) => void;
};

const SimilarFaq = (props: FaqProps) => {
  return (
    <RelevantTemplateStyling>
      <OptionTitleSection>
        <Checkbox
          checked={props.faq.checked}
          onChange={(e: { target: { checked: boolean; }; }) =>
            props.handleFaqCheck(props.index, e.target.checked)}
        />
        <RelevantTemplateTitle>FAQ {props.faq.answer_id} - {props.faq.title}</RelevantTemplateTitle>
      </OptionTitleSection>
      <RelevantTemplateText>{props.faq.answer_text}</RelevantTemplateText>
    </RelevantTemplateStyling>
  );
};

type Props = {
  templates: Template[];
  histResponses: HistResponse[];
  toggleAdvancedOptions: () => void;
  handleTemplateCheck: (index: number, checked: boolean) => void;
  handleHistResponsesCheck: (index: number, checked: boolean) => void;
  generateResponse: () => void;
  faqs: FAQ[];
  handleFaqCheck: (index: number, checked: boolean) => void;
  advancedOptionsText: string;
  setAdvancedOptionsText: (s: string) => any;
  answerLength: number;
  handleLengthChange: (e: any) => void;
};

const AdvancedOptions = (props: Props) => {
  return (
    <AdvancedOptionsSection>
      <div>
        <MessageTitle style={{'marginBottom': '0'} as React.CSSProperties}>Avancerade alternativ</MessageTitle>
        <CloseButton onClick={props.toggleAdvancedOptions} />
        <TemplatesSection>
          {props.templates.length > 0 &&
            <TemplatesTitle>Relevanta mallar</TemplatesTitle>
          }
          {props.templates.map((template, index) => (
            <RelevantTemplate
              key={index}
              template={template}
              index={index}
              handleTemplateCheck={props.handleTemplateCheck}
            />
          ))}
        </TemplatesSection>
        <TemplatesSection>
          {props.histResponses.length > 0 &&
            <TemplatesTitle>Svar till liknande meddelanden</TemplatesTitle>
          }
          {props.histResponses.map((histResponse, index) => (
            <SimilarResponse
              key={index}
              histResponse={histResponse}
              index={index}
              handleHistResponsesCheck={props.handleHistResponsesCheck}
            />
          ))}
        </TemplatesSection>
        <TemplatesSection>
          {props.faqs.length > 0 &&
            <TemplatesTitle>Liknande FAQ:n från hemsidan</TemplatesTitle>
          }
          {props.faqs.map((faq, index) => (
            <SimilarFaq
              key={index}
              faq={faq}
              index={index}
              handleFaqCheck={props.handleFaqCheck}
            />
          ))}
        </TemplatesSection>
        <TemplatesSection>
          <TemplatesTitle>Svarets längd</TemplatesTitle>
          <LengthSlider
            type="range"
            min="1"
            max="5"
            step="1"
            value={props.answerLength}
            onChange={props.handleLengthChange}
          />
          <LabelsContainer>
            <span>Kort</span>
            <span>Medium</span>
            <span>Långt</span>
          </LabelsContainer>
        </TemplatesSection>
          
        <TemplatesSection>
          <TemplatesTitle>Fri text</TemplatesTitle>
          <AdvancedTextInput
            placeholder='Skriv din fritext här...'
            value={props.advancedOptionsText}
            onChange={(e: { target: { value: string; }; }) =>
              props.setAdvancedOptionsText(e.target.value)}
          />
        </TemplatesSection>
      </div>
      <GenerateResponseContainer>
        <AddMessageButton onClick={props.generateResponse}>
          Generera Svar
        </AddMessageButton>
      </GenerateResponseContainer>
    </AdvancedOptionsSection>
  );
};
export default AdvancedOptions;