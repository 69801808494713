import React from 'react';
import{
  PopupOverlay,
  CloseErrandWindow,
  CloseButton,
  PopUpTitleContainer,
  CloseErrandInfoSection,
  CloseErrandInfo,
  CloseErrandButtonSection,
  DeleteErrandButton,
  CancelButton,
} from "../ErrandsPage/ErrandsPage-styling"


type Props = {
  toggleShowRefreshPopUp: () => void;
  handleRefreshCopilot: () => any;

}

const RefreshCopilotPopUp = (props: Props) => {
  return (
    <PopupOverlay>
      <CloseErrandWindow>
        <PopUpTitleContainer>Återställ chatten för att ställa en ny fråga.</PopUpTitleContainer>
        <CloseButton 
          onClick={props.toggleShowRefreshPopUp}
          style={{'--after-color': '#a0a0a0',} as React.CSSProperties}
        />
        <CloseErrandInfoSection>
          <CloseErrandInfo>
            Återställ chatten för att börja om med en ny fråga. <br/><br/>
            OBS! Återställer du Copilot chatten så kommer konversationen
            att rensas och du kommer inte kunna få tillbaka den.
          </CloseErrandInfo>
        </CloseErrandInfoSection>
        <CloseErrandButtonSection>
          <DeleteErrandButton
            style={{'backgroundColor': '#0f86a1',} as React.CSSProperties}
            onClick={props.handleRefreshCopilot}>
            Återställ
          </DeleteErrandButton>
          <CancelButton onClick={props.toggleShowRefreshPopUp}>
            Avbryt
          </CancelButton>
        </CloseErrandButtonSection>
      </CloseErrandWindow>
    </PopupOverlay>
  );
};

export default RefreshCopilotPopUp;
