
import { TForgotPasswordSchema, forgotPasswordSchema } from "../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ax } from "../Utils";
import { toast } from "react-toastify";
import { MainContent } from "../LoginPage/LoginPage-styling";
import { PageBackground, ScreenProtector, NavBar, LogoImg, Logo, Nav, NavItem, ContentContainer, Container } from "../Styling/NavBar-styling";
import { useNavigate } from "react-router-dom";
import { AuthorizationButton, AuthorizationContainer, AuthorizationError, AuthorizationForm, AuthorizationInput, AuthorizationLabel, AuthorizationText, AuthorizationTitle, LinkSpan } from "./Authorization-styling";

const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";


export default function ForgotPasswordPage() {
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    clearErrors,
  } = useForm<TForgotPasswordSchema>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  const onSubmit = async (data: TForgotPasswordSchema) => {
    console.log("submitting form", data);
    const requestBody = {
      email: data.email,
    };

    try {
      await ax.post('/auth/forgot-password', requestBody);
    }
    catch (error) {
      alert("Förfrågan misslyckades. Error: " + error);
      return;
    }

    toast.success("Ett mail har skickats till din e-postadress med instruktioner för att återställa ditt lösenord.");
    reset();

    // reset();
  };

  const handleHome = async () => {
    navigate('/');
  };
  const handleBack = async () => {
    navigate('/login');
  };
  const handleContact = async () => {
    navigate('/contact');
  };

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo>ragge.ai</Logo>
            <Nav>
              <NavItem onClick={handleBack}>Tillbaka</NavItem>
            </Nav>
          </NavBar>
          <ContentContainer>
            <MainContent>
              <AuthorizationContainer>
                <AuthorizationTitle>Glömt lösenord?</AuthorizationTitle>
                <AuthorizationText>
                  För att återställa lösenordet, skriv in e-postadressen du använde för att skapa kontot. 
                  En återställningslänk kommer att skickas till e&#8209;postadressen.
                </AuthorizationText>
              <AuthorizationForm onSubmit={handleSubmit(onSubmit)}>
                <AuthorizationLabel htmlFor="email">E&#8209;postadress</AuthorizationLabel>
                <AuthorizationInput
                  {...register("email")}
                  type="email"
                  placeholder="exempel@domän.se"
                  id="email"
                />
                {errors.email && (
                  <AuthorizationError>{`${errors.email.message}`}</AuthorizationError>
                )}

                <AuthorizationButton
                  disabled={isSubmitting}
                  type="submit"
                >
                  Skicka återställningslänk
                </AuthorizationButton>
              </AuthorizationForm>
              <AuthorizationText>
                Kommer du inte ihåg e&#8209;postadressen? Kontakta en administratör i din organisation eller <LinkSpan onClick={handleContact}>kontakta oss</LinkSpan>.
              </AuthorizationText>
              </AuthorizationContainer>
            </MainContent>
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
    
  );
}