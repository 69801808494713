import React from 'react';
import{
  NoActiveErrandSection,
  NoActiveErrandText,
  CreateErrandButton,
  OpenErrandSection,
  MessagesSection,
  MessageTitleContainer,
  MessageTitle,
  MessagesArea,
  MessageContainer,
  MessageBubble,
  AddMessageContainer,
  AddMessageButton,
  BottomSection,
  ButtonSection,
  SendButton,
  ResponseSection,
  ResponseTitleContainer,
  ResponseTitle,
  ResponseContainer,
  ResponseInput,
  AdvancedOptionsText,
  AdvancedOptionsWrapper,
  ErrandCardDate,
  ErrandCardTitle,
  SectionWrapper,
  AddMessageInput,
  AddMessageInputButton,
  AddMessageInputContainer,
  AddMessageInputButtonSection,
  CloseErrandButton,
  
} from "../ErrandsPage-styling"
import {
  Errand,
  Message,
  Response,
} from "../Types";
import { formatDate } from "../../Utils";

type ConversationProps = {
  chat: (Message | Response)[];
};

const Conversation = (props: ConversationProps) => {
  return (
    <div>
      {props.chat.map((item, index) => {
        if (item.class_type === 'response') {
          const response = item as Response;
          return(
            <MessageContainer key={index}>
              <MessageBubble $isCustomer={false}>
                <ErrandCardTitle>{response.text}</ErrandCardTitle>
                <ErrandCardDate>
                  Skickat: {formatDate(response.created_at)}
                </ErrandCardDate>
              </MessageBubble>
            </MessageContainer>
          );
        }
        else {
          const message = item as Message;
          return(
            <MessageContainer key={index}>
              <MessageBubble $isCustomer={true}>
                <ErrandCardTitle>{message.text}</ErrandCardTitle>
                <ErrandCardDate>
                  Mottaget: {formatDate(message.created_at)}
                </ErrandCardDate>
              </MessageBubble>
            </MessageContainer>
          );
        }
      })}
    </div>
  );
}

type Props = {
  selectedErrand: Errand | null;
  chat: (Message | Response)[];
  proposedResponse: string | undefined;
  setProposedResponse: (x: string) => any;
  toggleAdvancedOptions: () => void;
  newResponseText: string;
  setNewResponseText: (x: string) => any;
  handleSendResponse: () => any;
  handleCreateErrand: () => void;
  showAddMessageButton: boolean;
  showAddMessageInput: boolean;
  handleAddMessageClick: () => void;
  handleAddMessageSave: () => void;
  handleAddMessageCancel: () => void;
  setAddMessageText: (x: string) => any;
  addMessageText: string;
  toggleCloseErrand: () => void;
  message: Message | null;
  errandTitle: string;
  conversationRef: React.RefObject<HTMLDivElement>;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  responseInputRef: React.RefObject<HTMLTextAreaElement>;
};

//Conversation should be mapped, but then it needs to be taken from a list
const OpenErrand = (props: Props) => {
  return (
    <SectionWrapper>
      {!props.selectedErrand && (
        <NoActiveErrandSection>
          <NoActiveErrandText>
            Välj en recension till vänster eller öppna en ny med knappen nedan
          </NoActiveErrandText>
          <CreateErrandButton onClick={props.handleCreateErrand}>
            Ny Recension
          </CreateErrandButton>
        </NoActiveErrandSection>
      )}
      {props.selectedErrand && (
        <OpenErrandSection>
          <MessagesSection>
            <MessageTitleContainer>
              <MessageTitle>{props.errandTitle}</MessageTitle>
              <CloseErrandButton onClick={props.toggleCloseErrand}>
                Avsluta
              </CloseErrandButton>
            </MessageTitleContainer>
            <MessagesArea ref={props.conversationRef}>
              <Conversation
                chat={props.chat}
              />
              <AddMessageContainer>
                {props.showAddMessageButton && (
                  <AddMessageButton onClick={props.handleAddMessageClick}>
                    Lägg Till Kundmeddelande
                  </AddMessageButton>
                )}
                {props.showAddMessageInput && (
                  <AddMessageInputContainer>
                    <AddMessageInput 
                      placeholder='Skriv kundens meddelande här...'
                      value={props.addMessageText}
                      onChange={(e: { target: { value: string; }; }) =>
                        props.setAddMessageText(e.target.value)}
                    />
                    <AddMessageInputButtonSection>
                      <AddMessageInputButton onClick={props.handleAddMessageSave}>
                        Spara Meddelande
                      </AddMessageInputButton>
                      <AddMessageInputButton onClick={props.handleAddMessageCancel}>
                        Avbryt
                      </AddMessageInputButton>
                      </AddMessageInputButtonSection>
                  </AddMessageInputContainer>
                )}
              </AddMessageContainer>
            </MessagesArea>
          </MessagesSection>
          <BottomSection>
            <ButtonSection>
              <SendButton onClick={props.handleSendResponse}>Spara</SendButton>
            </ButtonSection>
            <ResponseSection>
              <ResponseTitleContainer>
                <ResponseTitle>Svar:</ResponseTitle>
              </ResponseTitleContainer>
              <ResponseContainer>
                <ResponseInput
                  ref={props.responseInputRef}
                  value={props.newResponseText}
                  onChange={props.handleTextareaChange}
                  rows={1}
                />
              </ResponseContainer>
              {props.message &&
                <AdvancedOptionsWrapper>
                  <AdvancedOptionsText onClick={props.toggleAdvancedOptions}>Avancerade Alternativ</AdvancedOptionsText>
                </AdvancedOptionsWrapper>
              }
            </ResponseSection>
          </BottomSection>
        </OpenErrandSection>
      )}
    </SectionWrapper>
  );


};
export default OpenErrand;