import React from "react";
import styled from "styled-components";

export const TopSection = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #000000;
  border-bottom: solid 1px #000000;
`;

export const MainPage = styled.div`
  background-color: #ffffff; /* Example background color for the right panel */
`;

interface ButtonProps {
  isActive: boolean;
}

export const Button = styled.div<ButtonProps>`
  background-color: ${(props) => (props.isActive ? "#777777" : "#000000")};
  &:hover {
    background-color: #444444;
  }
  font-size: 18px;
  text-align: center;
  color: ${(props) => (props.isActive ? "#ffffff" : "#ffffff")};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
`;
