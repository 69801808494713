// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/VerticalNavbar.css */
.vertical-navbar {
  max-width: 3rem;
  transition: max-width 0.3s, background-color 0.3s;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.2);
  padding-top: 6.2rem;
  z-index: 100;
}

.vertical-navbar:hover {
  max-width: 19rem;
  background-color: rgba(51, 51, 51, 0.85);
}

.nav-item {
  width: calc(100% - 1rem);
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  color: #eee;
  text-decoration: none;
  transition: background-color 0.3s;
}

.nav-item:hover {
  background-color: rgba(36, 36, 36, 0.5);
}

.nav-item:active {
  background-color: rgba(51, 51, 51, 0.7); /* Darker background to indicate click */
  transform: scale(0.98); /* Slightly scale down to give a pressed effect */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Add an inset shadow for a pressed effect */
}

.nav-icon {
  min-width: 2rem;
  height: 2rem;
}

.nav-text {
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  color: #eee;
  font-size: 1.5rem;
}

.vertical-navbar:hover .nav-text {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/Components/IconNavigation-styling.css"],"names":[],"mappings":"AAAA,sCAAsC;AACtC;EACE,eAAe;EACf,iDAAiD;EACjD,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,OAAO;EACP,MAAM;EACN,YAAY;EACZ,uCAAuC;EACvC,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,wCAAwC;AAC1C;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,WAAW;EACX,qBAAqB;EACrB,iCAAiC;AACnC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,uCAAuC,EAAE,wCAAwC;EACjF,sBAAsB,EAAE,iDAAiD;EACzE,4CAA4C,EAAE,6CAA6C;AAC7F;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,wBAAwB;EACxB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["/* src/components/VerticalNavbar.css */\n.vertical-navbar {\n  max-width: 3rem;\n  transition: max-width 0.3s, background-color 0.3s;\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  left: 0;\n  top: 0;\n  height: 100%;\n  background-color: rgba(51, 51, 51, 0.2);\n  padding-top: 6.2rem;\n  z-index: 100;\n}\n\n.vertical-navbar:hover {\n  max-width: 19rem;\n  background-color: rgba(51, 51, 51, 0.85);\n}\n\n.nav-item {\n  width: calc(100% - 1rem);\n  display: flex;\n  align-items: center;\n  padding: 1rem 0.5rem;\n  color: #eee;\n  text-decoration: none;\n  transition: background-color 0.3s;\n}\n\n.nav-item:hover {\n  background-color: rgba(36, 36, 36, 0.5);\n}\n\n.nav-item:active {\n  background-color: rgba(51, 51, 51, 0.7); /* Darker background to indicate click */\n  transform: scale(0.98); /* Slightly scale down to give a pressed effect */\n  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Add an inset shadow for a pressed effect */\n}\n\n.nav-icon {\n  min-width: 2rem;\n  height: 2rem;\n}\n\n.nav-text {\n  margin-left: 20px;\n  white-space: nowrap;\n  overflow: hidden;\n  opacity: 0;\n  transition: opacity 0.3s;\n  color: #eee;\n  font-size: 1.5rem;\n}\n\n.vertical-navbar:hover .nav-text {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
