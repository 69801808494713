import styled from 'styled-components';

export const ErrandContainer = styled.div`
  width: 80%;
  height: calc(100% - 25px);
  margin-top: 25px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  align-items: flex-start;
  flex-direction: row;
  overflow-x: auto;
  display: flex;
`;

//OpenErrand-styling
export const SectionWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const NoActiveErrandSection = styled.div`
  width: 100%;
  height: 100%;
  min-width: 240px;
  min-height: 300px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
`;

export const NoActiveErrandText = styled.h2`
  max-width: 60%;
  min-width: 200px;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  color: #fff;
`;

export const CreateErrandButton = styled.button`
  padding: 25px;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0f86a1;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: #d8d8d8;
  }
`;


export const OpenErrandSection = styled.div`
  height: 100%;
  width: 100%;
  min-width: 240px;
  min-height: 525px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
`;

export const MessagesSection = styled.div`
  min-height: max(70%, 160px);
  width: 100%;
  overflow: hidden;
`;

export const MessageTitleContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const CloseErrandButton = styled.button`
  background-color: rgba(180, 0, 0, 0.9);
  color: #fff;
  font-size: 20px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  margin: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);

  &:hover {
    background-color: rgba(210, 0, 0, 0.9);
    box-shadow: 0 0 5px #000;
  }
`;//#ab3f3f kanske mindre intensiv röd

export const MessageTitle = styled.h1`
  font-size: 24px;
  font-weight: 400;
  padding-left: 10px;
  color: #fff;
`;//write errand title at the top


export const MessagesArea = styled.div`
  width: 100%;
  height: calc(100% - 65px);
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
`;

export const MessageContainer = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export const MessageBubble = styled.div<{$isCustomer: boolean}>`
  background-color: ${(props) => (props.$isCustomer ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)")};
  max-width: 75%;
  margin-right: ${(props) => (props.$isCustomer ? "25%" : "10px")};
  margin-left: ${(props) => (props.$isCustomer ? "10px" : "25%")};
  border-radius: 6px;
`;
//styled.img som kommer vara två exemplar ner i höger hörn av SentMessage, en med tumme upp en med ned
//som fylls med grönt resp. rött om man klickar dem och släcker varandra om den andra redan lyser. (toggle, så den släcker om tänd redan)

export const AddMessageContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;//Bara för icke-chatt konversationer

export const AddMessageButton = styled.button`
  background-color: #fff;
  color: #0f86a1;
  font-size: 20px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;

  &:hover {
    background-color: #d8d8d8;
    box-shadow: 0 0 5px #000;
  }
`;

export const AddMessageInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const AddMessageInputButtonSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddMessageInput = styled.textarea`
  height: calc(100% - 26px);
  width: calc(100% - 6px);
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  color: #fff;
  resize: none;
  font-size: 16px;
  font-family: "Tahoma", "Arial", "Arabic Transparent";
  margin-right: 15px;
  max-width: 75%;
  margin-left: 10px;
  
  &:focus {
    outline: none;
    border:1px solid #0f86a1;
    box-shadow: 0 0 5px #0f86a1;
  }
`;

export const AddMessageInputButton = styled.button`
  background-color: #fff;
  color: #0f86a1;
  font-size: 20px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    background-color: #d8d8d8;
    box-shadow: 0 0 5px #0f86a1;
  }
`;


export const BottomSection = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const ButtonSection = styled.div`
  width: 15%;
  min-width: 100px;
  padding-top: 25px;
  display: flex;
  justify-content: center;
  align-content: flex-end;
`;

export const SendButton = styled.button`
  max-width: 90%;
  height: 48px;
  width: 120px;
  color: #0f86a1;
  background-color: #fff;
  font-size: 30px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  padding: 5px 0px 5px 0px;
  margin-bottom: 10px;

  &:hover {
    background-color: #d8d8d8;
    box-shadow: 0 0 5px #000;
  }
`;

export const ResponseSection = styled.div`
  height: 100%;
  width: 85%;
  min-width: 120px;
`;

export const ResponseTitleContainer = styled.div`
  height: 25px;
  justify-content: start;
`;

export const ResponseTitle = styled.h1`
  font-size: 20px;
  font-weight: 400;
  color: #fff;
`;

export const ResponseContainer = styled.div`
  height: calc(100% - 70px);
  width: calc(100% - 20px);
  padding-right: 20px;
`;

export const ResponseInput = styled.textarea`
  height: auto;
  max-height: 250px;
  width: calc(100% - 6px);
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  color: #fff;
  resize: none;
  font-size: 16px;
  font-family: "Tahoma", "Arial", "Arabic Transparent";
  white-space: pre-line;
  margin-bottom: 10px;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 4px #000;
  }
`;

export const AdvancedOptionsWrapper = styled.div`
  width: 100%;
  height: 25px;
  text-align: center;
`;

export const AdvancedOptionsText = styled.a`
  color: #fff;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    text-decoration: underline;
  }
`;



//AdvancedOptions-styling
export const AdvancedOptionsSection = styled.div`
  height: 100%;
  width: 100%;
  min-width: 240px;
  min-height: 320px;
  margin-left: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  overflow: auto;
  position: relative;
  justify-content: space-between;
  display: flex;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 7px;
    right: 7px;
    background: none;
    border: none;
    font-size: 0;
    cursor: pointer;
    padding: 0;
    width: 18px;
    height: 18px;
    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2px;
        height: 100%;
        background-color: var(--after-color, #d0d0d0);
    }
    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:hover {
      top: 5px;
      right: 5px;
      width: 22px;
      height: 22px;
        &:before,
        &:after {
            background-color: #f00;
            width: 3px;
        }
    }
`;

export const TemplatesSection = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;//add max-height: 350px; if template cards don't adjust height

export const TemplatesTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  padding-left: 10px;
  color: #fff;
  padding-right: 25px;
`;

export const RelevantTemplateStyling = styled.div`
  min-height: 75px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 3px;
  margin-left: 5px;
  margin-right: 10px;
  overflow: clip;
  border: 1px solid rgba(0, 0, 0, 0.3);

  &:last-child {
    margin-bottom: 5px;
  }
`;

export const OptionTitleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Checkbox = styled.input.attrs({type: 'checkbox'})`
  appearance: none;  
  width: 20px;
  height: 20px;
  margin-left: 11px;
  margin-top: 5px;
  margin-bottom: 0;
  border: 2px solid #d8d8d8;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.5s, border-color 0.5s;

  &:hover {
      border-color: #888;
  }
  &:checked {
      background-color: #0f86a1;
      border-color: #000;
  }
  &:checked:before {
    content: '\\2713';
    display: block;
    font-size: var(--check-size, 15px);
    font-weight: 500;
    color: #fff;
    line-height: 1;
    text-align: center;
  }
  &:checked:hover {
    border-color: #888;
  }
`;//Eventuellt ta bort all styling. kan se hur det ser ut genom att bara ta bort översta raden

export const RelevantTemplateTitle = styled.div`
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 11px;
  font-size: 16px;
  color: #fff;
  justify-content: start;
`;

export const RelevantTemplateText = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 14px;
  color: #fff;
  justify-content: start;
  overflow: clip;
`;

export const LengthSlider = styled.input`
  accent-color: #0f86a1;
  width: calc(100% - 20px);
  margin-left: 5px;
  margin-top: 0;
  height: 15px;
  cursor: pointer;
`;

/**  -webkit-appearance: none;  
  width: calc(100% - 20px);
  appearance: none;
  height: 15px;
  outline: 1px solid rgba(0, 0, 0, 0.4);
  opacity: 0.7;
  transition: opacity 0.2s;
  margin-bottom: 10px;
  margin-left: 5px;
  border-radius: 6px;
  accent-color: #F55050;


  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
  } */

export const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  margin-top: 5px;
  padding-left: 5px;
  color: #fff;
`;

export const AdvancedTextInput = styled.textarea`
  width: calc(100% - 30px);
  height: 4em;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  color: #fff;
  resize: none;
  font-size: 14px;
  font-family: "Tahoma", "Arial", "Arabic Transparent";
  margin-left: 5px;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 4px #000;
  }
`;

export const GenerateResponseContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;


//ErrandsList-styling
export const ErrandsListSection = styled.div`
  width: 20%;
  height: calc(100% - 25px);
  min-height: 307px;
  min-width: 150px;
  margin-top: 25px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: safe start;
`;

export const ErrandsHeader = styled.h1`
  font-size: 2rem;
  color: #fff;
  font-weight: 500;
  padding-left: 12px;
  margin-top: 10px;
`;

export const CreateErrandWrapper = styled.div`
  width: 94%;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-left: 1px;
  box-sizing: border-box;
  border: 1px solid #000;
  margin-bottom: 2px;

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  }
`;

export const CreateErrandCard = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  text-align: center;
  padding: 1.976rem;
  box-sizing: border-box;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const ErrandsListContainer = styled.div`
  width: 100%;
  max-height: calc(100% - 78px);
  min-height: 400px;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  display: flex;
`;

export const ErrandCardStyling = styled.div<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 90px;
  width: 94%;
  border-radius: 6px;
  background-color: ${(props) => (props.selected ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.2)')};
  margin-bottom: 2px;
  margin-left: 1px;
  overflow: clip;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: ${(props) => (props.selected ? 'inset 0px 0px 4px 0px rgba(0, 0, 0, 0.4)' : 'none')};

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  &:last-child {
    margin-bottom: 5px;
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  }
`;  //

export const ErrandCardTitle = styled.div`
  padding-top: 8px;
  margin-bottom: 2px;
  padding-left: 11px;
  padding-right: 11px;
  color: #fff;
  font-weight: 500;
  white-space: pre-line;
`;

export const ErrandCardText = styled.div`
  height: 2.2rem;
  min-height: 1.2rem;
  margin-top: 4px;
  margin-bottom: 2px;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 14px;
  color: #fff;
  overflow: clip;
  line-height: 1.25;
`;

export const ErrandCardDate = styled.div`
  padding-bottom: 6px;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 11px;
  color: #ccc;
  text-align: right;

`;


// Create errand styling

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const CreateErrandWindow = styled.div`
  width: 60%;
  height: 60%;
  max-width: 800px;
  max-height: 800px;
  min-height: 400px;
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PopUpTitleContainer = styled.div`
  margin: 20px;
  text-align: center;
  font-size: 36px;
  color: #000;
  background-color: #fff;
`;

export const CreateErrandInputSection = styled.div`
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  min-width: 240px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const CreateErrandTitleInput = styled.input`
  width: 100%;
  height: 100px;
  min-height: 50px;
  display: block;
  margin-bottom: 10px;
  font-size: 30px;
  border: 2px solid #333;
  border-radius: 6px;
  transition: border-color 0.3s;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  outline: none;
  box-sizing: border-box;

  &:hover {
    background-color: rgba(15, 134, 161, 0.1);
  }
  &:focus {
    box-shadow: 0 0 6px #0f86a1;
    outline: 1px solid #0f86a1;
  }
`;

export const CreateErrandTextInput = styled.textarea`
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  color: #000;
  border: 2px solid #333;
  resize: none;
  font-size: 20px;
  font-family: "Tahoma", "Arial", "Arabic Transparent";
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: border-color 0.3s;
  
  &:hover {
    background-color: rgba(15, 134, 161, 0.1);
  }
  &:focus {
    box-shadow: 0 0 6px #0f86a1;
    outline: 1px solid #0f86a1;
  }
`;

export const SaveErrandButton = styled.button`
  background-color: #0f86a1;
  color: #fff;
  border: none;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  margin-top: 15px;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #0a6073;
    box-shadow: 0 0 4px #0f86a1;
  }
`;


//Close errand styling

export const CloseErrandWindow = styled.div`
  width: 60%;
  max-width: 600px;
  min-height: 300px;
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
`;

export const CloseErrandInfoSection = styled.div`
  padding: 15px;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const CloseErrandInfo = styled.a`
  text-align: center;
  font-size: 20px;
`;

export const CloseErrandButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 5px;
`;

export const DeleteErrandButton = styled.button`
  background-color: #d00000;
  color: #fff;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  margin-right: 20px;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #b00000;
    box-shadow: 0 0 3px #000;
  }
`;

export const CancelButton = styled.button`
  background-color: #d0d0d0;
  color: #000;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #aaa;
    box-shadow: 0 0 3px #000;
  }
`;