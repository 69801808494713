import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { 
  FeedbackChatSection, 
  MessagesSection, 
  SeparatorLine, 
  MessageTitle, 
  Email, 
  EmailToFrom, 
  EmailInfoText, 
  EmailSender,
  EmailContent,
  ToolsSeparatorLine,
  SendEmailButton,
  Review,
  ReviewResponseInput,
  ReviewRating,
  ReviewText
} from "../Feedback-styling";
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Editor, useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Image from '@tiptap/extension-image'
import { MantineProvider } from '@mantine/core';
// import { notifications } from '@mantine/notifications';
import { ToolsGroup } from '../../MarketingPage/MarketingPage-styling';
import {FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaBold, 
  FaFont, FaHeading, FaHighlighter, FaItalic, FaLink, FaListOl, FaListUl, 
  FaRedo, FaStrikethrough, FaUnderline, FaUndo, FaUnlink
} from "react-icons/fa";
import { Feedback, FeedbackType, Message } from '../../Types';
import { debounce, set } from 'lodash';
import { toast } from 'react-toastify';
import { ax, api_base_url } from "../../Utils";
import { Id, Toast } from 'react-toastify/dist/types';
import { NoActiveErrandSection, NoActiveErrandText } from '../../ErrandsPage/ErrandsPage-styling';


type FeedbackProps = {
  feedbackChat: Message[];
  feedbackType: FeedbackType;
  timePassed: (date: Date) => string;
  selectedFeedback: Feedback | null;
  handleChange: (html: string, text: string) => void;
  cancelHandleChange: () => void;
  content: string;
  selectedFeedbackRef: React.MutableRefObject<Feedback | null>;
  sendEmail: (html: string) => void;
  updatePromptContent: (content: string) => void;
  editorRef: React.MutableRefObject<Editor | null>;
};

const FeedbackChat = (props: FeedbackProps) => {

  return (
    <FeedbackChatSection>
      {props.feedbackType === 'email' ? 
        <>
          <MessagesSection>
            <MessageTitle>
              {props.selectedFeedback?.title || props.feedbackType || "Feedback"}
            </MessageTitle>
            <SeparatorLine />
            {props.feedbackChat.map((item, index) => {
              if (item.sent_at === null) return null;
              return (
                <Fragment key={index}>
                  <Email>
                    <EmailToFrom>
                      <EmailInfoText>
                        <EmailSender>{item.sender_contact_info.name}</EmailSender> 
                        &nbsp;&lt;{item.sender_contact_info.email}&gt; 
                        &nbsp;&bull;&nbsp; för {props.timePassed(new Date(item.sent_at))} sedan<br />
                        till {item.receiver_contact_info.map((receiver, _recIndex) => {
                          if (receiver.name) {
                            return (
                              <Fragment key={_recIndex}>
                                {receiver.name}
                              </Fragment>
                            );
                          } else {
                            return (
                              <Fragment key={_recIndex}>
                                {receiver.email}
                              </Fragment>
                            );
                          }
                        })}
                      </EmailInfoText>
                    </EmailToFrom>
                    <EmailContent dangerouslySetInnerHTML={{__html: item.body }} />
                  </Email>
                  <SeparatorLine />
                </Fragment>
              );
            })}
          </MessagesSection>
          <EmailEditor
            handleChange={props.handleChange}
            cancelHandleChange={props.cancelHandleChange}
            selectedFeedback={props.selectedFeedback}
            content={props.content}
            selectedFeedbackRef={props.selectedFeedbackRef}
            sendEmail={props.sendEmail}
            updatePromptContent={props.updatePromptContent}
            editorRef={props.editorRef}
          />
        </>
      : props.feedbackType === 'review' ? 
        <>
          <MessagesSection>
            <MessageTitle>
              {props.selectedFeedback?.title || props.feedbackType || "Feedback"}
            </MessageTitle>
            <SeparatorLine />
            {props.feedbackChat.map((item, index) => 
              <Review>
                <EmailToFrom>
                  <EmailInfoText>
                    <EmailSender>{item.sender_contact_info.name}</EmailSender> 
                    &nbsp;&bull;&nbsp; Recension från Google<br />
                    <ReviewRating>{props.selectedFeedback?.rating}/5</ReviewRating> 
                    &nbsp;&bull;&nbsp; för {props.timePassed(new Date(item.sent_at))} sedan
                  </EmailInfoText>
                </EmailToFrom>
                <ReviewText>{item.text}</ReviewText>
              </Review>
            )}
          </MessagesSection>
          <ReviewResponseInput
            placeholder="Svara här"
            rows={1}
          />
        </>
      : <NoActiveErrandSection style={{backgroundColor: 'transparent'}}><NoActiveErrandText>Välj en Feedback</NoActiveErrandText></NoActiveErrandSection>}
    </FeedbackChatSection>
  );
};
export default FeedbackChat;


type Props = {
  handleChange: (html: string, text: string) => void;
  cancelHandleChange: () => void;
  selectedFeedback: Feedback | null;
  selectedFeedbackRef: React.MutableRefObject<Feedback | null>;
  content: string;
  sendEmail: (html: string) => void;
  updatePromptContent: (content: string) => void;
  editorRef: React.MutableRefObject<Editor | null>;
};

const EmailEditor = (props: Props) => {
  const [showTools, setShowTools] = useState(false);



  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Image,
    ],
    content: props.content,
    onUpdate: ({ editor }) => {
      // Call the onContentChange callback whenever the editor content changes
      if(props.selectedFeedbackRef.current === props.selectedFeedback) {
        props.cancelHandleChange(); // Cancel the debounced function
      }
      props.selectedFeedbackRef.current = props.selectedFeedback;
      props.updatePromptContent(editor.getHTML()); //eller getText
      props.handleChange(editor.getHTML(), editor.getText());
    }
  });

  useEffect(() => {
    if (props.editorRef.current) {
      props.editorRef.current.commands.setContent(props.content.replace(/\n/g, '<br>'));
    }
  }, [props.content])

  useEffect(() => {
    props.editorRef.current = editor;
    const proseMirrorElement = document.querySelector('.ProseMirror') as HTMLElement;
    const greatGrandparentElement = proseMirrorElement?.parentElement?.parentElement?.parentElement;
    
    if (proseMirrorElement){
      proseMirrorElement.style.outline = 'none';
    }
    const handleResize = () => {
      if (proseMirrorElement && greatGrandparentElement) {
        const greatGrandparentHeight = greatGrandparentElement.clientHeight;
        const minHeightPercentage = 100; // Adjust the percentage as needed

        if (showTools){
          proseMirrorElement.style.minHeight = `${(minHeightPercentage / 100) * greatGrandparentHeight - 117}px`;
        }
        else {
          proseMirrorElement.style.minHeight = `${(minHeightPercentage / 100) * greatGrandparentHeight - 72}px`;
        }
      }
    };

    // Initial setting of minHeight
    handleResize();

    // Listen for window resize event
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [editor, showTools]);

  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor?.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor]);


  return (
    <MantineProvider>
      <RichTextEditor 
        editor={editor}
        style={{
          minHeight: '250px',
          minWidth: '300px',
          width: 'calc(100% - 20px)',
          border: 'solid',
          borderColor: '#444', 
          borderWidth: '2px', 
          borderRadius: '20px', 
          alignSelf: 'safe center',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          position: 'relative',
          margin: '0 0 5px 0',
        }}
        >

        <RichTextEditor.Content
          style={{ maxHeight: showTools ? 'calc(100% - 100px)' : 'calc(100% - 55px)', overflowY: 'scroll', color: '#fff', padding: '0 10px 0px 10px', border: 'none'}}
        />
        {showTools &&
          <RichTextEditor.Toolbar style={{ display: 'flex', boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.4)', maxWidth: 'fit-content', margin: '5px 10px 10px 10px'}}>
            <ToolsGroup>
              <button 
                onClick={() => editor?.chain().focus().undo().run()}
                disabled={!editor?.can().undo()}
                className={editor?.can().undo() ? '' : 'is-disabled'}
              >
                <FaUndo/>
              </button>
              <button 
                onClick={() => editor?.chain().focus().redo().run()} 
                disabled={!editor?.can().redo()}
                className={editor?.can().redo() ? '' : 'is-disabled'}
              >
                <FaRedo/>
              </button>
            </ToolsGroup>

            <ToolsSeparatorLine/>

            <ToolsGroup>
              <button
                onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor?.isActive('heading', { level: 1 }) ? 'is-active' : ''}
              >
                <FaHeading />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleBold().run()}
                className={editor?.isActive('bold') ? 'is-active' : ''}
              >
                <FaBold />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleItalic().run()}
                className={editor?.isActive('italic') ? 'is-active' : ''}
              >
                <FaItalic />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleUnderline().run()}
                className={editor?.isActive('underline') ? 'is-active' : ''}
              >
                <FaUnderline />
              </button>
            </ToolsGroup>

            <ToolsSeparatorLine/>

            <ToolsGroup>
              <button
                onClick={() => editor?.chain().focus().toggleBulletList().run()}
                className={editor?.isActive('bulletList') ? 'is-active' : ''}
              >
                <FaListUl />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleOrderedList().run()}
                className={editor?.isActive('orderedList') ? 'is-active' : ''}
              >
                <FaListOl />
              </button>

              <button
                onClick={() => editor?.chain().focus().setTextAlign('left').run()}
                className={editor?.isActive('textAlign', 'left') ? 'is-active' : ''}
              >
                <FaAlignLeft />
              </button>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('center').run()}
                className={editor?.isActive('textAlign', 'center') ? 'is-active' : ''}
              >
                <FaAlignCenter />
              </button>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
                className={editor?.isActive('textAlign', 'justify') ? 'is-active' : ''}
              >
                <FaAlignJustify />
              </button>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('right').run()}
                className={editor?.isActive('textAlign', { alignment: 'right'}) ? 'is-active' : ''}
              >
                <FaAlignRight />
              </button>
            </ToolsGroup>

            <ToolsSeparatorLine/>

            <ToolsGroup>
              <button
                onClick={() => editor?.chain().focus().toggleStrike().run()}
                className={editor?.isActive('strike') ? 'is-active' : ''}
              >
                <FaStrikethrough />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleHighlight().run()}
                className={editor?.isActive('highlight') ? 'is-active' : ''}
              >
                <FaHighlighter />
              </button>
            </ToolsGroup>

          </RichTextEditor.Toolbar>
        }
        <RichTextEditor.Toolbar style={{ display: 'flex', margin: '5px 10px 10px 10px'}}>
          <SendEmailButton onClick={() => props.sendEmail(editor?.getHTML() ?? '')}>Skicka</SendEmailButton>
          <ToolsGroup style={{alignSelf: 'center'}}>
            <button
              onClick={() => setShowTools(!showTools)}
              className={showTools ? 'is-active' : ''}
            >
              <FaFont/>
            </button>
            <button 
              onClick={setLink}
              className={editor?.isActive('link') ? 'is-active' : ''}
              >
              <FaLink/>
            </button>
            <button
              onClick={() => editor?.chain().focus().unsetLink().run()}
              disabled={!editor?.isActive('link')}
              className={!editor?.isActive('link') ? 'is-disabled' : ''}
            >
              <FaUnlink/>
            </button>
          </ToolsGroup>

        </RichTextEditor.Toolbar>

      </RichTextEditor>
    </MantineProvider>
  );
};