import { FaTrashCan, FaWrench } from "react-icons/fa6";
import { User } from "../../Types";
import { AdminListContainer, AdminListItem, DeleteButton, ListConfigureButton } from "../AdminPage-styling";

type CompanyUsersListProps = {
  companyUsers: User[];
  handleDeleteUserPopup: (userId: string, firstName: string, lastName: string) => void;
  handleConfigureUser: (user: User) => void;
};

const CompanyUsersList = (props: CompanyUsersListProps) => {




  return (
    <AdminListContainer>
      {props.companyUsers.length === 0 ? (
        <AdminListItem>Hittade inga användare</AdminListItem>
      ) : (
        props.companyUsers.map((user) => (
        <AdminListItem key={user.id}>
          <DeleteButton onClick={() => props.handleDeleteUserPopup(user.id, user.first_name, user.last_name)}>
            <FaTrashCan />
          </DeleteButton>
          <ListConfigureButton onClick={() => props.handleConfigureUser(user)}>
            <FaWrench />
          </ListConfigureButton>
          {user.first_name} {user.last_name}
        </AdminListItem>
      ))
    )}
    </AdminListContainer>
  );
};

export default CompanyUsersList;