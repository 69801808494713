import React, { useState } from "react";
import { CreateErrand } from "./CreateErrandPage/CreateErrandPage";
import { OpenErrandsPage } from "./OpenErrandsPage/OpenErrandsPage";
import { Button, ButtonText, TopSection, MainPage } from "./MainPageStyling";

const createErrandState: string = "create_errand";
const openErrandsState: string = "open_errands";

type ViewState = typeof createErrandState | typeof openErrandsState;

export const TabSection = ({ viewState, setStateCallback }: any) => {
  return (
    <>
      <Button isActive={viewState === openErrandsState}>
        <ButtonText onClick={() => setStateCallback(openErrandsState)}>
          Ongoing Errands
        </ButtonText>
      </Button>
      <Button isActive={viewState === createErrandState}>
        <ButtonText onClick={() => setStateCallback(createErrandState)}>
          Create Errand
        </ButtonText>
      </Button>
    </>
  );
};

const AppLayout = () => {
  const [viewState, setViewState] = useState<ViewState>(openErrandsState);

  return (
    <>
      <TopSection>
        <TabSection viewState={viewState} setStateCallback={setViewState} />
      </TopSection>
      <MainPage>
        {viewState === openErrandsState && <OpenErrandsPage />}
        {viewState === createErrandState && <CreateErrand />}
      </MainPage>
    </>
  );
};

export default AppLayout;
