// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for Webkit browsers to mimic a hover-sensitive scrollbar similar to Firefox's behavior */

/* Customizes the scrollbar's overall look */
::-webkit-scrollbar {
  width: 5px; /* Thinner width when not hovered */
}

/* Hides the scrollbar track until hovered */
::-webkit-scrollbar-track {
  background-color: transparent; /* Makes the track invisible by default */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0; /* Medium grey, similar to Firefox's thumb */
  border-radius: 4px; /* Adjust for rounded corners */
  border: 4px solid transparent; /* Transparent border to maintain the thumb size */
}

/* Changes the track's color on hover to make it visible */
::-webkit-scrollbar-track:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Optionally, adjust the scrollbar thumb on hover for a visual feedback */
::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* A slightly darker grey on hover */
}

::-webkit-scrollbar-thumb:active {
  background-color: #333; /* Different color when active */
}
`, "",{"version":3,"sources":["webpack://./src/CSS/global.css"],"names":[],"mappings":"AAAA,kGAAkG;;AAElG,4CAA4C;AAC5C;EACE,UAAU,EAAE,mCAAmC;AACjD;;AAEA,4CAA4C;AAC5C;EACE,6BAA6B,EAAE,yCAAyC;AAC1E;;AAEA,2DAA2D;AAC3D;EACE,yBAAyB,EAAE,4CAA4C;EACvE,kBAAkB,EAAE,+BAA+B;EACnD,6BAA6B,EAAE,kDAAkD;AACnF;;AAEA,0DAA0D;AAC1D;EACE,0CAA0C;AAC5C;;AAEA,0EAA0E;AAC1E;EACE,sBAAsB,EAAE,oCAAoC;AAC9D;;AAEA;EACE,sBAAsB,EAAE,gCAAgC;AAC1D","sourcesContent":["/* Styles for Webkit browsers to mimic a hover-sensitive scrollbar similar to Firefox's behavior */\n\n/* Customizes the scrollbar's overall look */\n::-webkit-scrollbar {\n  width: 5px; /* Thinner width when not hovered */\n}\n\n/* Hides the scrollbar track until hovered */\n::-webkit-scrollbar-track {\n  background-color: transparent; /* Makes the track invisible by default */\n}\n\n/* Customizes the scrollbar thumb (the draggable element) */\n::-webkit-scrollbar-thumb {\n  background-color: #c0c0c0; /* Medium grey, similar to Firefox's thumb */\n  border-radius: 4px; /* Adjust for rounded corners */\n  border: 4px solid transparent; /* Transparent border to maintain the thumb size */\n}\n\n/* Changes the track's color on hover to make it visible */\n::-webkit-scrollbar-track:hover {\n  background-color: rgba(255, 255, 255, 0.3);\n}\n\n/* Optionally, adjust the scrollbar thumb on hover for a visual feedback */\n::-webkit-scrollbar-thumb:hover {\n  background-color: #777; /* A slightly darker grey on hover */\n}\n\n::-webkit-scrollbar-thumb:active {\n  background-color: #333; /* Different color when active */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
