import { useNavigate } from "react-router-dom";
import { Container, ContentContainer, Logo, NavBar, PageBackground, ScreenProtector } from "../Styling/NavBar-styling";
import HamburgerMenu from "../Components/HamburgerMenu";
import IconNavigation from "../Components/IconNavigation";
import { AdminMainContent, AdminCategoryContainer, AdminCategoryTitle, AdminSettingContainer, AdminSettingText, AdminSettingSelectBox, SmallTextSpan, AdminSettingTextarea, AdminButton } from "./AdminPage-styling";
import AddUser from "./AdminComponents/AddUser";
import { Company, Inbox, Language, User } from "../Types";
import { useRef, useEffect, useState } from "react";
import { ax } from "../Utils";
import AddInbox from "./AdminComponents/AddInbox";
import AddWebsite from "./AdminComponents/AddWebsite";
import AddDocument from "./AdminComponents/AddDocument";
import { AuthorizationButton } from "../AuthorizationPages/Authorization-styling";
import { FaLanguage } from "react-icons/fa6";
import { set, update } from "lodash";
import CompanyUsersList from "./AdminComponents/CompanyUsersList";
import Swal from "sweetalert2";
import CompanyInboxList from "./AdminComponents/CompanyInboxList";


export type AdminPageProps = {
  currentUser: User | null;
  setCurrentUser: (u: User) => any;
};

const AdminPage = (props: AdminPageProps) => {

  const [companyDescription, setCompanyDescription] = useState<string>("");
  const [company, setCompany] = useState<Company | null>(null);
  const [copilotLanguages, setCopilotLanguages] = useState<Language[]>([]);
  const [interfaceLanguages, setInterfaceLanguages] = useState<Language[]>([]);
  const [copilotLanguageId, setCopilotLanguageId] = useState<number | null>(null);
  const [interfaceLanguageId, setInterfaceLanguageId] = useState<number | null>(null);
  const [companyUsers, setCompanyUsers] = useState<User[]>([]);
  const [companyInboxes, setCompanyInboxes] = useState<Inbox[]>([]);
  
  const permissionsOptions = `
  <option value="false">Användare</option>
  <option value="true">Admin</option>
  `;
  
  
  const navigate = useNavigate();
  
  const isFetchingUser = useRef(false);
  const isFetchingCompany = useRef(false);
  const isFetchingCompanyUsers = useRef(false);
  const isFetchingCompanyInboxes = useRef(false);
  const isFetchingCopilotLanguages = useRef(false);
  const isFetchingInterfaceLanguages = useRef(false);

  useEffect(() => {
    if (!props.currentUser && !isFetchingUser.current) {
      isFetchingUser.current = true;
      ax.get('/users/company/whoami')
        .then((response) => {
          props.setCurrentUser(response.data);
          ax.get(`/companies?company_id=${response.data.company_id}`)
            .then((response2) => {
              setCompany(response2.data);
              setCompanyDescription(response2.data.business_description);
              setCopilotLanguageId(response2.data.default_copilot_language_id);
              setInterfaceLanguageId(response2.data.default_interface_language_id);
            }
          );
          ax.get(`/users/company/all?company_id=${response.data.company_id}`)
            .then((response3) => {
              setCompanyUsers(response3.data);
            });
        });
    }
    else if (!company && !isFetchingCompany.current && !isFetchingUser.current && props.currentUser) {
      isFetchingCompany.current = true;
      ax.get(`/companies?company_id=${props.currentUser.company_id}`)
        .then((response) => {
          setCompany(response.data);
          setCompanyDescription(response.data.business_description);
          setCopilotLanguageId(response.data.default_copilot_language_id);
          setInterfaceLanguageId(response.data.default_interface_language_id);
        });
    }
    if (props.currentUser && companyUsers.length === 0 && !isFetchingUser.current && !isFetchingCompanyUsers.current) {
      isFetchingCompanyUsers.current = true;
      ax.get(`/users/company/all?company_id=${props.currentUser.company_id}`)
        .then((response) => {
          setCompanyUsers(response.data);
        });
    }
    // if (props.currentUser && companyInboxes.length === 0 && !isFetchingUser.current && !isFetchingCompanyInboxes.current) {
    //   isFetchingCompanyInboxes.current = true;
    //   ax.get(`/feedback/email/company/all?company_id=${props.currentUser.company_id}`)
    //     .then((response) => {
    //       setCompanyInboxes(response.data);
    //     });
    // }Kommenterat för demo eftersom det inte finns en riktig endpoint för detta
    if (copilotLanguages.length === 0 && !isFetchingCopilotLanguages.current) {
      isFetchingCopilotLanguages.current = true;
      ax.get('/language/copilot/all')
        .then(response => {
          setCopilotLanguages(response.data);
        })
        .catch(error => {
          console.error('Failed to fetch copilot languages:', error);
        });
    }
    if (interfaceLanguages.length === 0 && !isFetchingInterfaceLanguages.current) {
      isFetchingInterfaceLanguages.current = true;
      ax.get('/language/interface/all')
        .then(response => {
          setInterfaceLanguages(response.data);
        })
        .catch(error => {
          console.error('Failed to fetch interface languages:', error);
        });
    }
  }, []);

  const handleHome = async () => {
    navigate('/user');
  };

  const updateCompanyDescription = async () => {
    if (company && companyDescription !== company.business_description) {
      const requestBody: any = {
        "id": company.id,
        "business_description": companyDescription,
      };
      try {//Finns ej på riktigt ännu
        await ax.patch('/companies', requestBody);
      } catch (error) {
        alert("Uppdatering av företagsbeskrivning misslyckades. Error: " + error);
        return;
      }
    }
  };

  const updateDefaultLanguages = async () => {
    if (company && (copilotLanguageId !== company?.default_copilot_language_id || interfaceLanguageId !== company?.default_interface_language_id)) {
      const requestBody: any = {
        "id": company.id,
        "default_copilot_language_id": copilotLanguageId,
        "default_interface_language_id": interfaceLanguageId,
      };
      try {
        await ax.patch('/companies', requestBody)
          .then((response) => setCompany(response.data));
        Swal.fire({
          title: "Språk uppdaterade!",
          icon: "success"
        });
      } catch (error) {
        Swal.fire({
          title: "Uppdatering av språk misslyckades!",
          text: "Error: " + error,
          icon: "error"
        });
      }
    }
  };

  const handleDeleteUserPopup = (userId: string, firstName: string, lastName: string) => {
    Swal.fire({
      title: `Är du säker på att du vill ta bort användaren ${firstName} ${lastName}?`,
      text: "Du kan inte ångra beslutet!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Ta bort användare",
      cancelButtonText: "Avbryt"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId);
      }
    });
  };

  const deleteUser = async (userId: string) => {
    try {
      await ax.delete(`/users/${userId}`);
      setCompanyUsers(companyUsers.filter((user) => user.id !== userId));
      Swal.fire({
        title: "Användare borttagen!",
        icon: "success"
      });
    } catch (error) {
      Swal.fire({
        title: "Radering av användare misslyckades!",
        text: "Error: " + error,
        icon: "error"
      });
    }
  };

  const handleConfigureUser = (user: User) => {
    Swal.fire({
      title: "Konfigurera användare",
      html: `
        <p>Välj behörighet för användaren</p>
        <select id="permissions" class="swal2-input">
          ${permissionsOptions}
        </select>
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Uppdatera användare",
      cancelButtonText: "Avbryt",
      didOpen: () => {
        // Set the selected value here
        const permissionsSelect = document.getElementById('permissions') as HTMLSelectElement;
        permissionsSelect.value = user.is_superuser ? 'true' : 'false';
      },
      preConfirm: () => {
        const newPermission = (document.getElementById('permissions') as HTMLSelectElement).value;
        return { newPermission };
      }

    }).then((result) => {
      if (result.isConfirmed) {
        updateUser(user, result.value.newPermission);
      }
    }
    );
  };

  const updateUser = async (user: User, newPermission: string) => {
    const requestBody = {
      "id": user.id,
      "is_superuser": newPermission === "true"
    };
    try {
      await ax.patch(`/users`, requestBody)
        .then((response) => setCompanyUsers(companyUsers.map((u) => u.id === user.id ? response.data : u)));
      Swal.fire({
        title: "Användare uppdaterad!",
        icon: "success"
      });
    } catch (error) {
      Swal.fire({
        title: "Uppdatering av användare misslyckades!",
        text: "Error: " + error,
        icon: "error"
      });
    }
  };

  const handleDeleteInboxPopup = (inboxId: number, inboxName: string) => {
    Swal.fire({
      title: `Är du säker på att du vill ta bort inkorgen ${inboxName}?`,
      text: "Du kan inte ångra beslutet!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#888",
      confirmButtonText: "Ta bort inkorg",
      cancelButtonText: "Avbryt"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInbox(inboxId);
      }
    });
  };

  const deleteInbox = async (inboxId: number) => {
    try {
      await ax.delete(`/feedback/email/${inboxId}`);//Finns ej på riktigt ännu
      setCompanyInboxes(companyInboxes.filter((inbox) => inbox.id !== inboxId));
      Swal.fire({
        title: "Inkorgen borttagen!",
        icon: "success"
      });
    } catch (error) {
      Swal.fire({
        title: "Radering av inkorg misslyckades!",
        text: "Error: " + error,
        icon: "error"
      });
    }
  };

  const handleConfigureInbox = (inbox: Inbox) => {
    Swal.fire({
      title: "Konfigurera inkorg",
      html: `
        <p>E-postadress</p>
        <input id="email" class="swal2-input" value="${inbox.email}" disabled>
        <p>Namnge inkorg</p>
        <input id="inboxName" class="swal2-input" value="${inbox.inbox_name}">
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Uppdatera inkorg",
      cancelButtonText: "Avbryt",
      preConfirm: () => {
        const inboxName = (document.getElementById('inboxName') as HTMLInputElement).value;
        return { inboxName };
      }

    }).then((result) => {
      if (result.isConfirmed) {
        updateInbox(inbox, result.value.inboxName);
      }
    }
    );
  };

  const updateInbox = async (inbox: Inbox, inboxName: string) => {
    const requestBody = {
      "id": inbox.id,
      "inbox_name": inboxName
    };
    try {
      await ax.patch(`/feedback/email/${inbox.id}`, requestBody)//Finns ej på riktigt ännu
        .then((response) => setCompanyInboxes(companyInboxes.map((i) => i.id === inbox.id ? response.data : i)));
      Swal.fire({
        title: "Inkorg uppdaterad!",
        icon: "success"
      });
    } catch (error) {
      Swal.fire({
        title: "Uppdatering av inkorg misslyckades!",
        text: "Error: " + error,
        icon: "error"
      });
    }
  };



  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <Logo onClick={handleHome}>ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <IconNavigation />
          <ContentContainer>
            <AdminMainContent>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Språk <FaLanguage/></AdminCategoryTitle>
                <AdminSettingContainer>
                  <AdminSettingText>Assistentens språk</AdminSettingText>
                  <AdminSettingSelectBox
                    defaultValue={company?.default_copilot_language_id}
                    onChange={(event: { target: { value: string; }; }) => setCopilotLanguageId(parseInt(event.target.value))}
                  >
                    {copilotLanguages.map((language) => (
                      <option key={language.id} value={language.id}>{language.name}</option>
                    ))}
                  </AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminSettingContainer>
                  <AdminSettingText>Hemsidans språk</AdminSettingText>
                  <AdminSettingSelectBox
                    defaultValue={company?.default_interface_language_id}
                    onChange={(event: { target: { value: string; }; }) => setInterfaceLanguageId(parseInt(event.target.value))}
                  >
                    {interfaceLanguages.map((language) => (
                      <option key={language.id} value={language.id}>{language.name}</option>
                    ))}
                  </AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminButton onClick={updateDefaultLanguages}>
                  Spara
                </AdminButton>

              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Lägg till användare</AdminCategoryTitle>
                <AddUser 
                  currentUser={props.currentUser}
                />
                <AdminSettingContainer>
                  <AdminSettingText>Registrerade användare på {company?.name}:</AdminSettingText>
                  <CompanyUsersList
                    companyUsers={companyUsers}
                    handleDeleteUserPopup={handleDeleteUserPopup}
                    handleConfigureUser={handleConfigureUser}
                  />
                </AdminSettingContainer>

              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Lägg till inkorg</AdminCategoryTitle>
                <AddInbox />
                <AdminSettingContainer>
                  <AdminSettingText>Registrerade inkorgar på {company?.name}:</AdminSettingText>
                  <CompanyInboxList
                    companyInboxes={companyInboxes}
                    handleDeleteInboxPopup={handleDeleteInboxPopup}
                    handleConfigureInbox={handleConfigureInbox}
                  />
                </AdminSettingContainer>
              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Företagsbeskrivning <SmallTextSpan>(Valfritt men rekommenderat då det hjälper assistenten att förstå sin uppgift)</SmallTextSpan></AdminCategoryTitle>
                <AdminSettingContainer>
                  <AdminSettingTextarea
                    placeholder="Skriv en kort beskrivning av ditt företag här..."
                    rows={5}
                    defaultValue={companyDescription}
                  />
                </AdminSettingContainer>
                <AdminButton onClick={updateCompanyDescription}
                >Uppdatera beskrivning</AdminButton>
              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Hemsidor</AdminCategoryTitle>
                <AddWebsite />
                -<br/>Lista på valda hemsidor
              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Lägg till texter</AdminCategoryTitle>
                <AddDocument />
              </AdminCategoryContainer>
              <AdminCategoryContainer>
                <AdminCategoryTitle>Konfigurera ragge</AdminCategoryTitle>
                <AdminSettingContainer>
                  <AdminSettingText>Välj konfiguration för intern chattbot</AdminSettingText>
                  <AdminSettingSelectBox></AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminSettingContainer>
                  <AdminSettingText>Välj konfiguration för extern chattbot</AdminSettingText>
                  <AdminSettingSelectBox></AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminSettingContainer>
                  <AdminSettingText>Välj konfiguration för feedback assistent</AdminSettingText>
                  <AdminSettingSelectBox></AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminSettingContainer>
                  <AdminSettingText>Välj konfiguration för marknadsföringsassistent</AdminSettingText>
                  <AdminSettingSelectBox></AdminSettingSelectBox>
                </AdminSettingContainer>
                <AdminButton>Skapa ny konfiguration</AdminButton>
              </AdminCategoryContainer>
            </AdminMainContent>
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};

export default AdminPage;