import React, { useEffect, useRef } from "react";
import { ax } from "../Utils";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function VerificationPage() {
  const { token } = useParams(); // Using useParams to get the token from the URL path
  const navigate = useNavigate();
  const isVerifying = useRef(false);

  useEffect(() => {
    if (!isVerifying.current) {
      isVerifying.current = true;
      verifyAccount();
    }
  }, []);

  const verifyAccount = async () => {
    if (!token) {
      toast.error("Ingen verifieringstoken hittades!");
      navigate('/login');  // Redirect user or handle as necessary
      return;
    }

    const requestBody = {
      token: token,
    };

    try {
      await ax.post('/auth/verify', requestBody);
    }
    catch (error) {
      toast.error("Verifieringen misslyckades!");
      navigate('/login');
      return;
    }

    toast.success("Ditt konto har verifierats! Du kan nu logga in.");
    navigate('/login');
  };

  return (
    <div>
      Verifierar konto...
    </div>
  );
};
