import React, { useState } from "react";
import styled from "styled-components";

// Container for the entire form
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.div`
  font-size: 32px;
  padding-bottom: 16px;
  padding-top: 12px;
  font-family: Arial, sans-serif;
`;

// Input field

export const InputTitle = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 500px;
`;

export const InputMessage = styled.textarea`
  padding: 10px;
  margin-bottom: 10px;
  height: 200px;
  width: 500px;
  font-family: Arial, sans-serif;
`;

// Button
export const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
