// MarkdownRenderer.tsx
import React from 'react';
import ReactMarkdown, {Components} from 'react-markdown';
import './MarkdownRenderer-styling.css';
import { BlinkingSpan, CopilotChatItemText } from '../UserPage/UserPage-styling';
import styled from 'styled-components';

interface MarkdownRendererProps {
  markdownText: string;
  isStreaming?: boolean;
  fontSize?: number;
}

const MarkdownContainer = styled.div<{ fontSize: string }>`
  color: #fff;
  font-size: ${(props) => (props.fontSize)}px; /* Use the fontSize prop */
  margin: 0px 15px 5px 5px;
  line-height: 1.25;
`;

const MarkdownRenderer = (mrprops: MarkdownRendererProps) => {
  const hasContent = mrprops.markdownText.trim().length > 0;

  const withBlinkingDot = (Element: React.ElementType) => (props: any) => (
    <Element {...props}>
      {props.children}
      {mrprops.isStreaming && props.node.position?.end.offset === mrprops.markdownText.length && <BlinkingSpan style={{'fontSize': `${mrprops.fontSize ?? 18} - 2px`}}>&#x25CF;</BlinkingSpan>}
    </Element>
  );

  const components: Components = {
    p: withBlinkingDot('p'),
    h1: withBlinkingDot('h1'),
    h2: withBlinkingDot('h2'),
    h3: withBlinkingDot('h3'),
    h4: withBlinkingDot('h4'),
    h5: withBlinkingDot('h5'),
    h6: withBlinkingDot('h6'),
    blockquote: withBlinkingDot('blockquote'),
    ul: withBlinkingDot('ul'),
    ol: withBlinkingDot('ol'),
    li: withBlinkingDot('li'),
    pre: withBlinkingDot('pre'),
    code: withBlinkingDot('code'),
    table: withBlinkingDot('table'),
    thead: withBlinkingDot('thead'),
    tbody: withBlinkingDot('tbody'),
    tr: withBlinkingDot('tr'),
    td: withBlinkingDot('td'),
    th: withBlinkingDot('th'),
  };


  return (
    <MarkdownContainer fontSize={`${mrprops.fontSize ?? 16}`}>
      <ReactMarkdown
        components={components}
      >
        {mrprops.markdownText}
      </ReactMarkdown>
      {!hasContent && mrprops.isStreaming && <CopilotChatItemText><BlinkingSpan style={{'fontSize': `${mrprops.fontSize ?? 18} - 2px`}}>&#x25CF;</BlinkingSpan></CopilotChatItemText>}
    </MarkdownContainer>
  );
};

export default MarkdownRenderer;