import styled from "styled-components";
import { Message, Response } from "../Types";
import { formatDate } from "../../Utils";

const MessagesContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  font-size: 23px;
  padding-bottom: 16px;
  padding-top: 12px;
  font-family: Arial, sans-serif;
`;

export const InputResponse = styled.textarea`
  padding: 10px;
  margin-bottom: 10px;
  height: 200px;
  width: 80%;
  resize: vertical;
  font-family: Arial, sans-serif;
`;

// Interface for ChatBubble props
interface ChatBubbleProps {
  isCustomer: boolean;
}

// Styles for the chat bubble based on the isSender prop
const StyledChatBubble = styled.div<ChatBubbleProps>`
  background-color: ${(props) => (props.isCustomer ? "#333" : "#f1f0f0")};
  color: ${(props) => (props.isCustomer ? "#fff" : "#000")};
  padding: 8px 12px;
  border-radius: 8px;
  width: 80%;
  margin: 8px;
`;

type MessageSectionProps = {
  message: Message | null;
  response: Response | null;
  proposedResponse: string | undefined;
  setProposedResponse: (x: string) => any;
};

const CreatedAtStyling = styled.div`
  margin-top: 8px;
  margin-bottom: 2px;
  margin-left: 0px;
  margin-right: 8px;
  font-size: 14px;
`;

// Button
export const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const MessagesSection = (messageProps: MessageSectionProps) => {
  return (
    <MessagesContainer>
      <TitleContainer>Conversation</TitleContainer>
      {messageProps.message && (
        <StyledChatBubble isCustomer={true}>
          <div>Customer: {messageProps.message.text}</div>
          <CreatedAtStyling>
            {formatDate(messageProps.message.created_at)}
          </CreatedAtStyling>
        </StyledChatBubble>
      )}
      {messageProps.response && (
        <StyledChatBubble isCustomer={false}>
          <div>Your Answer: {messageProps.response.text}</div>
          <CreatedAtStyling>
            {formatDate(messageProps.response.created_at)}
          </CreatedAtStyling>
        </StyledChatBubble>
      )}
      {messageProps.message &&
        !messageProps.response && ( // todo, check if message is not null is a hack way to proxy that the message response hasnt loaded, make prettier pls
          <>
            <InputResponse
              placeholder="Write your response"
              value={messageProps.proposedResponse}
              onChange={(x: any) =>
                messageProps.setProposedResponse(x.target.value)
              }
            ></InputResponse>
            <Button onClick={() => console.log(messageProps.proposedResponse)}>
              Send response
            </Button>
          </>
        )}
    </MessagesContainer>
  );
};
